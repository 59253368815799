import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env?.VUE_APP_AAD_APPLICATION_ID,
    authority: process.env?.VUE_APP_AAD_AUTHORITY,
    knownAuthorities: [],
    redirectUri: process.env?.VUE_APP_AAD_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: process.env?.VUE_APP_AAD_REDIRECT_URI_LOGOUT, // Must be registered as a SPA redirectURI on your app registration
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            // return;
            break;
          default:
            // return;
            break;
        }
      },
      logLevel: LogLevel.Warning
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read', 'Group.Read.All']
};
