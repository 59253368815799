<template>
  <h2 class="ui-card-title mt-0 mb-5">
    <slot></slot>
  </h2>
</template>

<style lang="scss">
.ui-card-title {
  font-size: calc-rem(20);
  font-weight: 600;
  line-height: calc-rem(22);
}
</style>
