<template>
  <span class="ui-form-error-message d-inline-block">
    <slot></slot>
  </span>
</template>

<style lang="scss">
.ui-form-error-message {
  font-size: calc-rem(14);
  font-style: italic;
  line-height: calc-rem(16);
  color: $errorColor;
}
</style>
