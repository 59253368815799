import { defineStore } from 'pinia';
// import HttpClient from '@/services/httpclient';

const useMenuBarStore = defineStore({
  id: 'menubar',
  state: () => ({
    showLogo: false,
    showLeftButtons: false,
    showAddButton: false,
    projectsDropdownMenu: [
      {
        id: 0,
        title: 'Schaliegas',
        iconRight: null,
        iconLeft: null,
        url: '/'
      },
      {
        id: 1,
        title: 'Wind op land',
        iconRight: null,
        iconLeft: null,
        url: '/'
      },
      {
        id: 2,
        title: 'Rijstroken A1',
        iconRight: null,
        iconLeft: null,
        url: '/'
      }
    ],
    userDropdownMenu: [
      {
        id: 0,
        title: 'Profiel bewerken',
        iconRight: null,
        iconLeft: null,
        url: '/',
        subMenu: false,
        subMenuItems: null
      },
      {
        id: 1,
        title: 'Wachtwoord wijzigen',
        iconRight: null,
        iconLeft: null,
        url: '/',
        subMenu: false,
        subMenuItems: null
      },
      {
        id: 2,
        title: 'Demo prospects',
        iconRight: null,
        iconLeft: null,
        url: null,
        subMenu: true,
        subMenuItems: [
          {
            id: 0,
            title: '',
            iconRight: null,
            iconLeft: null,
            url: '/'
          }
        ]
      },
      {
        id: 3,
        title: 'Taal / language',
        iconRight: null,
        iconLeft: null,
        url: null,
        subMenu: true,
        subMenuItems: [
          {
            id: 0
          }
        ]
      },
      {
        id: 4,
        title: 'Uitloggen',
        iconRight: null,
        iconLeft: null,
        url: '/',
        subMenu: false,
        subMenuItems: null
      }
    ]
  }),
  getters: {
  },
  actions: {
    async updateAddDropdown (projectId) {
      for (let i = 0; i < this.addDropdownMenu.length; i++) {
        switch (i) {
          case 0: this.addDropdownMenu[i].url = '/projects/' + projectId + '/issues/create'; break;
          case 1: this.addDropdownMenu[i].url = '/projects/' + projectId + '/stakeholder/create'; break;
          case 2: this.addDropdownMenu[i].url = '/projects/' + projectId + '/note/create'; break;
          case 3: this.addDropdownMenu[i].url = '/projects/' + projectId + '/media/create'; break;
        }
      }
    }
  }
});

export { useMenuBarStore };
