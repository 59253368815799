import axios from 'axios';
import { AuthService } from '../auth';

const baseUrl = () => {
  const cookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('ApiBaseUrl='));
  let cookieUrl = '';

  if (cookie !== undefined) {
    cookieUrl = cookie.split('=')[1];
  } else {
    cookieUrl = process.env?.VUE_APP_SOMSET_API_BASEURL;
  }
  return decodeURIComponent(cookieUrl);
};
const auth = new AuthService();

function checkUrlAndPathValidity (path) {
  const baseUrlValue = baseUrl();
  if (!baseUrlValue || baseUrlValue.length === 0) {
    throw new TypeError('Base URL can not be empty.');
  }
  if (!path || path.length === 0) {
    throw new TypeError('Path URL can not be empty.');
  }
}

export default {
  async get (path, config) {
    const defaultConfig = await auth.configureDefaultAuthorizationHeader();

    try {
      checkUrlAndPathValidity(path);
      if (config) {
        let language = window.$cookies.get('language');
        if (language === undefined || language === null || language === '') {
          language = 'nl';
        }
        config.headers.language = language;
        return await axios.get((baseUrl() + path), config);
      }

      return await axios.get((baseUrl() + path), defaultConfig);
    } catch (error) {
      this.handleError(error);
    }
  },

  async post (path, entity, config) {
    const defaultConfig = await auth.configureDefaultAuthorizationHeader();

    try {
      checkUrlAndPathValidity(path);

      if (config) {
        return await axios.post((baseUrl() + path), entity, config);
      }

      return await axios.post((baseUrl() + path), entity, defaultConfig);
    } catch (error) {
      this.handleError(error);
    }
  },

  async put (path, entity, config) {
    const defaultConfig = await auth.configureDefaultAuthorizationHeader();

    try {
      checkUrlAndPathValidity(path);

      if (config) {
        return await axios.put((baseUrl() + path), entity, config);
      }

      return await axios.put((baseUrl() + path), entity, defaultConfig);
    } catch (error) {
      this.handleError(error);
    }
  },

  async delete (path, config) {
    const defaultConfig = await auth.configureDefaultAuthorizationHeader();

    try {
      checkUrlAndPathValidity(path);

      if (config) {
        return await axios.delete((baseUrl() + path), config);
      }

      return await axios.delete((baseUrl() + path), defaultConfig);
    } catch (error) {
      this.handleError(error);
    }
  },

  handleError (responseError) {
    console.error(responseError);
    if (responseError.response.status === 401) {
      console.info('Catch 401 and login again.');
      // Handle login if 401.
      auth.login();
    }
    throw responseError;
  }
};
