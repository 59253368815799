import { createApp } from 'vue';
import { createPinia } from 'pinia';
// import { createI18n } from 'vue-i18n';
import App from './App.vue';

import { msalPlugin } from './services/auth/msalPlugin';
import { msalInstance } from './services/auth/config';
import { AuthenticationResult, EventType } from '@azure/msal-browser';

import router from './router';

import i18n from './translations';

import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import VueCookies from 'vue-cookies';

import 'normalize.css/normalize.css';
import './themes/bootstrap/bootstrap.scss';
import './themes/default/somset/theme.scss';
import 'primevue/resources/primevue.css';
import 'primeicons/primeicons.css';
import './themes/utils/_helpers.scss';

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

createApp(App)
  .use(createPinia())
  .use(msalPlugin, msalInstance)
  .use(i18n)
  .use(router)
  .use(PrimeVue, {
    ripple: true,
    locale: {
      dayNames: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
      dayNamesShort: ['Zon', 'Maa', 'Din', 'Woe', 'Don', 'Vri', 'Zat'],
      dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
      monthNames: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      today: ['Vandaag'],
      firstDayOfWeek: 1
    }
  })
  .use(ToastService)
  .use(ConfirmationService)
  .use(VueCookies, { expires: '1d' })
  .directive('tooltip', Tooltip)
  .mount('#app');
