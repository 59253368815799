<template>
  <router-link
    class="ui-logo-link d-inline-block"
    to="/">
    <span class="sr-only">Somset</span>
  </router-link>
</template>

<style lang="scss">
.ui-logo-link {
  background-image: url(../../assets/images/logo-somset.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:focus {
    @extend %button-focus;
  }
}
</style>
