import { defineStore } from 'pinia';
import HttpClient from '@/services/httpclient';
import historyHelper from '@/services/historyhelper';
import i18n from '../../../translations';

const t = (input) => {
  return i18n.global.t(input);
};

export const useStakeholdersStore = defineStore({
  id: 'stakeholders',
  state: () => ({
    stakeholders: [],
    stakeholder: {},
    editStakeholder: {},
    stakeholderVersion: {},
    issuesEdit: [],
    issuesEditSelected: [],
    selectedProjectIssues: [],
    orgSelectedProjectIssues: [],
    stakeholderIssues: [],
    author: null,
    moderator: null,
    contentCard: null,
    configReview: {
      cardTitle: t('General.Tension'),
      forCheckboxes: 'beoordelingspanning',
      checkboxes: [
        {
          id: 5,
          labelTitle: t('Status.Onduidelijk'),
          value: 'onduidelijk',
          nameCheckboxes: 'checkboxes',
          className: '--unclear',
          checked: false
        },
        {
          id: 4,
          labelTitle: t('Status.Positief'),
          value: 'positief',
          nameCheckboxes: 'checkboxes',
          className: '--positive',
          checked: false
        },
        {
          id: 3,
          labelTitle: t('Status.Aandacht'),
          value: 'aandacht',
          nameCheckboxes: 'checkboxes',
          className: '--focus',
          checked: false
        },
        {
          id: 2,
          labelTitle: t('Status.Waarschuwing'),
          value: 'waarschuwing',
          nameCheckboxes: 'checkboxes',
          className: '--warning',
          checked: false
        },
        {
          id: 1,
          labelTitle: t('Status.Kritiek'),
          value: 'kritiek',
          nameCheckboxes: 'checkboxes',
          className: '--criticism',
          checked: false
        }
      ]
    },
    configImportance: {
      cardTitle: t('Stakeholder.ConcernText'),
      forCheckboxes: 'beoordelingspanning',
      checkboxes: [
        {
          id: 5,
          labelTitle: t('Status.Onduidelijk'),
          value: 'onduidelijk',
          nameCheckboxes: 'checkboxes',
          className: '--unclear',
          checked: false
        },
        {
          id: 4,
          labelTitle: t('Status.Nauwelijks.belang'),
          value: 'nauwelijksbelang',
          nameCheckboxes: 'checkboxes',
          className: '--hardlyimportance',
          checked: false
        },
        {
          id: 3,
          labelTitle: t('Status.Redelijk.belang'),
          value: 'redelijkbelang',
          nameCheckboxes: 'checkboxes',
          className: '--reasonablyimportance',
          checked: false
        },
        {
          id: 2,
          labelTitle: t('Status.Groot.belang'),
          value: 'grootbelang',
          nameCheckboxes: 'checkboxes',
          className: '--greatimportance',
          checked: false
        },
        {
          id: 1,
          labelTitle: t('Status.Extreem.groot.belang'),
          value: 'extreemgrootbelang',
          nameCheckboxes: 'checkboxes',
          className: '--extremlyhighimportance',
          checked: false
        }
      ]
    },
    configParticipation: {
      cardTitle: t('Participatieniveau'),
      optionLabel: 'name',
      placeholder: t('General.ChooseLevel'),
      selectedValue: {},
      options: [
        {
          name: '',
          value: null
        },
        {
          name: t('Status.Informeren'),
          value: 1
        },
        {
          name: t('Status.Communiceren'),
          value: 2
        },
        {
          name: t('Status.Adhoc'),
          value: 3
        },
        {
          name: t('Status.Participeren'),
          value: 4
        },
        {
          name: t('Status.Consensus'),
          value: 5
        },
        {
          name: t('Status.Empowerment'),
          value: 6
        }
      ]
    },
    configContact: {
      headings: {
        cardTitle: t('General.Contactdetails'),
        subTitle: t('Stakeholder.Contacts'),
        address: t('General.Address'),
        extraAddress: t('ProjectStakeholder.ExtraAddress'),
        phone: t('General.Phone'),
        email: t('General.Email'),
        website: t('General.Website'),
        jobTitle: t('General.Function'),
        description: t('General.Description')
      },
      buttons: {
        editContact: t('General.Edit'),
        removeContact: t('General.Delete'),
        newContact: t('Contact.AddNewContact')
      },
      contactData: {
        address: '',
        zipcode: '',
        town: '',
        extraAddress: '',
        extraZipcode: '',
        extraTown: '',
        phone: '',
        email: '',
        website: '',
        contactPersons: []
      }
    },
    configFeatures: {
      legendTitle: t('General.Features'),
      dropdownOptionLabel: 'name',
      features: [
        {
          id: 0,
          name: 'power',
          labelName: t('Stakeholder.Power'),
          labelFor: 'powerText',
          dropdownPlaceholder: t('General.MakeAChoice'),
          dropdownLabel: 'name',
          options: [
            {
              nr: null,
              name: ''
            },
            {
              nr: 1,
              name: t('Power.Persoonsmacht')
            },
            {
              nr: 2,
              name: t('Power.Positiemacht')
            },
            {
              nr: 3,
              name: t('Power.Connectiemacht')
            },
            {
              nr: 4,
              name: t('Power.BATNA.macht')
            }
          ]
        },
        {
          id: 1,
          name: 'emotion',
          labelName: t('Issue.Frontend.Emotion'),
          labelFor: 'emotionText',
          dropdownPlaceholder: t('General.MakeAChoice'),
          dropdownLabel: 'name',
          options: [
            {
              nr: null,
              name: ''
            },
            {
              nr: 0,
              name: t('Emotion.Geen.emotie')
            },
            {
              nr: 1,
              name: t('Emotion.Ontkenning')
            },
            {
              nr: 2,
              name: t('Emotion.Woede')
            },
            {
              nr: 3,
              name: t('Emotion.Onderhandelen')
            },
            {
              nr: 4,
              name: t('Emotion.Depressie')
            },
            {
              nr: 5,
              name: t('Emotion.Aanvaarding')
            },
            {
              nr: 6,
              name: t('Emotion.Low')
            },
            {
              nr: 7,
              name: t('Emotion.Average')
            },
            {
              nr: 8,
              name: t('Emotion.High')
            }
          ]
        },
        {
          id: 2,
          name: 'line',
          labelName: t('Stakeholder.Line'),
          labelFor: 'lineText',
          dropdownPlaceholder: t('General.MakeAChoice'),
          dropdownLabel: 'name',
          options: [
            {
              nr: null,
              name: '',
              description: null
            },
            {
              nr: 1,
              name: t('Stakeholder.Verybasic')
            },
            {
              nr: 2,
              name: t('Stakeholder.Basic')
            },
            {
              nr: 3,
              name: t('Stakeholder.Reasonablybasic')
            },
            {
              nr: 4,
              name: t('Stakeholder.Limitedbasic')
            },
            {
              nr: 5,
              name: t('Stakeholder.Notbasic')
            }
          ]
        },
        {
          id: 3,
          name: 'changableStrategy',
          labelName: t('Stakeholder.ChangableStrategy'),
          labelFor: 'changableStrategyText',
          dropdownPlaceholder: t('General.MakeAChoice'),
          dropdownLabel: 'name',
          options: [
            {
              nr: 1,
              name: t('ChangableStrategy.Geeldruk')
            },
            {
              nr: 2,
              name: t('ChangableStrategy.Blauwdruk')
            },
            {
              nr: 3,
              name: t('ChangableStrategy.Rooddruk')
            },
            {
              nr: 4,
              name: t('ChangableStrategy.Groendruk')
            },
            {
              nr: 5,
              name: t('ChangableStrategy.Witdruk')
            }
          ]
        },
        {
          id: 4,
          name: 'urgency',
          labelName: t('Stakeholder.Urgency'),
          labelFor: 'urgencyText',
          dropdownPlaceholder: t('General.MakeAChoice'),
          dropdownLabel: 'name',
          options: [
            {
              nr: null,
              name: ''
            },
            {
              nr: 1,
              name: t('Urgency.Zeer.urgent')
            },
            {
              nr: 2,
              name: t('Urgency.Urgent')
            },
            {
              nr: 3,
              name: t('Urgency.Redelijk.urgent')
            },
            {
              nr: 4,
              name: t('Urgency.Beperkt.urgent')
            },
            {
              nr: 5,
              name: t('Urgency.Niet.urgent')
            }
          ]
        },
        {
          id: 5,
          name: 'legitimity',
          labelName: t('Stakeholder.StakeholderLegitimacy'),
          labelFor: 'legitimityText',
          dropdownPlaceholder: t('General.MakeAChoice'),
          dropdownLabel: 'name',
          options: [
            {
              nr: null,
              name: ''
            },
            {
              nr: 1,
              name: t('Legitimity.Zeer.legitiem')
            },
            {
              nr: 2,
              name: t('Legitimity.Legitiem')
            },
            {
              nr: 3,
              name: t('Legitimity.Redelijk.legitiem')
            },
            {
              nr: 4,
              name: t('Legitimity.Beperkt.legitiem')
            },
            {
              nr: 5,
              name: t('Legitimity.Niet.legitiem')
            }
          ]
        },
        {
          id: 6,
          name: 'participation',
          labelName: t('Participatieniveau'),
          labelFor: 'participationText',
          dropdownPlaceholder: t('General.MakeAChoice'),
          dropdownLabel: 'name',
          options: [
            {
              name: '',
              value: null
            },
            {
              nr: 1,
              name: t('Status.Informeren')
            },
            {
              nr: 2,
              name: t('Status.Communiceren')
            },
            {
              nr: 3,
              name: t('Status.Adhoc')
            },
            {
              nr: 4,
              name: t('Status.Participeren')
            },
            {
              nr: 5,
              name: t('Status.Consensus')
            },
            {
              nr: 6,
              name: t('Status.Empowerment')
            }
          ]
        },
        {
          id: 6,
          name: 'batna',
          labelName: t('Stakeholder.BATNA'),
          labelFor: 'batnaText',
          labelDescription: t('Stakeholder.Batna.Description'),
          dropdownPlaceholder: t('General.MakeAChoice'),
          dropdownLabel: 'name',
          options: [
            {
              name: '',
              value: null
            },
            {
              nr: 1,
              name: t('BATNA.Sterk.BATNA')
            },
            {
              nr: 2,
              name: t('BATNA.Redelijk.BATNA')
            },
            {
              nr: 3,
              name: t('BATNA.Zwak.BATNA')
            },
            {
              nr: 4,
              name: t('BATNA.Fantoom.BATNA')
            }
          ]
        },
        {
          id: 7,
          name: 'levelOfTrust',
          labelName: t('Stakeholder.DegreeOfTrust'),
          labelFor: 'levelOfTrustText',
          dropdownPlaceholder: t('General.MakeAChoice'),
          dropdownLabel: 'name',
          options: [
            {
              name: '',
              value: null
            },
            {
              nr: 1,
              name: t('LevelOfTrust.Blind.vertrouwen'),
              description: t('LevelOfTrust.Blind.vertrouwen.Description')
            },
            {
              nr: 2,
              name: t('LevelOfTrust.Afgemeten.vertrouwen'),
              description: t('LevelOfTrust.Afgemeten.vertrouwen.Description')
            },
            {
              nr: 3,
              name: t('LevelOfTrust.Gezond.vertrouwen'),
              description: t('LevelOfTrust.Gezond.vertrouwen.Description')
            },
            {
              nr: 4,
              name: t('LevelOfTrust.Geen.vertrouwen'),
              description: t('LevelOfTrust.Geen.vertrouwen.Description')
            },
            {
              nr: 5,
              name: t('LevelOfTrust.Diepgeworteld.wantrouwen'),
              description: t('LevelOfTrust.Diepgeworteld.wantrouwen.Description')
            }
          ]
        }
      ]
    },
    issueOverview: {
      cardTitle: t('General.Issues'),
      tabs: []
    },
    hideContactEditor: false,
    loadingStakeholders: true,
    loadingStakeholder: true,
    loadingNewStakeholder: true,
    loadingEditStakeholder: true,
    loadingStakeholderContacts: false,
    loadingStakeholderVersion: false,
    loadingIssueStakeholders: true,
    availableStakeholderFields: null,
    error: null
  }),
  getters: {
  },
  actions: {
    async fetchAllStakeholders (projectGuid) {
      this.loadingStakeholders = true;

      try {
        await HttpClient.get('api/v1/projects/' + projectGuid + '/stakeholders')
          .then((stakeholders) => {
            this.stakeholders = stakeholders.data;
            this.loadingStakeholders = false;
          });
      } catch (error) {
        this.loadingStakeholders = false;
        this.error = error;
      }
    },
    async fetchStakeholder (project, stakeholderId, selectedDate) {
      this.loadingStakeholder = true;

      try {
        const addOn = selectedDate ? '/version?date=' + selectedDate : '';
        const response = await HttpClient.get('api/v1/projects/' + project.id + '/stakeholders/' + stakeholderId);
        this.stakeholder = response.data;
        if (selectedDate) {
          const responseVersion = await HttpClient.get('api/v1/projects/' + project.id + '/stakeholders/' + stakeholderId + addOn);
          this.stakeholderVersion = responseVersion.data;
        }
        this.loadingIssueStakeholders = true;

        if (project.availableStakeholderFields !== null) {
          this.availableStakeholderFields = JSON.parse(project.availableStakeholderFields);
        }

        this.contentCard = '';
        // customCategory
        if (this.stakeholder.customCategory) {
          this.contentCard += '<h3>' + t('General.Category') + '</h3>';
          this.contentCard += '<p>' + this.stakeholder.customCategory + '</p>';
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.stakeholder.customCategory, this.stakeholderVersion.customCategory, selectedDate);
        }

        // description
        if (this.stakeholder.description && this.stripHTML(this.stakeholder.description)) {
          this.contentCard += '<h3>' + t('Stakeholder.Description') + '</h3>';
          if (this.stakeholder.description.indexOf('<p>') < 0) {
            this.contentCard += '<p>' + this.stakeholder.description + '</p>';
          } else {
            this.contentCard += this.stakeholder.description;
          }
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.stakeholder.description, this.stakeholderVersion.description, selectedDate);
        }

        // context
        if (this.stakeholder.context && this.stripHTML(this.stakeholder.context) && this.showField('Stakeholder.Context')) {
          this.contentCard += '<h3>' + t('Stakeholder.Context') + '</h3>';
          this.contentCard += this.stakeholder.context;
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.stakeholder.context, this.stakeholderVersion.context, selectedDate);
        }

        // pointOfView
        if (this.stakeholder.pointOfView && this.stripHTML(this.stakeholder.pointOfView)) {
          this.contentCard += '<h3>' + t('Stakeholder.PointOfViewText') + '</h3>';
          this.contentCard += this.stakeholder.pointOfView;
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.stakeholder.pointOfView, this.stakeholderVersion.pointOfView, selectedDate);
        }

        if (this.stakeholder.concernText && this.stripHTML(this.stakeholder.concernText)) {
          this.contentCard += '<h3>' + t('Stakeholder.ConcernText') + '</h3>';
          this.contentCard += this.stakeholder.concernText;
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.stakeholder.concernText, this.stakeholderVersion.concernText, selectedDate);
        }

        let contentFeatures = '';
        // emotionNr
        if (this.showField('Stakeholder.Emotion')) {
          if (this.stakeholder.emotionNr || (this.stakeholder.emotionText && this.stripHTML(this.stakeholder.emotionText))) {
            contentFeatures += '<h3>' + t('ProjectStakeholder.Emotion.Excel') + '</h3>';
            if (this.stakeholder.emotionNr) {
              const type = this.configFeatures.features.find(x => x.name === 'emotion').options.find(y => y.nr === this.stakeholder.emotionNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.stakeholder.emotionText) {
              contentFeatures += this.stakeholder.emotionText;
            }
          }
        }
        if (selectedDate) {
          const current = this.configFeatures.features.find(x => x.name === 'emotion').options.find(y => y.nr === this.stakeholder.emotionNr);
          const old = this.configFeatures.features.find(x => x.name === 'emotion').options.find(y => y.nr === this.stakeholderVersion.emotionNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.stakeholder.emotionText, this.stakeholderVersion.emotionText, selectedDate);
        }

        // legitimityNr
        if (this.showField('Stakeholder.Legitimity')) {
          if (this.stakeholder.legitimityNr || (this.stakeholder.legitimityText && this.stripHTML(this.stakeholder.legitimityText))) {
            contentFeatures += '<h3>' + t('Stakeholder.StakeholderLegitimacy') + '</h3>';
            if (this.stakeholder.legitimityNr) {
              const type = this.configFeatures.features.find(x => x.name === 'legitimity').options.find(y => y.nr === this.stakeholder.legitimityNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.stakeholder.legitimityText) {
              contentFeatures += this.stakeholder.legitimityText;
            }
          }
        }
        if (selectedDate) {
          const current = this.configFeatures.features.find(x => x.name === 'legitimity').options.find(y => y.nr === this.stakeholder.legitimityNr);
          const old = this.configFeatures.features.find(x => x.name === 'legitimity').options.find(y => y.nr === this.stakeholderVersion.legitimityNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.stakeholder.legitimityText, this.stakeholderVersion.legitimityText, selectedDate);
        }

        // powerNr
        if (this.showField('Stakeholder.Power')) {
          if (this.stakeholder.powerNr || (this.stakeholder.powerText && this.stripHTML(this.stakeholder.powerText))) {
            contentFeatures += '<h3>' + t('Stakeholder.Power') + '</h3>';
            if (this.stakeholder.powerNr) {
              const type = this.configFeatures.features.find(x => x.name === 'power').options.find(y => y.nr === this.stakeholder.powerNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.stakeholder.powerText) {
              contentFeatures += this.stakeholder.powerText;
            }
          }
        }
        if (selectedDate) {
          const current = this.configFeatures.features.find(x => x.name === 'power').options.find(y => y.nr === this.stakeholder.powerNr);
          const old = this.configFeatures.features.find(x => x.name === 'power').options.find(y => y.nr === this.stakeholderVersion.powerNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.stakeholder.powerText, this.stakeholderVersion.powerText, selectedDate);
        }

        // urgencyNr
        if (this.showField('Stakeholder.Urgency')) {
          if (this.stakeholder.urgencyNr || (this.stakeholder.urgencyText && this.stripHTML(this.stakeholder.urgencyText))) {
            contentFeatures += '<h3>' + t('Stakeholder.Urgency') + '</h3>';
            if (this.stakeholder.urgencyNr) {
              const type = this.configFeatures.features.find(x => x.name === 'urgency').options.find(y => y.nr === this.stakeholder.urgencyNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.stakeholder.urgencyText) {
              contentFeatures += this.stakeholder.urgencyText;
            }
          }
        }
        if (selectedDate) {
          const current = this.configFeatures.features.find(x => x.name === 'urgency').options.find(y => y.nr === this.stakeholder.urgencyNr);
          const old = this.configFeatures.features.find(x => x.name === 'urgency').options.find(y => y.nr === this.stakeholderVersion.urgencyNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.stakeholder.urgencyText, this.stakeholderVersion.urgencyText, selectedDate);
        }

        // lineNr
        if (this.showField('Stakeholder.Line')) {
          if (this.stakeholder.lineNr || (this.stakeholder.lineText && this.stripHTML(this.stakeholder.lineText))) {
            contentFeatures += '<h3>' + t('Stakeholder.Line') + '</h3>';
            if (this.stakeholder.lineNr) {
              const type = this.configFeatures.features.find(x => x.name === 'line').options.find(y => y.nr === this.stakeholder.lineNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.stakeholder.lineText) {
              contentFeatures += this.stakeholder.lineText;
            }
          }
        }
        if (selectedDate) {
          const current = this.configFeatures.features.find(x => x.name === 'line').options.find(y => y.nr === this.stakeholder.lineNr);
          const old = this.configFeatures.features.find(x => x.name === 'line').options.find(y => y.nr === this.stakeholderVersion.lineNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.stakeholder.lineText, this.stakeholderVersion.lineText, selectedDate);
        }

        // changableStrategyNr
        if (this.showField('Stakeholder.ChangableStrategy')) {
          if (this.stakeholder.changableStrategyNr || (this.stakeholder.changableStrategyText && this.stripHTML(this.stakeholder.changableStrategyText))) {
            contentFeatures += '<h3>' + t('Stakeholder.ChangableStrategy') + '</h3>';
            if (this.stakeholder.changableStrategyNr) {
              const type = this.configFeatures.features.find(x => x.name === 'changableStrategy').options.find(y => y.nr === this.stakeholder.changableStrategyNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.stakeholder.changableStrategyText !== null) {
              contentFeatures += this.stakeholder.changableStrategyText;
            }
          }
        }
        if (selectedDate) {
          const current = this.configFeatures.features.find(x => x.name === 'changableStrategy').options.find(y => y.nr === this.stakeholder.changableStrategyNr);
          const old = this.configFeatures.features.find(x => x.name === 'changableStrategy').options.find(y => y.nr === this.stakeholderVersion.changableStrategyNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, (old?.name === undefined ? null : old.name), this.stakeholder.changableStrategyText, this.stakeholderVersion.changableStrategyText, selectedDate);
        }

        // batnaNr
        if (this.showField('Stakeholder.Batna')) {
          if (this.stakeholder.batnaNr || (this.stakeholder.batnaText && this.stripHTML(this.stakeholder.batnaText))) {
            contentFeatures += '<h3>' + t('Stakeholder.BATNA') + '</h3>';
            if (this.stakeholder.batnaNr) {
              const type = this.configFeatures.features.find(x => x.name === 'batna').options.find(y => y.nr === this.stakeholder.batnaNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.stakeholder.batnaText !== null) {
              contentFeatures += this.stakeholder.batnaText;
            }
          }
        }
        if (selectedDate) {
          const current = this.configFeatures.features.find(x => x.name === 'batna').options.find(y => y.nr === this.stakeholder.batnaNr);
          const old = this.configFeatures.features.find(x => x.name === 'batna').options.find(y => y.nr === this.stakeholderVersion.batnaNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, (old?.name === undefined ? null : old.name), this.stakeholder.batnaText, this.stakeholderVersion.batnaText, selectedDate);
        }

        // levelOfTrustNr
        if (this.showField('Stakeholder.LevelOfTrust')) {
          if (this.stakeholder.levelOfTrustNr || (this.stakeholder.levelOfTrustText && this.stripHTML(this.stakeholder.levelOfTrustText))) {
            contentFeatures += '<h3>' + t('Stakeholder.DegreeOfTrust') + '</h3>';
            if (this.stakeholder.levelOfTrustNr) {
              const type = this.configFeatures.features.find(x => x.name === 'levelOfTrust').options.find(y => y.nr === this.stakeholder.levelOfTrustNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.stakeholder.levelOfTrustText !== null) {
              contentFeatures += this.stakeholder.levelOfTrustText;
            }
          }
        }
        if (selectedDate) {
          const current = this.configFeatures.features.find(x => x.name === 'levelOfTrust').options.find(y => y.nr === this.stakeholder.levelOfTrustNr);
          const old = this.configFeatures.features.find(x => x.name === 'levelOfTrust').options.find(y => y.nr === this.stakeholderVersion.levelOfTrustNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, (old?.name === undefined ? null : old.name), this.stakeholder.levelOfTrustText, this.stakeholderVersion.levelOfTrustText, selectedDate);
        }

        // participationNr
        if (this.showField('Stakeholder.Participation')) {
          if (this.stakeholder.participationNr || (this.stakeholder.participationText && this.stripHTML(this.stakeholder.participationText))) {
            contentFeatures += '<h3>' + t('Stakeholder.Strategy') + '</h3>';
            if (this.stakeholder.participationNr) {
              const type = this.configFeatures.features.find(x => x.name === 'participation').options.find(y => y.nr === this.stakeholder.participationNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.stakeholder.participationText !== null) {
              contentFeatures += this.stakeholder.participationText;
            }
          }
        }
        if (selectedDate) {
          const current = this.configFeatures.features.find(x => x.name === 'participation').options.find(y => y.nr === this.stakeholder.participationNr);
          const old = this.configFeatures.features.find(x => x.name === 'participation').options.find(y => y.nr === this.stakeholderVersion.participationNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.stakeholder.participationText, this.stakeholderVersion.participationText, selectedDate);
        }

        if (contentFeatures !== '') {
          this.contentCard += '<h2>' + t('General.Features') + '</h2>';
          this.contentCard += contentFeatures;
        }

        if (this.stakeholder.customFieldsValues && this.stakeholder.customFieldsValues.length > 0) {
          const customFields = JSON.parse(this.stakeholder.customFieldsValues);
          for (let idx = 0; idx < customFields.length; idx++) {
            if (customFields[idx].Value && this.stripHTML(customFields[idx].Value) && project.customStakeholderFields.indexOf(customFields[idx].FieldName) > -1) {
              this.contentCard += '<h3>' + customFields[idx].FieldName + '</h3>';
              this.contentCard += customFields[idx].Value + '</h3>';
            }
          }
        }

        // discussionText
        if (this.showField('Stakeholder.Discussion') && this.stakeholder.discussionText && this.stripHTML(this.stakeholder.discussionText)) {
          this.contentCard += '<h3>' + t('Stakeholder.Discussion') + '</h3>';
          this.contentCard += this.stakeholder.discussionText;
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.stakeholder.discussionText, this.stakeholderVersion.discussionText, selectedDate);
        }

        for (const item in this.configReview.checkboxes) {
          this.configReview.checkboxes[item].checked = this.configReview.checkboxes[item].id === this.stakeholder.statusNr;
        }

        for (const item in this.configImportance.checkboxes) {
          this.configImportance.checkboxes[item].checked = this.configImportance.checkboxes[item].id === this.stakeholder.concernNr;
        }

        this.configParticipation.selectedValue = this.configParticipation.options.find(x => x.value === this.stakeholder.participationNr);

        this.configContact.contactData.address = (this.stakeholder.street ?? '') + ' ' + (this.stakeholder.houseNumber ?? '');
        this.configContact.contactData.zipcode = this.stakeholder.zipCode ?? '';
        this.configContact.contactData.town = this.stakeholder.city ?? '';
        this.configContact.contactData.extraAddress = this.stakeholder.extraAddress ?? '';
        this.configContact.contactData.extraZipcode = this.stakeholder.extraZipCode ?? '';
        this.configContact.contactData.extraTown = this.stakeholder.extraCity ?? '';
        this.configContact.contactData.phone = this.stakeholder.telephone ?? '';
        this.configContact.contactData.email = this.stakeholder.emailAddress ?? '';
        this.configContact.contactData.website = this.stakeholder.website ?? '';
        this.author = this.stakeholder.addUser ?? null;
        this.moderator = this.stakeholder.modUser ?? null;

        const responseIssues = await HttpClient.get('api/v1/projects/' + project.id + '/stakeholders/' + stakeholderId + '/issues');
        this.stakeholderIssues = responseIssues.data;

        const issuesTabs = [];
        for (let i = 0; i < this.stakeholderIssues.length; i++) {
          let classNameImportance = null;
          if (this.stakeholderIssues[i].importance) {
            switch (this.stakeholderIssues[i].importance.nr) {
              case 0: classNameImportance = '--unclear'; break;
              case 1: classNameImportance = '--criticism'; break;
              case 2: classNameImportance = '--warning'; break;
              case 3: classNameImportance = '--focus'; break;
              case 4: classNameImportance = '--positive'; break;
            }
          } else {
            classNameImportance = '--unclear';
          }

          let classNameConcern = null;
          if (this.stakeholderIssues[i].concern) {
            switch (this.stakeholderIssues[i].concern.nr) {
              case 1: classNameConcern = '--extremlyhighimportance'; break;
              case 2: classNameConcern = '--greatimportance'; break;
              case 3: classNameConcern = '--reasonablyimportance'; break;
              case 4: classNameConcern = '--hardlyimportance'; break;
              case 5: classNameConcern = '--unclear'; break;
            }
          } else {
            classNameConcern = '--unclear';
          }

          issuesTabs.push({
            id: this.stakeholderIssues[i].issueId,
            className: classNameImportance,
            title: this.stakeholderIssues[i].issueName,
            content: {
              tabTitle: this.stakeholderIssues[i].concern ? this.stakeholderIssues[i].concern.name : '',
              className: classNameConcern,
              importance: this.stakeholderIssues[i].importance ? this.stakeholderIssues[i].importance.name : '',
              tabContent: this.stakeholderIssues[i].opinion,
              textButton: 'Open stakeholder-issue',
              urlButton: '/projects/' + project.id + '/stakeholders/' + stakeholderId + '/issues/' + this.stakeholderIssues[i].issueId,
              urlIssueButton: '/projects/' + project.id + '/issues/' + this.stakeholderIssues[i].issueId,
              urlStakeholderButton: '/projects/' + project.id + '/stakeholders/' + stakeholderId
            }
          });
        }

        this.issueOverview.tabs = issuesTabs;

        this.loadingStakeholder = false;
        this.loadingIssueStakeholders = false;

        await this.fetchStakeholderContacts(project.id, stakeholderId);
      } catch (error) {
        console.error(error);

        this.loadingStakeholder = false;
        this.loadingIssueStakeholders = false;
        this.error = error;
      }
    },
    async fetchEditStakeholder (project, stakeholderId) {
      this.loadingEditStakeholder = true;
      this.loadingProjectIssues = true;
      this.hideContactEditor = false;
      try {
        const response = await HttpClient.get('api/v1/projects/' + project.id + '/stakeholders/' + stakeholderId);
        const stakeholder = response.data;
        // TODO: REMOVE
        if (stakeholder.batna === undefined) {
          stakeholder.batna = {};
          stakeholder.batna.nr = null;
          stakeholder.batna.text = null;
        }
        if (stakeholder.levelOfTrust === undefined) {
          stakeholder.levelOfTrust = {};
          stakeholder.levelOfTrust.nr = null;
          stakeholder.levelOfTrust.text = null;
        }

        this.editStakeholder = {
          name: stakeholder.name,
          description: stakeholder.description,
          context: stakeholder.context,
          street: stakeholder.street,
          houseNumber: stakeholder.houseNumber,
          zipCode: stakeholder.zipCode,
          city: stakeholder.city,
          telephone: stakeholder.telephone,
          type: stakeholder.type,
          legalForm: stakeholder.legalForm,
          extraAddress: stakeholder.extraAddress,
          emailAddress: stakeholder.emailAddress,
          extraZipCode: stakeholder.extraZipCode,
          extraCity: stakeholder.extraCity,
          organisationalStructure: stakeholder.organisationalStructure,
          organisationalSize: stakeholder.organisationalStructure,
          localisation: stakeholder.localisation,
          goalsMission: stakeholder.goalsMission,
          financialDetails: stakeholder.financialDetails,
          importantConnections: stakeholder.importantConnections,
          followerDescription: stakeholder.followerDescription,
          followerSize: stakeholder.followerSize,
          address: stakeholder.address,
          statusNr: stakeholder.statusNr,
          website: stakeholder.website,
          customCategory: stakeholder.customCategory,
          participation: {
            nr: stakeholder.participation !== null && stakeholder.participation.nr !== null ? this.configFeatures.features.find(x => x.name === 'participation').options.find(y => y.nr === stakeholder.participation.nr) : null,
            text: stakeholder.participationText ?? ''
          },
          emotion: {
            nr: stakeholder.emotion !== null && stakeholder.emotion.nr !== null ? this.configFeatures.features.find(x => x.name === 'emotion').options.find(y => y.nr === stakeholder.emotion.nr) : null,
            text: stakeholder.emotionText ?? ''
          },
          power: {
            nr: stakeholder.power !== null && stakeholder.power.nr !== null ? this.configFeatures.features.find(x => x.name === 'power').options.find(y => y.nr === stakeholder.power.nr) : null,
            text: stakeholder.powerText ?? ''
          },
          urgency: {
            nr: stakeholder.urgency !== null && stakeholder.urgency.nr !== null ? this.configFeatures.features.find(x => x.name === 'urgency').options.find(y => y.nr === stakeholder.urgency.nr) : null,
            text: stakeholder.urgencyText ?? ''
          },
          legitimity: {
            nr: stakeholder.legitimity !== null && stakeholder.legitimity.nr !== null ? this.configFeatures.features.find(x => x.name === 'legitimity').options.find(y => y.nr === stakeholder.legitimity.nr) : null,
            text: stakeholder.legitimityText ?? ''
          },
          batna: {
            nr: stakeholder.batna !== null && stakeholder.batna.nr !== null ? this.configFeatures.features.find(x => x.name === 'batna').options.find(y => y.nr === stakeholder.batna.nr) : null,
            text: stakeholder.batnaText ?? ''
          },
          levelOfTrust: {
            nr: stakeholder.levelOfTrust !== null && stakeholder.levelOfTrust.nr !== null ? this.configFeatures.features.find(x => x.name === 'levelOfTrust').options.find(y => y.nr === stakeholder.levelOfTrust.nr) : null,
            text: stakeholder.levelOfTrustText ?? ''
          },
          line: {
            nr: stakeholder.line !== null && stakeholder.line.nr !== null ? this.configFeatures.features.find(x => x.name === 'line').options.find(y => y.nr === stakeholder.line.nr) : null,
            text: stakeholder.lineText ?? ''
          },
          changableStrategy: {
            nr: stakeholder.changableStrategy !== null && stakeholder.changableStrategy.nr !== null ? this.configFeatures.features.find(x => x.name === 'changableStrategy').options.find(y => y.nr === stakeholder.changableStrategy.nr) : null,
            text: stakeholder.changableStrategyText ?? ''
          },
          discussionText: stakeholder.discussionText,
          concernNr: stakeholder.concernNr,
          concernText: stakeholder.concernText,
          pointOfView: stakeholder.pointOfView,
          customFieldsValues: stakeholder.customFieldsValues,
          projectId: stakeholder.projectId,
          id: stakeholder.id,
          gis: stakeholder.gis,
          projectIssues: null
        };

        // Dirty hack but for some reason this.editStakeholder.customFieldsValues can have a string value of '[]'
        if (this.editStakeholder.customFieldsValues === null || this.editStakeholder.customFieldsValues.length === 0 || this.editStakeholder.customFieldsValues === '[]') {
          this.editStakeholder.customFieldsValues = [];
          const customFields = project.customStakeholderFields;
          for (let idx = 0; idx < customFields.length; idx++) {
            this.editStakeholder.customFieldsValues.push({
              FieldName: customFields[idx],
              Type: 'text',
              Value: ''
            });
          }
        } else {
          const currentValues = JSON.parse(this.editStakeholder.customFieldsValues);
          this.editStakeholder.customFieldsValues = [];
          const customFields = project.customStakeholderFields;
          for (let idx = 0; idx < customFields.length; idx++) {
            const currentValue = currentValues.find((x) => {
              return x.FieldName === customFields[idx];
            });
            this.editStakeholder.customFieldsValues.push({
              FieldName: customFields[idx],
              Type: 'text',
              Value: currentValue === undefined ? '' : currentValue.Value
            });
          }
          // this.editStakeholder.customFieldsValues = JSON.parse(this.editStakeholder.customFieldsValues);
        }

        for (const item in this.configReview.checkboxes) {
          this.configReview.checkboxes[item].checked = this.configReview.checkboxes[item].id === stakeholder.statusNr;
        }

        for (const item in this.configImportance.checkboxes) {
          this.configImportance.checkboxes[item].checked = this.configImportance.checkboxes[item].id === stakeholder.concernNr;
        }

        const responseSelectedIssues = await HttpClient.get('api/v1/projects/' + project.id + '/stakeholders/' + stakeholderId + '/issues');
        const responseIssues = await HttpClient.get('api/v1/projects/' + project.id + '/issues');

        this.selectedProjectIssues = responseSelectedIssues.data;
        this.orgSelectedProjectIssues = responseSelectedIssues.data;
        this.projectIssues = responseIssues.data;

        this.issuesEdit = [];
        for (let i = 0; i < this.projectIssues.length; i++) {
          let classStatus = null;
          if (this.projectIssues[i].status) {
            switch (this.projectIssues[i].status.nr) {
              case 1: classStatus = '--criticism'; break;
              case 2: classStatus = '--warning'; break;
              case 3: classStatus = '--focus'; break;
              case 4: classStatus = '--positive'; break;
              case 5: classStatus = '--unclear'; break;
            }
          } else {
            classStatus = '--unclear';
          }

          this.issuesEdit.push({
            id: this.projectIssues[i].id,
            className: classStatus,
            name: this.projectIssues[i].name
          });
        }

        this.issuesEditSelected = [];
        for (let i = 0; i < this.projectIssues.length; i++) {
          if (this.selectedProjectIssues.some(x => x.issueId === this.projectIssues[i].id)) {
            let classStatus = null;
            if (this.projectIssues[i].status) {
              switch (this.projectIssues[i].status.nr) {
                case 1:
                  classStatus = '--criticism';
                  break;
                case 2:
                  classStatus = '--warning';
                  break;
                case 3:
                  classStatus = '--focus';
                  break;
                case 4:
                  classStatus = '--positive';
                  break;
                case 5:
                  classStatus = '--unclear';
                  break;
              }
            } else {
              classStatus = '--unclear';
            }

            this.issuesEditSelected.push({
              id: this.projectIssues[i].id,
              className: classStatus,
              name: this.projectIssues[i].name
            });
          }
        }

        this.loadingEditStakeholder = false;
        this.loadingProjectIssues = false;
      } catch (error) {
        console.error('fetchEditStakeholder', error);
        this.loadingEditStakeholder = false;
        this.loadingProjectIssues = false;
        this.error = error;
      }
    },
    async fetchNewStakeholder (project) {
      this.loadingNewStakeholder = true;
      this.loadingProjectIssues = true;
      this.hideContactEditor = true;
      try {
        this.editStakeholder = {
          name: '',
          description: '',
          context: '',
          street: '',
          houseNumber: '',
          zipCode: '',
          city: '',
          telephone: '',
          type: '',
          legalForm: '',
          extraAddress: '',
          emailAddress: '',
          extraZipCode: '',
          extraCity: '',
          organisationalStructure: '',
          organisationalSize: '',
          localisation: '',
          goalsMission: '',
          financialDetails: '',
          importantConnections: '',
          followerDescription: '',
          followerSize: '',
          address: '',
          statusNr: 5,
          website: '',
          customCategory: '',
          participation: {
            nr: null,
            text: ''
          },
          emotion: {
            nr: null,
            text: ''
          },
          power: {
            nr: null,
            text: ''
          },
          urgency: {
            nr: null,
            text: ''
          },
          legitimity: {
            nr: null,
            text: ''
          },
          batna: {
            nr: null,
            text: ''
          },
          levelOfTrust: {
            nr: null,
            text: ''
          },
          line: {
            nr: null,
            text: ''
          },
          changableStrategy: {
            nr: null,
            text: ''
          },
          discussionText: '',
          concernNr: 5,
          concernText: '',
          pointOfView: '',
          customFieldsValues: '',
          projectIssues: null,
          gis: '',
          projectId: project.id
        };

        this.editStakeholder.customFieldsValues = [];
        const customFields = project.customStakeholderFields;
        for (let idx = 0; idx < customFields.length; idx++) {
          this.editStakeholder.customFieldsValues.push({
            FieldName: customFields[idx],
            Type: 'text',
            Value: ''
          });
        }

        for (const item in this.configReview.checkboxes) {
          this.configReview.checkboxes[item].checked = this.configReview.checkboxes[item].id === this.editStakeholder.statusNr;
        }

        for (const item in this.configImportance.checkboxes) {
          this.configImportance.checkboxes[item].checked = this.configImportance.checkboxes[item].id === this.editStakeholder.concernNr;
        }

        const responseIssues = await HttpClient.get('api/v1/projects/' + project.id + '/issues');
        this.projectIssues = responseIssues.data;
        this.selectedProjectIssues = [];
        this.orgSelectedProjectIssues = [];

        this.issuesEdit = [];
        for (let i = 0; i < this.projectIssues.length; i++) {
          let classStatus = null;
          if (this.projectIssues[i].status) {
            switch (this.projectIssues[i].status.nr) {
              case 1: classStatus = '--criticism'; break;
              case 2: classStatus = '--warning'; break;
              case 3: classStatus = '--focus'; break;
              case 4: classStatus = '--positive'; break;
              case 5: classStatus = '--unclear'; break;
            }
          } else {
            classStatus = '--unclear';
          }

          this.issuesEdit.push({
            id: this.projectIssues[i].id,
            className: classStatus,
            name: this.projectIssues[i].name
          });
        }
        this.issuesEditSelected = [];

        this.loadingNewStakeholder = false;
        this.loadingProjectIssues = false;
      } catch (error) {
        this.loadingNewStakeholder = false;
        this.loadingProjectIssues = false;
        this.error = error;
      }
    },
    // async fetchStakeholderVersion (payload) {
    //   this.loadingStakeholderVersion = true;

    //   try {
    //     await HttpClient.get('api/v1/projects/' + payload.projectId + '/stakeholders/' + payload.stakeholderId + '/version')
    //       .then((stakeholderVersion) => {
    //         this.stakeholderVersion = stakeholderVersion.data;
    //         this.loadingStakeholderVersion = false;
    //       });
    //   } catch (error) {
    //     this.error = error;
    //   }
    // },
    async fetchStakeholderContacts (projectId, stakeholderId) {
      this.loadingStakeholderContacts = true;

      try {
        await HttpClient.get('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/contacts')
          .then((contacts) => {
            this.configContact.contactData.contactPersons = contacts.data;

            // this.stakeholders = stakeholders.data;
            this.loadingStakeholderContacts = false;
          });
      } catch (error) {
        this.loadingStakeholderContacts = false;
        this.error = error;
      }
    },
    async updateStakeholderStatus (projectId, stakeholderId, status) {
      this.stakeholder.statusNr = status;
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId, this.stakeholder);
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderGeoJson (projectId, stakeholderId, geoJson) {
      const submitGeoJSson = {
        ProjectStakeholderId: stakeholderId,
        ProjectId: projectId,
        GeoJson: geoJson
      };

      this.stakeholder.gis = geoJson;

      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/geo', submitGeoJSson)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderStatusFromMatrix (project, stakeholderId, status) {
      await this.fetchStakeholder(project, stakeholderId);
      await this.updateStakeholderStatus(project.id, stakeholderId, status);
    },
    async updateStakeholderConcern (projectId, stakeholderId, concern) {
      this.stakeholder.concernNr = concern;
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId, this.stakeholder);
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderConcernFromMatrix (project, stakeholderId, concern) {
      await Promise.all([this.fetchStakeholder(project, stakeholderId)], this.updateStakeholderConcern(project.id, stakeholderId, concern));
      // await this.updateStakeholderConcern(project.id, stakeholderId, concern);
    },
    async updateStakeholderConcernText (projectId, stakeholderId, concernText) {
      this.stakeholder.concernText = concernText;
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId, this.stakeholder);
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderConcernTextFromMatrix (project, stakeholderId, concernText) {
      await this.fetchStakeholder(project, stakeholderId);
      await this.updateStakeholderConcernText(project.id, stakeholderId, concernText);
    },
    async updateStakeholderPointOfViewText (projectId, stakeholderId, pointOfViewText) {
      this.stakeholder.pointOfView = pointOfViewText;
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId, this.stakeholder);
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderPointOfViewTextFromMatrix (project, stakeholderId, pointOfViewText) {
      await this.fetchStakeholder(project, stakeholderId);
      await this.updateStakeholderPointOfViewText(project.id, stakeholderId, pointOfViewText);
    },
    async updateStakeholderParticipationText (projectId, stakeholderId, participationText) {
      this.stakeholder.participationText = participationText;
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId, this.stakeholder);
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderParticipationTextFromMatrix (project, stakeholderId, participationText) {
      await this.fetchStakeholder(project, stakeholderId);
      await this.updateStakeholderParticipationText(project.id, stakeholderId, participationText);
    },
    async updateStakeholderParticipation (projectId, stakeholderId, participation) {
      this.stakeholder.participationNr = participation;
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId, this.stakeholder)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderParticipationFromMatrix (project, stakeholderId, participation) {
      await this.fetchStakeholder(project, stakeholderId);
      await this.updateStakeholderParticipation(project.id, stakeholderId, participation);
    },
    async updateStakeholderEmotion (projectId, stakeholderId, emotion) {
      this.stakeholder.emotionNr = emotion;
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId, this.stakeholder)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderEmotionFromMatrix (project, stakeholderId, emotion) {
      await this.fetchStakeholder(project, stakeholderId);
      await this.updateStakeholderEmotion(project.id, stakeholderId, emotion);
    },
    async updateStakeholderUrgency (projectId, stakeholderId, urgency) {
      this.stakeholder.urgencyNr = urgency;
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId, this.stakeholder)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderUrgencyFromMatrix (project, stakeholderId, urgency) {
      await this.fetchStakeholder(project, stakeholderId);
      await this.updateStakeholderUrgency(project.id, stakeholderId, urgency);
    },
    async addNewStakeholderContact (projectId, stakeholderId, contact) {
      try {
        await HttpClient.post('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/contacts', contact);
        await this.fetchStakeholderContacts(projectId, stakeholderId);
      } catch (err) {
        console.error(err);
      }
    },
    async updateNewStakeholderContact (projectId, stakeholderId, contactId, contact) {
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/contacts/' + contactId, contact)
          .then(() => {
            this.fetchStakeholderContacts(projectId, stakeholderId);
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async deleteStakeholderContact (projectId, stakeholderId, contactId) {
      try {
        await HttpClient.delete('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/contacts/' + contactId)
          .then(() => {
            this.fetchStakeholderContacts(projectId, stakeholderId);
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholder (projectId, stakeholderId, stakeholder) {
      let customFields = null;
      if (stakeholder.customFieldsValues) {
        customFields = JSON.stringify(stakeholder.customFieldsValues);
      }

      const submitStakeholder = {
        name: stakeholder.name,
        description: stakeholder.description,
        context: stakeholder.context,
        street: stakeholder.street,
        houseNumber: stakeholder.houseNumber,
        zipCode: stakeholder.zipCode,
        city: stakeholder.city,
        telephone: stakeholder.telephone,
        type: stakeholder.type,
        legalForm: stakeholder.legalForm,
        extraAddress: stakeholder.extraAddress,
        emailAddress: stakeholder.emailAddress,
        extraZipCode: stakeholder.extraZipCode,
        extraCity: stakeholder.extraCity,
        organisationalStructure: stakeholder.organisationalStructure,
        organisationalSize: stakeholder.organisationalStructure,
        localisation: stakeholder.localisation,
        goalsMission: stakeholder.goalsMission,
        financialDetails: stakeholder.financialDetails,
        importantConnections: stakeholder.importantConnections,
        followerDescription: stakeholder.followerDescription,
        followerSize: stakeholder.followerSize,
        address: stakeholder.address,
        statusNr: stakeholder.statusNr,
        website: stakeholder.website,
        customCategory: stakeholder.customCategory,
        participationNr: Object.prototype.hasOwnProperty.call(stakeholder, 'participation') && stakeholder.participation !== null && stakeholder.participation.nr !== null ? stakeholder.participation.nr.nr : null,
        participationText: Object.prototype.hasOwnProperty.call(stakeholder, 'participation') && stakeholder.participation !== null && stakeholder.participation.text !== null ? stakeholder.participation.text : null,
        emotionNr: Object.prototype.hasOwnProperty.call(stakeholder, 'emotion') && stakeholder.emotion !== null && stakeholder.emotion.nr !== null ? stakeholder.emotion.nr.nr : null,
        emotionText: Object.prototype.hasOwnProperty.call(stakeholder, 'emotion') && stakeholder.emotion !== null && stakeholder.emotion.text !== null ? stakeholder.emotion.text : null,
        powerNr: Object.prototype.hasOwnProperty.call(stakeholder, 'power') && stakeholder.power !== null && stakeholder.power.nr !== null ? stakeholder.power.nr.nr : null,
        powerText: Object.prototype.hasOwnProperty.call(stakeholder, 'power') && stakeholder.power !== null && stakeholder.power.text !== null ? stakeholder.power.text : null,
        urgencyNr: Object.prototype.hasOwnProperty.call(stakeholder, 'urgency') && stakeholder.urgency !== null && stakeholder.urgency.nr !== null ? stakeholder.urgency.nr.nr : null,
        urgencyText: Object.prototype.hasOwnProperty.call(stakeholder, 'urgency') && stakeholder.urgency !== null && stakeholder.urgency.text !== null ? stakeholder.urgency.text : null,
        legitimityNr: Object.prototype.hasOwnProperty.call(stakeholder, 'legitimity') && stakeholder.legitimity !== null && stakeholder.legitimity.nr !== null ? stakeholder.legitimity.nr.nr : null,
        legitimityText: Object.prototype.hasOwnProperty.call(stakeholder, 'legitimity') && stakeholder.legitimity !== null && stakeholder.legitimity.text !== null ? stakeholder.legitimity.text : null,
        batnaNr: Object.prototype.hasOwnProperty.call(stakeholder, 'batna') && stakeholder.batna !== null && stakeholder.batna.nr !== null ? stakeholder.batna.nr.nr : null,
        batnaText: Object.prototype.hasOwnProperty.call(stakeholder, 'batna') && stakeholder.batna !== null && stakeholder.batna.text !== null ? stakeholder.batna.text : null,
        levelOfTrustNr: Object.prototype.hasOwnProperty.call(stakeholder, 'levelOfTrust') && stakeholder.levelOfTrust !== null && stakeholder.levelOfTrust.nr !== null ? stakeholder.levelOfTrust.nr.nr : null,
        levelOfTrustText: Object.prototype.hasOwnProperty.call(stakeholder, 'levelOfTrust') && stakeholder.levelOfTrust !== null && stakeholder.levelOfTrust.text !== null ? stakeholder.levelOfTrust.text : null,
        lineNr: Object.prototype.hasOwnProperty.call(stakeholder, 'line') && stakeholder.line !== null && stakeholder.line.nr !== null ? stakeholder.line.nr.nr : null,
        lineText: Object.prototype.hasOwnProperty.call(stakeholder, 'line') && stakeholder.line !== null && stakeholder.line.text !== null ? stakeholder.line.text : null,
        changableStrategyNr: Object.prototype.hasOwnProperty.call(stakeholder, 'changableStrategy') && stakeholder.changableStrategy !== null && stakeholder.changableStrategy.nr !== null ? stakeholder.changableStrategy.nr.nr : null,
        changableStrategyText: Object.prototype.hasOwnProperty.call(stakeholder, 'changableStrategy') && stakeholder.changableStrategy !== null && stakeholder.changableStrategy.text !== null ? stakeholder.changableStrategy.text : null,
        discussionText: stakeholder.discussionText,
        concernNr: stakeholder.concernNr,
        concernText: stakeholder.concernText,
        pointOfView: stakeholder.pointOfView,
        customFieldsValues: customFields,
        projectId: stakeholder.projectId,
        gis: stakeholder.gis,
        id: stakeholder.id
      };

      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId, submitStakeholder);

        if (Object.prototype.hasOwnProperty.call(stakeholder, 'projectIssues')) {
          // add issues?
          if (stakeholder.projectIssues) {
            for (let idx = 0; idx < stakeholder.projectIssues.length; idx++) {
              if (this.orgSelectedProjectIssues.length === 0 || this.orgSelectedProjectIssues.find(x => x.issueId === stakeholder.projectIssues[idx].id) === undefined) {
                const stakeholderIssue = {
                  issueId: stakeholder.projectIssues[idx].id,
                  stakeholderId: stakeholderId,
                  concernNr: 5, // default, status = onduidelijk,
                  importanceNr: 0 // default, status = onduidelijk
                };
                try {
                  await HttpClient.post('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/issues/' + stakeholder.projectIssues[idx].id, stakeholderIssue);
                } catch (err) {
                  console.error('Error Post IssueStakeholder:' + err);
                }
              }
            }
          }
          // delete issues?
          if (this.orgSelectedProjectIssues.length > 0) {
            for (let idx = 0; idx < this.orgSelectedProjectIssues.length; idx++) {
              if (stakeholder.projectIssues.find(x => x.id === this.orgSelectedProjectIssues[idx].issueId) === undefined) {
                try {
                  await HttpClient.delete('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/issues/' + this.orgSelectedProjectIssues[idx].issueId);
                } catch (err) {
                  console.error('Error delete IssueStakeholder:' + err);
                }
              }
            }
          }
        }
      } catch (err) {
        console.error(err);

        return false;
      }

      return true;
    },
    async deleteStakeholder (projectId, stakeholderId) {
      this.loadingEditStakeholder = true;

      try {
        await HttpClient.delete('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId);
      } catch (err) {
        console.error(err);
        this.loadingEditStakeholder = false;
      }

      this.loadingEditStakeholder = false;
    },
    async createNewStakeholder (projectId, stakeholder) {
      let customFields = null;
      if (stakeholder.customFieldsValues) {
        customFields = JSON.stringify(stakeholder.customFieldsValues);
      }

      const submitStakeholder = {
        name: stakeholder.name,
        description: stakeholder.description,
        context: stakeholder.context,
        street: stakeholder.street,
        houseNumber: stakeholder.houseNumber,
        zipCode: stakeholder.zipCode,
        city: stakeholder.city,
        telephone: stakeholder.telephone,
        type: stakeholder.type,
        legalForm: stakeholder.legalForm,
        extraAddress: stakeholder.extraAddress,
        emailAddress: stakeholder.emailAddress,
        extraZipCode: stakeholder.extraZipCode,
        extraCity: stakeholder.extraCity,
        organisationalStructure: stakeholder.organisationalStructure,
        organisationalSize: stakeholder.organisationalStructure,
        localisation: stakeholder.localisation,
        goalsMission: stakeholder.goalsMission,
        financialDetails: stakeholder.financialDetails,
        importantConnections: stakeholder.importantConnections,
        followerDescription: stakeholder.followerDescription,
        followerSize: stakeholder.followerSize,
        address: stakeholder.address,
        statusNr: stakeholder.statusNr,
        website: stakeholder.website,
        customCategory: stakeholder.customCategory,
        participationNr: Object.prototype.hasOwnProperty.call(stakeholder, 'participation') && stakeholder.participation !== null && stakeholder.participation.nr !== null ? stakeholder.participation.nr.nr : null,
        participationText: Object.prototype.hasOwnProperty.call(stakeholder, 'participation') && stakeholder.participation !== null && stakeholder.participation.text !== null ? stakeholder.participation.text : null,
        emotionNr: Object.prototype.hasOwnProperty.call(stakeholder, 'emotion') && stakeholder.emotion !== null && stakeholder.emotion.nr !== null ? stakeholder.emotion.nr.nr : null,
        emotionText: Object.prototype.hasOwnProperty.call(stakeholder, 'emotion') && stakeholder.emotion !== null && stakeholder.emotion.text !== null ? stakeholder.emotion.text : null,
        powerNr: Object.prototype.hasOwnProperty.call(stakeholder, 'power') && stakeholder.power !== null && stakeholder.power.nr !== null ? stakeholder.power.nr.nr : null,
        powerText: Object.prototype.hasOwnProperty.call(stakeholder, 'power') && stakeholder.power !== null && stakeholder.power.text !== null ? stakeholder.power.text : null,
        urgencyNr: Object.prototype.hasOwnProperty.call(stakeholder, 'urgency') && stakeholder.urgency !== null && stakeholder.urgency.nr !== null ? stakeholder.urgency.nr.nr : null,
        urgencyText: Object.prototype.hasOwnProperty.call(stakeholder, 'urgency') && stakeholder.urgency !== null && stakeholder.urgency.text !== null ? stakeholder.urgency.text : null,
        legitimityNr: Object.prototype.hasOwnProperty.call(stakeholder, 'legitimity') && stakeholder.legitimity !== null && stakeholder.legitimity.nr !== null ? stakeholder.legitimity.nr.nr : null,
        legitimityText: Object.prototype.hasOwnProperty.call(stakeholder, 'legitimity') && stakeholder.legitimity !== null && stakeholder.legitimity.text !== null ? stakeholder.legitimity.text : null,
        lineNr: Object.prototype.hasOwnProperty.call(stakeholder, 'line') && stakeholder.line !== null && stakeholder.line.nr !== null ? stakeholder.line.nr.nr : null,
        lineText: Object.prototype.hasOwnProperty.call(stakeholder, 'line') && stakeholder.line !== null && stakeholder.line.text !== null ? stakeholder.line.text : null,
        changableStrategyNr: Object.prototype.hasOwnProperty.call(stakeholder, 'changableStrategy') && stakeholder.changableStrategy !== null && stakeholder.changableStrategy.nr !== null ? stakeholder.changableStrategy.nr.nr : null,
        changableStrategyText: Object.prototype.hasOwnProperty.call(stakeholder, 'changableStrategy') && stakeholder.changableStrategy !== null && stakeholder.changableStrategy.text !== null ? stakeholder.changableStrategy.text : null,
        discussionText: stakeholder.discussionText,
        concernNr: stakeholder.concernNr,
        concernText: stakeholder.concernText,
        pointOfView: stakeholder.pointOfView,
        customFieldsValues: customFields,
        projectId: stakeholder.projectId
      };
      try {
        const response = await HttpClient.post('api/v1/projects/' + projectId + '/stakeholders/', submitStakeholder);
        const responseData = response.data;
        const stakeholderId = responseData.id;

        if (Object.prototype.hasOwnProperty.call(stakeholder, 'projectIssues')) {
          if (stakeholder.projectIssues) {
            for (let idx = 0; idx < stakeholder.projectIssues.length; idx++) {
              const stakeholderIssue = {
                issueId: stakeholder.projectIssues[idx].id,
                stakeholderId: stakeholderId,
                concernNr: 5, // default, status = onduidelijk,
                importanceNr: 0 // default, status = onduidelijk
              };
              try {
                await HttpClient.post('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/issues/' + stakeholder.projectIssues[idx].id, stakeholderIssue);
              } catch (err) {
                console.error('Error Post IssueStakeholder:' + err);
              }
            }
          }
        }

        return response.data;
      } catch (err) {
        console.error(err);

        return null;
      }
    },
    async createNewStakeholderFast (projectId, stakeholder) {
      const submitStakeholder = {
        projectId: projectId,
        name: stakeholder.name,
        description: stakeholder.description,
        statusNr: stakeholder.status !== null ? stakeholder.status : 0,
        customCategory: stakeholder.customCategory
      };
      try {
        const response = await HttpClient.post('api/v1/projects/' + projectId + '/stakeholders/', submitStakeholder);
        return response.data;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    showField (fieldName) {
      if (!this.availableStakeholderFields || this.availableStakeholderFields === null || this.availableStakeholderFields.length === 0) {
        return true;
      }
      const result = this.availableStakeholderFields.find(x => x.FieldName === fieldName);
      if (result) {
        return result.Available;
      } else {
        return false;
      }
    },
    stripHTML (value) {
      // let result = value.replaceAll(/(<([^>]+)>)/gi, ''); // replaceAll is ES12 and will not work in some older browsers
      let result = value.replace(/(<([^>]+)>)/gi, '');
      result = result.replace('&nbsp;', '');
      result = result.trim();
      return result;
    }
  }
});
