<script setup>
// Component imports
import UiFormLabel from '@/core/ui/UiFormLabel';
import PInputtext from 'primevue/inputtext';
import UiFormErrorMessage from '@/core/ui/UiFormErrorMessage';

// Define props
const props = defineProps({
  labelTitle: String,
  labelFor: String,
  optional: String,
  modelValue: String,
  showError: Boolean,
  errorMessage: String
});

// Define emits
const emit = defineEmits(['update:modelValue', 'emitDirty']);

// Variables

// Functions
function updateValue (value) {
  emit('update:modelValue', value);
  emit('emitDirty');
}
</script>

<template>
  <div class="ui-label-input">
    <ui-form-label
      class="mb-2"
      :label-title="props.labelTitle"
      :label-for="props.labelFor"
      :optional="props.optional">
    </ui-form-label>
    <p-inputtext
      class="ui-label-input__input d-block w-100"
      :id="props.labelFor"
      :name="props.labelFor"
      :value="props.modelValue"
      @input="updateValue($event.target.value)">
    </p-inputtext>
    <ui-form-error-message
      class="mt-1"
      xv-if="props.showError && props.modelValue.length === 0"
      v-if="props.showError">
      {{ props.errorMessage }}
    </ui-form-error-message>
  </div>
</template>

<style lang="scss">
.ui-label-input {
  &__input {
    height: calc-rem(40);
  }
}
</style>
