<script setup>
// Define props
const props = defineProps({
  iconRight: String,
  iconLeft: String,
  routeLink: String,
  title: String
});

// Define emits
const emit = defineEmits(['btnClick']);

// Variables
const showRightIcon = () => {
  if (props.iconRight === '' || props.iconRight === null) {
    return false;
  }
  return true;
};
const showLeftIcon = () => {
  if (props.iconLeft === '' || props.iconLeft === null) {
    return false;
  }
  return true;
};
const submitEvent = () => {
  emit('btnClick');
};
</script>

<template>
  <router-link
    class="ui-router-link d-flex flex-nowrap justify-content-between align-items-center w-100 text-decoration-none"
    :to="props.routeLink"
    @click="submitEvent()">
    <span class="d-flex flex-nowrap">
      <span
        class="pi"
        :class="props.iconLeft"
        v-if="showLeftIcon()">
      </span>
      <span
        class="d-inline-block text-nowrap">
        {{ props.title }}
      </span>
    </span>
    <span
      class="pi"
      :class="props.iconRight"
      v-if="showRightIcon()">
    </span>
  </router-link>
</template>

<style lang="scss">
.ui-router-link {
  margin-bottom: calc-rem(2);
  padding-top: calc-rem(13);
  padding-right: calc-rem(12);
  padding-bottom: calc-rem(13);
  padding-left: calc-rem(12);
  border-radius: $borderRadius;
  font-size: calc-rem(14);
  font-weight: 400;
  line-height: calc-rem(14);
  color: $textColor;
  transition: all $transitionDuration ease-out;

  &:hover {
    background-color: $secondaryButtonHoverBg;
  }

  &:focus {
    @extend %button-focus;
  }

  .pi {
    margin-right: calc-rem(7);
    font-size: inherit;
  }
}
</style>
