// import HomeView from '@/views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Projects/ProjectsView.vue')
    // component: HomeView
  },
  {
    path: '/me',
    name: 'me',
    component: () => import('@/views/Me/MeView.vue')
  },
  /* {
    path: '/me/edit',
    component: () => import('@/views/Me/MeEditView.vue')
  }, */
  {
    path: '/me/terms',
    name: 'me-terms',
    component: () => import('@/views/Me/MeTermsView.vue')
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/Projects/ProjectsView.vue')
  },
  {
    path: '/projects/:projectId',
    name: 'project-details',
    component: () => import('@/views/Projects/ProjectView.vue')
  },
  {
    path: '/projects/:projectId/screenshotmap',
    name: 'project-screenshot-map',
    screenshot: true,
    component: () => import('@/views/Projects/ProjectScreenshotMapView.vue')
  },
  {
    path: '/projects/create',
    name: 'project-create',
    component: () => import('@/views/Projects/ProjectCreateView.vue')
  },
  {
    path: '/projects/:projectId/edit',
    name: 'project-edit',
    component: () => import('@/views/Projects/ProjectEditView.vue')
  },
  {
    path: '/projects/:projectId/archive',
    name: 'project-archive',
    component: () => import('@/views/Projects/ProjectArchiveView.vue')
  },
  {
    path: '/projects/index',
    name: 'index-projects',
    component: () => import('@/views/Projects/ProjectSearchIndexView.vue')
  },
  {
    path: '/projects/:projectId/members',
    name: 'project-members',
    component: () => import('@/views/Projects/ProjectMembersView.vue')
  },
  {
    path: '/projects/:projectId/stakeholders',
    name: 'project-stakeholders',
    component: () => import('@/views/Projects/Stakeholders/ProjectStakeholdersView.vue')
  },
  {
    path: '/projects/:projectId/stakeholders/create',
    name: 'project-stakeholder-create',
    component: () => import('@/views/Projects/Stakeholders/ProjectStakeholderCreate.vue')
  },
  {
    path: '/projects/:projectId/stakeholders/:stakeholderId',
    name: 'project-stakeholder-details',
    component: () => import('@/views/Projects/Stakeholders/ProjectStakeholderView.vue')
  },
  {
    path: '/projects/:projectId/stakeholders/:stakeholderId/Edit',
    name: 'project-stakeholder-edit',
    component: () => import('@/views/Projects/Stakeholders/ProjectStakeholderEdit.vue')
  },
  {
    path: '/projects/:projectId/issues',
    name: 'project-issues',
    component: () => import('@/views/Projects/Issues/ProjectIssuesView.vue')
  },
  {
    path: '/projects/:projectId/issues/create',
    name: 'project-issue-create',
    component: () => import('@/views/Projects/Issues/ProjectIssueCreateView.vue')
  },
  {
    path: '/projects/:projectId/issues/:issueId',
    name: 'project-issue-details',
    component: () => import('@/views/Projects/Issues/ProjectIssueView.vue')
  },
  {
    path: '/projects/:projectId/issues/:issueId/edit',
    name: 'project-issue-edit',
    component: () => import('@/views/Projects/Issues/ProjectIssueEditView.vue')
  },
  {
    path: '/projects/:projectId/stakeholders/:stakeholderId/issues/:issueId',
    name: 'project-stakeholder-issue-details',
    component: () => import('@/views/Projects/StakeholderIssue/ProjectStakeholderIssueView.vue')
  },
  {
    path: '/projects/:projectId/issues/:issueId/stakeholders/:stakeholderId',
    name: 'project-issue-stakeholder-details',
    component: () => import('@/views/Projects/StakeholderIssue/ProjectStakeholderIssueView.vue')
  },
  {
    path: '/projects/:projectId/stakeholders/:stakeholderId/issues/:issueId/edit',
    name: 'project-stakeholder-issue-edit',
    component: () => import('@/views/Projects/StakeholderIssue/ProjectStakeholderIssueEdit.vue')
  },
  {
    path: '/projects/:projectId/issues/:issueId/stakeholders/:stakeholderId/edit',
    name: 'project-issue-stakeholder-edit',
    component: () => import('@/views/Projects/StakeholderIssue/ProjectStakeholderIssueEdit.vue')
  },
  {
    path: '/projects/:projectId/contacts',
    name: 'project-contacts',
    component: () => import('@/views/Projects/ProjectContactsView.vue')
  },
  {
    path: '/projects/:projectId/concernmatrix',
    name: 'project-concernmatrix',
    component: () => import('@/views/Projects/ProjectConcernMatrixView.vue')
  },
  {
    path: '/projects/:projectId/concernmatrixold',
    name: 'project-concernmatrix-old',
    component: () => import('@/views/Projects/ProjectConcernMatrixViewOld.vue')
  },
  {
    path: '/projects/:projectId/importancematrix',
    name: 'project-importancematrix',
    component: () => import('@/views/Projects/ProjectImportanceMatrixView.vue')
  },
  {
    path: '/projects/:projectId/importancematrixold',
    name: 'project-importancematrix-old',
    component: () => import('@/views/Projects/ProjectImportanceMatrixViewOld.vue')
  },
  {
    path: '/projects/:projectId/progressmatrix',
    name: 'project-progressmatrix',
    component: () => import('@/views/Projects/ProjectProgressMatrixView.vue')
  },
  {
    path: '/projects/:projectId/progressmatrixold',
    name: 'project-progressmatrix-old',
    component: () => import('@/views/Projects/ProjectProgressMatrixViewOld.vue')
  },
  {
    path: '/projects/:projectId/environmentmonitor',
    name: 'project-environmentmonitor',
    component: () => import('@/views/Projects/ProjectEnvironmentMonitorView.vue')
  },
  {
    path: '/projects/:projectId/environmentmonitorold',
    name: 'project-environmentmonitor-old',
    component: () => import('@/views/Projects/ProjectEnvironmentMonitorViewOld.vue')
  },
  {
    path: '/projects/:projectId/stakeholderissueanalysis',
    name: 'project-stakeholderissueanalysis',
    component: () => import('@/views/Projects/ProjectStakeholderIssueAnalysisView.vue')
  },
  {
    path: '/projects/:projectId/allissues',
    name: 'project-allissues',
    component: () => import('@/views/Projects/ProjectAllIssuesView.vue')
  },
  {
    path: '/projects/:projectId/notes',
    name: 'project-notes',
    component: () => import('@/views/Projects/Notes/ProjectNotesView.vue')
  },
  {
    path: '/projects/:projectId/notes/:noteId',
    name: 'project-note-details',
    component: () => import('@/views/Projects/Notes/ProjectNoteView.vue')
  },
  {
    path: '/projects/:projectId/notes/create',
    component: () => import('@/views/Projects/Notes/ProjectNoteCreateView.vue')
  },
  {
    path: '/projects/:projectId/notes/:noteId/edit',
    component: () => import('@/views/Projects/Notes/ProjectNoteEditView.vue')
  },
  {
    path: '/projects/:projectId/media',
    name: 'project-media',
    component: () => import('@/views/Projects/Media/ProjectMediaView.vue')
  },
  {
    path: '/projects/:projectId/media/:mediaId',
    name: 'project-media-details',
    component: () => import('@/views/Projects/Media/ProjectMediaDetailView.vue')
  },
  {
    path: '/projects/:projectId/media/create',
    component: () => import('@/views/Projects/Media/ProjectMediaCreateView.vue')
  },
  {
    path: '/projects/:projectId/media/:mediaId/edit',
    component: () => import('@/views/Projects/Media/ProjectMediaEditView.vue')
  },
  {
    path: '/profile/:profileId',
    name: 'profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/help/:projectId',
    name: 'help',
    component: () => import('@/views/HelpView.vue')
  },
  {
    path: '/reportissue',
    name: 'reportissue',
    component: () => import('@/views/ReportIssueView.vue')
  },
  {
    path: '/searchresults',
    name: 'searchresults',
    component: () => import('@/views/SearchResultsView.vue')
  },
  {
    path: '/projects/:projectId/projectusersoverview',
    name: 'projectusersoverview',
    component: () => import('@/views/ProjectUsersOverview.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/LogoutView.vue')
  }
];

export default routes;
