import { defineStore } from 'pinia';
import { defineEmits } from 'vue';
import HttpClient from '@/services/httpclient';
import historyHelper from '@/services/historyhelper';
import i18n from '../../../translations';

const t = (input) => {
  return i18n.global.t(input);
};

export const useIssuesStore = defineStore({
  id: 'issues',
  state: () => {
    return ({
      loadingIssue: true,
      loadingIssueStakeholders: true,
      loadingProjectStakeholders: true,
      loadingEditIssue: true,
      error: null,
      pageTitle: t('General.Issues'),
      issue: {},
      issueVersion: {},
      contentCard: null,
      projectStakeholders: {},
      stakeholdersEdit: [],
      stakeholdersEditSelected: [],
      selectedProjectStakeholders: {},
      orgSelectedProjectStakeholders: {},
      issueStakeholders: {},
      stakeholdersOverview: null,
      issueToAdd: {},
      author: null,
      moderator: null,
      emit: defineEmits(['update-title']),
      defaultStatus: {
        id: 0,
        labelTitle: t('Status.Onduidelijk'),
        value: 'onduidelijk',
        nameCheckboxes: 'checkboxes',
        className: '--unclear',
        checked: false
      },
      configReview: {
        cardTitle: t('General.Tension'),
        forCheckboxes: 'beoordelingspanning',
        checkboxes: [
          {
            id: 0,
            labelTitle: t('Status.Onduidelijk'),
            value: 'onduidelijk',
            nameCheckboxes: 'checkboxes',
            className: '--unclear',
            checked: false
          },
          {
            id: 4,
            labelTitle: t('Status.Positief'),
            value: 'positief',
            nameCheckboxes: 'checkboxes',
            className: '--positive',
            checked: false
          },
          {
            id: 3,
            labelTitle: t('Status.Aandacht'),
            value: 'aandacht',
            nameCheckboxes: 'checkboxes',
            className: '--focus',
            checked: false
          },
          {
            id: 2,
            labelTitle: t('Status.Waarschuwing'),
            value: 'waarschuwing',
            nameCheckboxes: 'checkboxes',
            className: '--warning',
            checked: false
          },
          {
            id: 1,
            labelTitle: t('Status.Kritiek'),
            value: 'kritiek',
            nameCheckboxes: 'checkboxes',
            className: '--criticism',
            checked: false
          }
        ]
      },
      stakeholderOverview: {
        cardTitle: t('General.Stakeholders'),
        tabs: []
      },
      features: [
        {
          id: 0,
          name: 'emotion',
          options: [
            {
              nr: 0,
              name: t('General.Not')
            },
            {
              nr: 1,
              name: t('General.High')
            },
            {
              nr: 2,
              name: t('General.Low>')
            }
          ]
        },
        {
          id: 1,
          name: 'intelligibility',
          options: [
            {
              nr: null,
              name: ''
            },
            {
              nr: 1,
              name: t('General.Intelligibility.VeryUnderstandable')
            },
            {
              nr: 2,
              name: t('General.Intelligibility.Understandable')
            },
            {
              nr: 3,
              name: t('General.Intelligibility.ReasonableUnderstandable')
            },
            {
              nr: 4,
              name: t('General.Intelligibility.LimitedUnderstandable')
            },
            {
              nr: 5,
              name: t('General.Intelligibility.NotUnderstandable')
            }
          ]
        },
        {
          id: 2,
          name: 'visibility',
          options: [
            {
              nr: null,
              name: ''
            },
            {
              nr: 1,
              name: t('General.Visibility.VeryVisible')
            },
            {
              nr: 2,
              name: t('General.Visibility.VeryVisible')
            },
            {
              nr: 3,
              name: t('General.Visibility.ReasonableVisible')
            },
            {
              nr: 4,
              name: t('General.Visibility.LimitedVisible')
            },
            {
              nr: 5,
              name: t('General.Visibility.NotVisible')
            }
          ]
        },
        {
          id: 3,
          name: 'mediaSensitivity',
          options: [
            {
              nr: null,
              name: ''
            },
            {
              nr: 1,
              name: t('General.MediaSensitivity.VerySensitive')
            },
            {
              nr: 2,
              name: t('General.MediaSensitivity.Sensitive')
            },
            {
              nr: 3,
              name: t('General.MediaSensitivity.ReasonableSensitive')
            },
            {
              nr: 4,
              name: t('General.MediaSensitivity.LimitedSensitive')
            },
            {
              nr: 5,
              name: t('General.MediaSensitivity.NotSensitive')
            }
          ]
        },
        {
          id: 4,
          name: 'developmentPhase',
          options: [
            {
              nr: 1,
              name: t('General.DevelopmentStage.Acknowledgement'),
              description: t('General.DevelopmentStage.Acknowledgemen.Description')
            },
            {
              nr: 2,
              name: t('General.DevelopmentStage.PolicyFormulation'),
              description: t('General.DevelopmentStage.PolicyFormulation.Description')
            },
            {
              nr: 3,
              name: t('General.DevelopmentStage.Solution'),
              description: t('General.DevelopmentStage.Solution.Description')
            },
            {
              nr: 4,
              name: t('General.DevelopmentStage.PolicyAndManagement'),
              description: t('General.DevelopmentStage.PolicyAndManagement.Description')
            }
          ]
        },
        {
          id: 5,
          name: 'complexity',
          options: [
            {
              nr: 2,
              name: t('IssueComplexity.Extreem')
            },
            {
              nr: 3,
              name: t('IssueComplexity.Hoog')
            },
            {
              nr: 4,
              name: t('IssueComplexity.Midden')
            },
            {
              nr: 5,
              name: t('IssueComplexity.Laag')
            },
            {
              nr: 6,
              name: t('IssueComplexity.Niet')
            }
          ]
        }
      ],
      availableIssueFields: null
    });
  },
  getters: {
  },
  actions: {
    async fetchSingleIssue (project, issuesId, selectedDate) {
      this.loadingIssue = true;
      this.loadingIssueStakeholders = true;

      try {
        const addOn = selectedDate ? '/version?date=' + selectedDate : '';
        const response = await HttpClient.get('api/v1/projects/' + project.id + '/issues/' + issuesId);
        this.issue = response.data;
        if (selectedDate) {
          const responseVersion = await HttpClient.get('api/v1/projects/' + project.id + '/issues/' + issuesId + addOn);
          this.issueVersion = responseVersion.data;
        }

        if (project.availableIssueFields !== null) {
          this.availableIssueFields = JSON.parse(project.availableIssueFields);
        }

        this.contentCard = '';
        // customCategory
        if (this.issue.customCategory) {
          this.contentCard += '<h3>' + t('General.Category') + '</h3>';
          this.contentCard += '<p>' + this.issue.customCategory + '</p>';
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.issue.customCategory, this.issueVersion.customCategory, selectedDate);
        }

        // description
        if (this.issue.description) {
          this.contentCard += '<h3>' + t('Issue.Description') + '</h3>';
          if (this.issue.description.indexOf('<p>') < 0) {
            this.contentCard += '<p>' + this.issue.description + '</p>';
          } else {
            this.contentCard += this.issue.description;
          }
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.issue.description, this.issueVersion.description, selectedDate);
        }

        let contentFeatures = '';
        // emotionNr
        if (this.showField('Issue.Emotion')) {
          if (this.issue.emotionNr || (this.issue.emotionText && this.stripHTML(this.issue.emotionText))) {
            contentFeatures += '<h3>' + t('Issue.Frontend.Emotion') + '</h3>';
            if (this.issue.emotionNr) {
              const type = this.features.find(x => x.name === 'emotion').options.find(y => y.nr === this.issue.emotionNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.issue.emotionText) {
              contentFeatures += this.issue.emotionText;
            }
          }
        }
        if (selectedDate) {
          const current = this.features.find(x => x.name === 'emotion').options.find(y => y.nr === this.issue.emotionNr);
          const old = this.features.find(x => x.name === 'emotion').options.find(y => y.nr === this.issueVersion.emotionNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.issue.emotionText, this.issueVersion.emotionText, selectedDate);
        }

        // intelligibilityNr
        if (this.showField('Issue.Intelligibility')) {
          if (this.issue.intelligibilityNr || (this.issue.intelligibilityText && this.stripHTML(this.issue.intelligibilityText))) {
            contentFeatures += '<h3>' + t('Issue.Frontend.Intelligibility') + '</h3>';
            if (this.issue.intelligibilityNr) {
              const type = this.features.find(x => x.name === 'intelligibility').options.find(y => y.nr === this.issue.intelligibilityNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.issue.intelligibilityText) {
              contentFeatures += this.issue.intelligibilityText;
            }
          }
        }
        if (selectedDate) {
          const current = this.features.find(x => x.name === 'intelligibility').options.find(y => y.nr === this.issue.intelligibilityNr);
          const old = this.features.find(x => x.name === 'intelligibility').options.find(y => y.nr === this.issueVersion.intelligibilityNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.issue.intelligibilityText, this.issueVersion.intelligibilityText, selectedDate);
        }

        // visibilityNr
        if (this.showField('Issue.Visibility')) {
          if (this.issue.visibilityNr || (this.issue.visibilityText && this.stripHTML(this.issue.visibilityText))) {
            contentFeatures += '<h3>' + t('Issue.Frontend.Visibility') + '</h3>';
            if (this.issue.visibilityNr) {
              const type = this.features.find(x => x.name === 'visibility').options.find(y => y.nr === this.issue.visibilityNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.issue.visibilityText) {
              contentFeatures += this.issue.visibilityText;
            }
          }
        }
        if (selectedDate) {
          const current = this.features.find(x => x.name === 'visibility').options.find(y => y.nr === this.issue.visibilityNr);
          const old = this.features.find(x => x.name === 'visibility').options.find(y => y.nr === this.issueVersion.visibilityNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.issue.visibilityText, this.issueVersion.visibilityText, selectedDate);
        }

        if (this.showField('Issue.MediaSensitivity')) {
          if (this.issue.mediaSensitivityNr || (this.issue.mediaSensitivityText && this.stripHTML(this.issue.mediaSensitivityText))) {
            contentFeatures += '<h3>' + t('Issue.MediaSensitivity') + '</h3>';
            if (this.issue.mediaSensitivityNr) {
              const type = this.features.find(x => x.name === 'mediaSensitivity').options.find(y => y.nr === this.issue.mediaSensitivityNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.issue.mediaSensitivityText) {
              contentFeatures += this.issue.mediaSensitivityText;
            }
          }
        }
        if (selectedDate) {
          const current = this.features.find(x => x.name === 'mediaSensitivity').options.find(y => y.nr === this.issue.mediaSensitivityNr);
          const old = this.features.find(x => x.name === 'mediaSensitivity').options.find(y => y.nr === this.issueVersion.mediaSensitivityNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.issue.mediaSensitivityText, this.issueVersion.mediaSensitivityText, selectedDate);
        }

        // developmentPhaseNr
        if (this.showField('Issue.DevelopmentPhase')) {
          if (this.issue.developmentPhaseNr || (this.issue.developmentPhaseText && this.stripHTML(this.issue.developmentPhaseText))) {
            contentFeatures += '<h3>' + t('Issue.DevelopmentPhase') + '</h3>';
            if (this.issue.developmentPhaseNr) {
              const type = this.features.find(x => x.name === 'developmentPhase').options.find(y => y.nr === this.issue.developmentPhaseNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.issue.developmentPhaseText) {
              contentFeatures += this.issue.developmentPhaseText;
            }
          }
        }
        if (selectedDate) {
          const current = this.features.find(x => x.name === 'developmentPhase').options.find(y => y.nr === this.issue.developmentPhaseNr);
          const old = this.features.find(x => x.name === 'developmentPhase').options.find(y => y.nr === this.issueVersion.developmentPhaseNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.issue.developmentPhaseText, this.issueVersion.developmentPhaseText, selectedDate);
        }

        // complexityNr
        if (this.showField('Issue.Complexity')) {
          if (this.issue.complexityNr || (this.issue.complexityText && this.stripHTML(this.issue.complexityText))) {
            contentFeatures += '<h3>' + t('Issue.Complexity') + '</h3>';
            if (this.issue.complexityNr !== null) {
              const type = this.features.find(x => x.name === 'complexity').options.find(y => y.nr === this.issue.complexityNr);
              contentFeatures += '<p><strong>' + type.name + '</strong></p>';
            }
            if (this.issue.complexityText !== null) {
              contentFeatures += this.issue.complexityText;
            }
          }
        }
        if (selectedDate) {
          const current = this.features.find(x => x.name === 'complexity').options.find(y => y.nr === this.issue.complexityNr);
          const old = this.features.find(x => x.name === 'complexity').options.find(y => y.nr === this.issueVersion.complexityNr);
          contentFeatures += historyHelper.historyDropdownTextBlock(current?.name, old?.name, this.issue.complexityText, this.issueVersion.complexityText, selectedDate);
        }

        if (contentFeatures !== '') {
          this.contentCard += '<h2>Kenmerken</h2>';
          this.contentCard += contentFeatures;
        }
        if (this.showField('Issue.Narrative') && (this.issue.narrative && this.stripHTML(this.issue.narrative))) {
          this.contentCard += '<h3>' + t('Issue.Narrative') + '</h3>';
          this.contentCard += this.issue.narrative;
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.issue.narrative, this.issueVersion.narrative, selectedDate);
        }

        if (this.issue.customFieldsValues && this.issue.customFieldsValues.length > 0) {
          const customFields = JSON.parse(this.issue.customFieldsValues);
          for (let idx = 0; idx < customFields.length; idx++) {
            if (customFields[idx].Value && this.stripHTML(customFields[idx].Value) && project.customIssueFields.indexOf(customFields[idx].FieldName) > -1) {
              this.contentCard += '<h3>' + customFields[idx].FieldName + '</h3>';
              this.contentCard += customFields[idx].Value + '</h3>';
            }
          }
        }

        if (this.showField('Issue.Strategy') && (this.issue.strategy && this.stripHTML(this.issue.strategy))) {
          this.contentCard += '<h3>' + t('Issue.Strategy') + '</h3>';
          this.contentCard += this.issue.strategy;
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.issue.strategy, this.issueVersion.strategy, selectedDate);
        }
        if (this.showField('Issue.DiscussionText') && (this.issue.discussionText && this.stripHTML(this.issue.discussionText))) {
          this.contentCard += '<h3>' + t('Issue.DiscussionText') + '</h3>';
          this.contentCard += this.issue.discussionText;
        }
        if (selectedDate) {
          this.contentCard += historyHelper.historyTextBlock(this.issue.discussionText, this.issueVersion.discussionText, selectedDate);
        }

        this.author = this.issue.addUser ?? null;
        this.moderator = this.issue.modUser ?? null;
        this.pageTitle = this.issue.name;
        for (const item in this.configReview.checkboxes) {
          this.configReview.checkboxes[item].checked = this.configReview.checkboxes[item].id === this.issue.status.nr;
        }

        const responseStakeholders = await HttpClient.get('api/v1/projects/' + project.id + '/issues/' + issuesId + '/stakeholders');

        this.issueStakeholders = responseStakeholders.data;

        const stakeholderTabs = [];
        for (let i = 0; i < this.issueStakeholders.length; i++) {
          let classNameImportance = null;
          if (this.issueStakeholders[i].importance) {
            switch (this.issueStakeholders[i].importance.nr) {
              case 0: classNameImportance = '--unclear'; break;
              case 1: classNameImportance = '--criticism'; break;
              case 2: classNameImportance = '--warning'; break;
              case 3: classNameImportance = '--focus'; break;
              case 4: classNameImportance = '--positive'; break;
            }
          } else {
            classNameImportance = '--unclear';
          }

          let classNameConcern = null;
          if (this.issueStakeholders[i].concern) {
            switch (this.issueStakeholders[i].concern.nr) {
              case 1: classNameConcern = '--extremlyhighimportance'; break;
              case 2: classNameConcern = '--greatimportance'; break;
              case 3: classNameConcern = '--reasonablyimportance'; break;
              case 4: classNameConcern = '--hardlyimportance'; break;
              case 5: classNameConcern = '--unclear'; break;
            }
          } else {
            classNameConcern = '--unclear';
          }

          stakeholderTabs.push({
            id: this.issueStakeholders[i].stakeholderId,
            className: classNameImportance,
            title: this.issueStakeholders[i].projectStakeholderName,
            content: {
              tabTitle: this.issueStakeholders[i].concern ? this.issueStakeholders[i].concern.name : '',
              className: classNameConcern,
              importance: this.issueStakeholders[i].importance ? this.issueStakeholders[i].importance.name : '',
              tabContent: this.issueStakeholders[i].opinion,
              textButton: 'Open stakeholder-issue',
              urlButton: '/projects/' + project.id + '/issues/' + issuesId + '/stakeholders/' + this.issueStakeholders[i].stakeholderId,
              urlIssueButton: '/projects/' + project.id + '/issues/' + issuesId,
              urlStakeholderButton: '/projects/' + project.id + '/stakeholders/' + this.issueStakeholders[i].stakeholderId
            }
          });
        }

        this.stakeholderOverview.tabs = stakeholderTabs;
        this.loadingIssue = false;
        this.loadingIssueStakeholders = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingIssue = false;
        this.loadingIssueStakeholders = false;
      }
    },
    async fetchSingleIssueNew (project) {
      this.loadingEditIssue = true;
      this.loadingProjectStakeholders = true;
      try {
        this.issueToAdd = {
          projectId: project.id,
          name: '',
          description: '',
          narrative: '',
          strategy: '',
          emotion: {
            nr: null,
            text: ''
          },
          intelligibility: {
            nr: null,
            text: ''
          },
          visibility: {
            nr: null,
            text: ''
          },
          mediaSensitivity: {
            nr: null,
            text: ''
          },
          complexity: {
            nr: null,
            text: ''
          },
          developmentPhase: {
            nr: null,
            text: ''
          },
          discussionText: '',
          customFieldsValues: null,
          CategoryRating: {
            selectedCheckboxId: {},
            selectedCategory: ''
          },
          gis: '',
          projectStakeholders: null
        };

        this.issueToAdd.customFieldsValues = [];
        const customFields = project.customIssueFields;
        if (customFields !== null) {
          for (let idx = 0; idx < customFields.length; idx++) {
            this.issueToAdd.customFieldsValues.push({
              FieldName: customFields[idx],
              Type: 'text',
              Value: ''
            });
          }
        }

        for (const item in this.configReview.checkboxes) {
          this.configReview.checkboxes[item].checked = this.configReview.checkboxes[item].id === 0; // default status onduidelijk
        }

        const responseStakeholders = await HttpClient.get('api/v1/projects/' + project.id + '/stakeholders');
        this.projectStakeholders = responseStakeholders.data;
        this.selectedProjectStakeholders = [];
        this.orgSelectedProjectStakeholders = [];
        this.stakeholdersEdit = [];
        if (this.projectStakeholders !== null) {
          for (let i = 0; i < this.projectStakeholders.length; i++) {
            let classStatus = null;
            if (this.projectStakeholders[i].status) {
              switch (this.projectStakeholders[i].status.nr) {
                case 1: classStatus = '--criticism'; break;
                case 2: classStatus = '--warning'; break;
                case 3: classStatus = '--focus'; break;
                case 4: classStatus = '--positive'; break;
                case 5: classStatus = '--unclear'; break;
              }
            } else {
              classStatus = '--unclear';
            }

            this.stakeholdersEdit.push({
              id: this.projectStakeholders[i].id,
              className: classStatus,
              name: this.projectStakeholders[i].name
            });
          }
        }
        this.stakeholdersEditSelected = [];

        this.loadingEditIssue = false;
        this.loadingProjectStakeholders = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingEditIssue = false;
      }
    },
    async fetchSingleIssueToEdit (project, issuesId) {
      this.loadingEditIssue = true;
      this.loadingProjectStakeholders = true;
      try {
        const response = await HttpClient.get('api/v1/projects/' + project.id + '/issues/' + issuesId);
        const issue = response.data;

        this.issueToAdd = {
          projectId: project.id,
          name: issue.name,
          description: issue.description ?? '',
          narrative: issue.narrative ?? '',
          strategy: issue.strategy ?? '',
          emotion: {
            nr: issue.emotion !== null && issue.emotion.nr !== null ? this.features.find(x => x.name === 'emotion').options.find(y => y.nr === issue.emotion.nr) : null,
            text: issue.emotionText ?? ''
          },
          intelligibility: {
            nr: issue.intelligibility !== null && issue.intelligibility.nr !== null ? this.features.find(x => x.name === 'intelligibility').options.find(y => y.nr === issue.intelligibility.nr) : null,
            text: issue.intelligibilityText ?? ''
          },
          visibility: {
            nr: issue.visibility !== null && issue.visibility.nr !== null ? this.features.find(x => x.name === 'visibility').options.find(y => y.nr === issue.visibility.nr) : null,
            text: issue.visibilityText ?? ''
          },
          mediaSensitivity: {
            nr: issue.mediaSensitivity !== null && issue.mediaSensitivity.nr !== null ? this.features.find(x => x.name === 'mediaSensitivity').options.find(y => y.nr === issue.mediaSensitivity.nr) : null,
            text: issue.mediaSensitivityText ?? ''
          },
          complexity: {
            nr: issue.complexity !== null && issue.complexity.nr !== null ? this.features.find(x => x.name === 'complexity').options.find(y => y.nr === issue.complexity.nr) : null,
            text: issue.complexityText ?? ''
          },
          developmentPhase: {
            nr: issue.developmentPhase !== null && issue.developmentPhase.nr !== null ? this.features.find(x => x.name === 'developmentPhase').options.find(y => y.nr === issue.developmentPhase.nr) : null,
            text: issue.developmentPhaseText ?? ''
          },
          discussionText: issue.discussionText ?? '',
          customFieldsValues: issue.customFieldsValues,
          CategoryRating: {
            selectedCheckboxId: {},
            selectedCategory: issue.customCategory ?? ''
          },
          gis: issue.gis,
          projectStakeholders: null
        };

        // Dirty hack but for some reason this.issueToAdd.customFieldsValues can have a string value of '[]'
        if (this.issueToAdd.customFieldsValues === null || this.issueToAdd.customFieldsValues.length === 0 || this.issueToAdd.customFieldsValues === '[]') {
          this.issueToAdd.customFieldsValues = [];
          const customFields = project.customIssueFields;
          for (let idx = 0; idx < customFields.length; idx++) {
            this.issueToAdd.customFieldsValues.push({
              FieldName: customFields[idx],
              Type: 'text',
              Value: ''
            });
          }
        } else {
          const currentValues = JSON.parse(this.issueToAdd.customFieldsValues);
          this.issueToAdd.customFieldsValues = [];
          const customFields = project.customIssueFields;
          for (let idx = 0; idx < customFields.length; idx++) {
            const currentValue = currentValues.find((x) => {
              return x.FieldName === customFields[idx];
            });
            this.issueToAdd.customFieldsValues.push({
              FieldName: customFields[idx],
              Type: 'text',
              Value: currentValue === undefined ? '' : currentValue.Value
            });
          }
          // this.issueToAdd.customFieldsValues = JSON.parse(this.issueToAdd.customFieldsValues);
        }

        for (const item in this.configReview.checkboxes) {
          this.configReview.checkboxes[item].checked = this.configReview.checkboxes[item].id === this.issue.statusNr;
        }

        this.author = issue.addUser;
        this.moderator = issue.modUser;
        this.pageTitle = issue.name;

        const responseSelectedStakeholders = await HttpClient.get('api/v1/projects/' + project.id + '/issues/' + issuesId + '/stakeholders');
        const responseStakeholders = await HttpClient.get('api/v1/projects/' + project.id + '/stakeholders');

        this.selectedProjectStakeholders = responseSelectedStakeholders.data;
        this.orgSelectedProjectStakeholders = responseSelectedStakeholders.data;
        this.projectStakeholders = responseStakeholders.data;

        this.stakeholdersEdit = [];
        for (let i = 0; i < this.projectStakeholders.length; i++) {
          let classStatus = null;
          if (this.projectStakeholders[i].status) {
            switch (this.projectStakeholders[i].status.nr) {
              case 1: classStatus = '--criticism'; break;
              case 2: classStatus = '--warning'; break;
              case 3: classStatus = '--focus'; break;
              case 4: classStatus = '--positive'; break;
              case 5: classStatus = '--unclear'; break;
            }
          } else {
            classStatus = '--unclear';
          }

          this.stakeholdersEdit.push({
            id: this.projectStakeholders[i].id,
            className: classStatus,
            name: this.projectStakeholders[i].name
          });
        }

        this.stakeholdersEditSelected = [];
        for (let i = 0; i < this.projectStakeholders.length; i++) {
          if (this.selectedProjectStakeholders.some(x => x.stakeholderId === this.projectStakeholders[i].id)) {
            let classStatus = null;
            if (this.projectStakeholders[i].status) {
              switch (this.projectStakeholders[i].status.nr) {
                case 1:
                  classStatus = '--criticism';
                  break;
                case 2:
                  classStatus = '--warning';
                  break;
                case 3:
                  classStatus = '--focus';
                  break;
                case 4:
                  classStatus = '--positive';
                  break;
                case 5:
                  classStatus = '--unclear';
                  break;
              }
            } else {
              classStatus = '--unclear';
            }

            this.stakeholdersEditSelected.push({
              id: this.projectStakeholders[i].id,
              className: classStatus,
              name: this.projectStakeholders[i].name
            });
          }
        }

        this.loadingEditIssue = false;
        this.loadingProjectStakeholders = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingEditIssue = false;
        this.loadingProjectStakeholders = false;
      }
    },
    async fetchIssueStakeholders (projectId, issuesId) {
      this.loadingIssueStakeholders = true;
      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/issues/' + issuesId + '/stakeholders');
        this.issueStakeholders = response.data;
        this.loadingIssueStakeholders = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingIssueStakeholders = false;
      }
    },
    async fetchProjectStakeholders (projectId) {
      this.loadingProjectStakeholders = true;
      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/stakeholders');
        this.projectStakeholders = response.data;
        this.loadingProjectStakeholders = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectStakeholders = false;
      }
    },
    async updateIssueStatus (projectId, issuesId, status) {
      this.issue.statusNr = status;
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/issues/' + issuesId, this.issue)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateIssueStatusFromMatrix (project, issueId, status) {
      await this.fetchSingleIssue(project, issueId);
      await this.updateIssueStatus(project.id, issueId, status);
    },
    async updateIssueGeoJson (projectId, issuesId, geoJson) {
      const submitGeoJSson = {
        IssueId: issuesId,
        ProjectId: projectId,
        GeoJson: geoJson
      };

      this.issue.gis = geoJson;

      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/issues/' + issuesId + '/geo', submitGeoJSson)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async addNewIssue (projectId, issue) {
      let customFields = null;
      if (issue.customFieldsValues) {
        customFields = JSON.stringify(issue.customFieldsValues);
      }

      const submitIssue = {
        projectId: projectId,
        name: issue.name,
        description: issue.description,
        emotionNr: Object.prototype.hasOwnProperty.call(issue, 'emotion') && issue.emotion !== null && issue.emotion.nr !== null ? issue.emotion.nr.nr : null,
        emotionText: Object.prototype.hasOwnProperty.call(issue, 'emotion') && issue.emotion !== null && issue.emotion.text !== null ? issue.emotion.text : null,
        intelligibilityNr: Object.prototype.hasOwnProperty.call(issue, 'intelligibility') && issue.intelligibility !== null && issue.intelligibility.nr !== null ? issue.intelligibility.nr.nr : null,
        intelligibilityText: Object.prototype.hasOwnProperty.call(issue, 'intelligibility') && issue.intelligibility !== null && issue.intelligibility.text !== null ? issue.intelligibility.text : null,
        visibilityNr: Object.prototype.hasOwnProperty.call(issue, 'visibility') && issue.visibility !== null && issue.visibility.nr !== null ? issue.visibility.nr.nr : null,
        visibilityText: Object.prototype.hasOwnProperty.call(issue, 'visibility') && issue.visibility !== null && issue.visibility.text !== null ? issue.visibility.text : null,
        mediaSensitivityNr: Object.prototype.hasOwnProperty.call(issue, 'mediaSensitivity') && issue.mediaSensitivity !== null && issue.mediaSensitivity.nr !== null ? issue.mediaSensitivity.nr.nr : null,
        mediaSensitivityText: Object.prototype.hasOwnProperty.call(issue, 'mediaSensitivity') && issue.mediaSensitivity !== null && issue.mediaSensitivity.text !== null ? issue.mediaSensitivity.text : null,
        complexityNr: Object.prototype.hasOwnProperty.call(issue, 'complexity') && issue.complexity !== null && issue.complexity.nr !== null ? issue.complexity.nr.nr : null,
        complexityText: Object.prototype.hasOwnProperty.call(issue, 'complexity') && issue.complexity !== null && issue.complexity.text !== null ? issue.complexity.text : null,
        developmentPhaseNr: Object.prototype.hasOwnProperty.call(issue, 'developmentPhase') && issue.developmentPhase !== null && issue.developmentPhase.nr !== null ? issue.developmentPhase.nr.nr : null,
        developmentPhaseText: Object.prototype.hasOwnProperty.call(issue, 'developmentPhase') && issue.developmentPhase !== null && issue.developmentPhase.text !== null ? issue.developmentPhase.text : null,
        narrative: issue.narrative,
        strategy: issue.strategy,
        discussionText: issue.discussionText,
        customFieldsValues: customFields,
        statusNr: Object.prototype.hasOwnProperty.call(issue, 'CategoryRating') && issue.CategoryRating && issue.CategoryRating.selectedCheckboxId ? issue.CategoryRating.selectedCheckboxId.id : 0,
        CustomCategory: Object.prototype.hasOwnProperty.call(issue, 'CategoryRating') && issue.CategoryRating && issue.CategoryRating.selectedCategory ? issue.CategoryRating.selectedCategory : ''
      };

      let responseData = null;
      try {
        const response = await HttpClient.post('api/v1/projects/' + projectId + '/issues', submitIssue);
        responseData = response.data;
      } catch (err) {
        console.error(err);
      }

      const issueId = responseData.id;

      if (Object.prototype.hasOwnProperty.call(issue, 'projectStakeholders')) {
        if (issue.projectStakeholders) {
          for (let idx = 0; idx < issue.projectStakeholders.length; idx++) {
            const issueStakeholder = {
              issueId: issueId,
              stakeholderId: issue.projectStakeholders[idx].id,
              concernNr: 5, // default, status = onduidelijk,
              importanceNr: 0 // default, status = onduidelijk
            };
            try {
              await HttpClient.post('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + issue.projectStakeholders[idx].id, issueStakeholder);
            } catch (err) {
              console.error('Error Post IssueStakeholder:' + err);
            }
          }
        }
      }

      return responseData;
    },
    async adddNewIssueFast (projectId, issue) {
      const submitIssue = {
        projectId: projectId,
        name: issue.name,
        description: issue.description,
        statusNr: issue.status !== null ? issue.status : 0,
        customCategory: issue.customCategory
      };

      let responseData = null;
      try {
        const response = await HttpClient.post('api/v1/projects/' + projectId + '/issues', submitIssue);
        responseData = response.data;
      } catch (err) {
        console.error(err);
      }

      return responseData;
    },
    async updateIssue (projectId, issueId, issue) {
      let customFields = null;
      if (issue.customFieldsValues) {
        customFields = JSON.stringify(issue.customFieldsValues);
      }

      const submitIssue = {
        projectId: projectId,
        id: issueId,
        name: issue.name,
        description: issue.description,
        emotionNr: Object.prototype.hasOwnProperty.call(issue, 'emotion') && issue.emotion !== null && issue.emotion.nr !== null ? issue.emotion.nr.nr : null,
        emotionText: Object.prototype.hasOwnProperty.call(issue, 'emotion') && issue.emotion !== null && issue.emotion.text !== null ? issue.emotion.text : null,
        intelligibilityNr: Object.prototype.hasOwnProperty.call(issue, 'intelligibility') && issue.intelligibility !== null && issue.intelligibility.nr !== null ? issue.intelligibility.nr.nr : null,
        intelligibilityText: Object.prototype.hasOwnProperty.call(issue, 'intelligibility') && issue.intelligibility !== null && issue.intelligibility.text !== null ? issue.intelligibility.text : null,
        visibilityNr: Object.prototype.hasOwnProperty.call(issue, 'visibility') && issue.visibility !== null && issue.visibility.nr !== null ? issue.visibility.nr.nr : null,
        visibilityText: Object.prototype.hasOwnProperty.call(issue, 'visibility') && issue.visibility !== null && issue.visibility.text !== null ? issue.visibility.text : null,
        mediaSensitivityNr: Object.prototype.hasOwnProperty.call(issue, 'mediaSensitivity') && issue.mediaSensitivity !== null && issue.mediaSensitivity.nr !== null ? issue.mediaSensitivity.nr.nr : null,
        mediaSensitivityText: Object.prototype.hasOwnProperty.call(issue, 'mediaSensitivity') && issue.mediaSensitivity !== null && issue.mediaSensitivity.text !== null ? issue.mediaSensitivity.text : null,
        complexityNr: Object.prototype.hasOwnProperty.call(issue, 'complexity') && issue.complexity !== null && issue.complexity.nr !== null ? issue.complexity.nr.nr : null,
        complexityText: Object.prototype.hasOwnProperty.call(issue, 'complexity') && issue.complexity !== null && issue.complexity.text !== null ? issue.complexity.text : null,
        developmentPhaseNr: Object.prototype.hasOwnProperty.call(issue, 'developmentPhase') && issue.developmentPhase !== null && issue.developmentPhase.nr !== null ? issue.developmentPhase.nr.nr : null,
        developmentPhaseText: Object.prototype.hasOwnProperty.call(issue, 'developmentPhase') && issue.developmentPhase !== null && issue.developmentPhase.text !== null ? issue.developmentPhase.text : null,
        narrative: issue.narrative,
        strategy: issue.strategy,
        discussionText: issue.discussionText,
        customFieldsValues: customFields,
        gis: issue.gis,
        statusNr: Object.prototype.hasOwnProperty.call(issue, 'CategoryRating') && issue.CategoryRating && issue.CategoryRating.selectedCheckboxId ? issue.CategoryRating.selectedCheckboxId.id : 0,
        CustomCategory: Object.prototype.hasOwnProperty.call(issue, 'CategoryRating') && issue.CategoryRating && issue.CategoryRating.selectedCategory ? issue.CategoryRating.selectedCategory : ''
      };

      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/issues/' + issueId, submitIssue);

        if (Object.prototype.hasOwnProperty.call(issue, 'projectStakeholders')) {
          // add stakeholders?
          if (issue.projectStakeholders) {
            for (let idx = 0; idx < issue.projectStakeholders.length; idx++) {
              if (this.orgSelectedProjectStakeholders.length === 0 || this.orgSelectedProjectStakeholders.find(x => x.stakeholderId === issue.projectStakeholders[idx].id) === undefined) {
                const issueStakeholder = {
                  issueId: issueId,
                  stakeholderId: issue.projectStakeholders[idx].id,
                  concernNr: 5, // default, status = onduidelijk,
                  importanceNr: 0 // default, status = onduidelijk
                };
                try {
                  await HttpClient.post('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + issue.projectStakeholders[idx].id, issueStakeholder);
                } catch (err) {
                  console.error('Error Post IssueStakeholder:' + err);
                }
              }
            }
          }
          // delete stakeholders?
          if (issue.projectStakeholders && this.orgSelectedProjectStakeholders.length > 0) {
            for (let idx = 0; idx < this.orgSelectedProjectStakeholders.length; idx++) {
              if (issue.projectStakeholders.find(x => x.id === this.orgSelectedProjectStakeholders[idx].stakeholderId) === undefined) {
                try {
                  await HttpClient.delete('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + this.orgSelectedProjectStakeholders[idx].stakeholderId);
                } catch (err) {
                  console.error('Error delete IssueStakeholder:' + err);
                }
              }
            }
          }
        }
      } catch (err) {
        console.error(err);
        return false;
      }

      return true;
    },
    async deleteIssue (projectId, issueId) {
      this.loadingEditIssue = true;

      try {
        await HttpClient.delete('api/v1/projects/' + projectId + '/issues/' + issueId);
      } catch (err) {
        this.loadingEditIssue = false;
        console.error(err);
        return false;
      }

      this.loadingEditIssue = false;
    },
    showField (fieldName) {
      if (!this.availableIssueFields || this.availableIssueFields === null || this.availableIssueFields.length === 0) {
        return true;
      }
      const result = this.availableIssueFields.find(x => x.FieldName === fieldName);
      if (result) {
        return result.Available;
      } else {
        return false;
      }
    },
    stripHTML (value) {
      // let result = value.replaceAll(/(<([^>]+)>)/gi, ''); // replaceAll is ES12 and will not work in some older browsers
      let result = value.replace(/(<([^>]+)>)/gi, '');
      result = result.replace('&nbsp;', '');
      result = result.trim();
      return result;
    }
  }
});
