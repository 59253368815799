<script setup>
// Component imports
import UiFormLabel from '@/core/ui/UiFormLabel';
import PTextarea from 'primevue/textarea';

// Define props
const props = defineProps({
  labelTitle: String,
  labelFor: String,
  modelValue: String
});

// Define emits
const emit = defineEmits(['update:modelValue', 'emitDirty']);

// Variables

// Functions
function updateValue (value) {
  emit('update:modelValue', value);
  emit('emitDirty');
}
</script>

<template>
  <div class="ui-label-textarea">
    <ui-form-label
      class="mb-2"
      :label-title="props.labelTitle"
      :label-for="props.labelFor">
    </ui-form-label>
    <p-textarea
      class="ui-label-textarea__input d-block w-100"
      :id="props.labelFor"
      :name="props.labelFor"
      :modelValue="props.modelValue"
      @input="updateValue($event.target.value)"
    >
    </p-textarea>
  </div>
</template>

<style lang="scss">
.ui-label-textarea {
  &__input {
    min-height: calc-rem(154);
  }
}
</style>
