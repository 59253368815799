<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

// Component imports
// import PDropdown from 'primevue/dropdown';
import PDropdownEditable from '../../../core/ui/dropdown/DropdownCustom';
import UiLabelInput from '@/core/ui/UiLabelInput';
import UiReviewCardDlg from '@/core/ui/UiReviewCardDlg';
import UiFormLabel from '@/core/ui/UiFormLabel';
import UiLabelTextarea from '@/core/ui/UiLabelTextarea';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';

// Store imports

// Define props
const props = defineProps({
  saveButton: String,
  cancelButton: String,
  modelValue: Object,
  categories: Array
});

// Define emits
const emit = defineEmits([
  'save', 'saveAndNew', 'saveAndEdit', 'closeDialog'
]);

// Variables
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const issue = ref(props.modelValue);
const errorName = ref(false);
const availableCategories = ref(props.categories);
const saveDialog = (value) => {
  if (issue.value.name === '') {
    errorName.value = true;
    return;
  }
  errorName.value = false;
  emit('save', issue.value);
};
const saveAndNew = (value) => {
  if (issue.value.name === '') {
    errorName.value = true;
    return;
  }
  errorName.value = false;
  emit('saveAndNew', issue.value);
};
const saveAndEdit = (value) => {
  if (issue.value.name === '') {
    errorName.value = true;
    return;
  }
  errorName.value = false;
  emit('saveAndEdit', issue.value);
};
const changeValueCat = (input) => {
  issue.value.customCategory = input.value.name;
};
const changeStatus = async (value) => {
  issue.value.status = value.id;
};
const cancelDialog = () => {
  emit('closeDialog');
};
const configName = {
  labelName: t('Issue.Name'),
  labelFor: 'name'
};
const configDescription = {
  labelName: t('Issue.Description'),
  labelFor: 'description'
};
const configCategory = {
  labelName: t('Issue.Category'),
  labelFor: 'categorie'
};
const configDialog = {
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel'),
  buttonsDropdownItems: [
    {
      label: t('General.Save'),
      command: () => {
        saveDialog();
      }
    },
    {
      label: t('General.SaveAndAddNew'),
      command: () => {
        saveAndNew();
      }
    },
    {
      label: t('General.SaveAndAddEdit'),
      command: () => {
        saveAndEdit();
      }
    }
  ]
};
const configReview = {
  cardTitle: t('General.Tension'),
  forCheckboxes: 'beoordelingspanning',
  checkboxes: [
    {
      id: 0,
      labelTitle: t('Status.Onduidelijk'),
      value: 'onduidelijk',
      nameCheckboxes: 'checkboxes',
      className: '--unclear',
      checked: false
    },
    {
      id: 4,
      labelTitle: t('Status.Positief'),
      value: 'positief',
      nameCheckboxes: 'checkboxes',
      className: '--positive',
      checked: false
    },
    {
      id: 3,
      labelTitle: t('Status.Aandacht'),
      value: 'aandacht',
      nameCheckboxes: 'checkboxes',
      className: '--focus',
      checked: false
    },
    {
      id: 2,
      labelTitle: t('Status.Waarschuwing'),
      value: 'waarschuwing',
      nameCheckboxes: 'checkboxes',
      className: '--warning',
      checked: false
    },
    {
      id: 1,
      labelTitle: t('Status.Kritiek'),
      value: 'kritiek',
      nameCheckboxes: 'checkboxes',
      className: '--criticism',
      checked: false
    }
  ]
};

watch(() => props.modelValue, (val) => {
  issue.value = val;
  const idx = configReview.checkboxes.findIndex(x => x.checked === true);
  if (idx > 0) {
    configReview.checkboxes[idx].checked = false;
  }
});
</script>

<template>
  <form class="issue-detail-dialog-form">
    <fieldset class="issue-detail-dialog-form__fieldset m-0 pt-3 pb-2 pe-4 ps-4">
      <ui-label-input
        class="mb-5"
        :label-title="configName.labelName"
        :label-for="configName.labelFor"
        :showError="errorName"
        v-model="issue.name"
        :error-message="$t('General.MandatoryField')"
      >
      </ui-label-input>
      <div>
        <ui-form-label
          class="mb-2"
          :label-title="configCategory.labelName"
          :label-for="configCategory.labelFor">
        </ui-form-label>
        <p-dropdown-editable
          class="ui-label-dropdown-checkboxes__dropdown w-100 mb-4"
          :editable="true"
          option-label="name"
          v-model="issue.customCategory"
          :options="availableCategories"
          scrollHeight="400px"
          @change="changeValueCat($event)">
        </p-dropdown-editable>
      </div>
      <ui-review-card-dlg
        class="mb-5"
        :card-title="configReview.cardTitle"
        :checkbox-options="configReview.checkboxes"
        :model-value="issue.status"
        @submit-value="''"
        @update-value="changeStatus">
      </ui-review-card-dlg>
      <ui-label-textarea
        class="issue-edit__textarea mb-6"
        :label-title="configDescription.labelName"
        :label-for="configDescription.labelFor"
        v-model="issue.description">
      </ui-label-textarea>
      <ui-save-buttons-dropdown
        class="pt-2 pb-1 me-2 float-end" style="z-index:99999"
        :items="configDialog.buttonsDropdownItems"
        :save-button="configDialog.saveButton"
        :cancel-button="configDialog.cancelButton"
        @emit-save="saveDialog(this)"
        @emit-cancel="cancelDialog()">
      </ui-save-buttons-dropdown>
    </fieldset>
  </form>
</template>

<style lang="scss">
.issue-detail-dialog-form {

  &__fieldset {
    border: none;
    border-bottom: calc-rem(1) solid $separatorLineColor;
  }

  .ui-save-buttons {
    justify-content: end;
  }

  .__review-card {
    .p-card-content {
      padding: 0;
    }

    .ui-card-title {
      font-size: calc-rem(14);
    }
    .ui-review-card__reset {
      width: calc-rem(24);
      height: calc-rem(24);
      padding: calc-rem(5);
      margin-right: calc-rem(7);
      background-color: #F6F7F8;
      border-color: #DFE3E7;
      color: #98A4B3;
    }
  }

  .ui-label-textarea__input {
    width: calc-rem(380) !important;
  }
}
</style>
