<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

// Component imports
import PButton from 'primevue/button';
import SideBarButton from './components/SideBarButton';
import UiLogoLink from '@/core/ui/UiLogoLink';
import UiRouterLink from '@/core/ui/UiRouterLink';

// Store inports
import { useProjectsStore } from '@/store/modules/projects';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Variables
const { project } = storeToRefs(useProjectsStore());
const route = useRoute();

const sidebarStore = useSideBarStore();
const { currentUser } = storeToRefs(useUserStore());
const { IsActionAllowedInRole } = useRolesStore();

const {
  sidebarMenuBottom,
  toggleSideBarClass
} = storeToRefs(sidebarStore);
const { actionToggleSideBar } = sidebarStore;
const showReportsDropdown = ref(false);
const showProjectDropdown = ref(false);
const toggleSidebar = () => {
  actionToggleSideBar();
};

if (
  route.path.endsWith('concernmatrix') ||
  route.path.endsWith('importancematrix') ||
  route.path.endsWith('environmentmonitor') ||
  route.path.endsWith('progressmatrix') ||
  route.path.endsWith('stakeholderissueanalysis') ||
  route.path.endsWith('contacts')) {
  showReportsDropdown.value = true;
}

const sidebarMenuTop = ref([
  {
    id: 0,
    title: 'General.Frontend.Dashboard',
    iconRight: null,
    iconLeft: 'pi-th-large',
    url: '/projects/' + route.params.projectId,
    subMenu: false,
    subMenuItems: null
  },
  {
    id: 0,
    title: 'General.Frontend.Chart',
    iconRight: null,
    iconLeft: 'pi-map',
    url: '/projects/' + route.params.projectId + '?showmap=true',
    subMenu: false,
    subMenuItems: null
  },
  {
    id: 1,
    title: 'General.Issues',
    iconRight: null,
    iconLeft: 'pi-check-circle',
    url: '/projects/' + route.params.projectId + '/issues',
    subMenu: false,
    subMenuItems: null
  },
  {
    id: 2,
    title: 'General.Stakeholders',
    iconRight: null,
    iconLeft: 'pi-user',
    url: '/projects/' + route.params.projectId + '/stakeholders',
    subMenu: false,
    subMenuItems: null
  }
]);
const sidebarMenuMiddle = ref([
  {
    id: 0,
    title: 'General.ProjectNotes',
    iconRight: null,
    iconLeft: 'pi-comments',
    url: '/projects/' + route.params.projectId + '/notes',
    subMenu: false,
    subMenuItems: null
  },
  {
    id: 1,
    title: 'General.ProjectMedias',
    iconRight: null,
    iconLeft: 'pi-file',
    url: '/projects/' + route.params.projectId + '/media',
    subMenu: false,
    subMenuItems: null
  }
]);

// Functions
const menuClick = () => {
  console.log('menu click');
};

</script>

<template>
  <aside
    class="side-bar pt-2 pe-2 ps-2"
    :class="{ shrink: !toggleSideBarClass, 'w-100': toggleSideBarClass }"
    :key="project.id">
    <header class="d-flex justify-content-between">
      <ui-logo-link class="side-bar__logo"></ui-logo-link>
      <p-button
        class="side-bar__toggle"
        :label="$t('General.Frontend.ToggleMenu')"
        :icon="toggleSideBarClass ? 'pi pi-chevron-left' : 'pi pi-chevron-right'"
        iconPos="center"
        @click="toggleSidebar()">
      </p-button>
    </header>
    <main class="pt-6">
      <nav class="side-bar__nav p-0 d-flex flex-column">
        <ui-router-link
          class="side-bar__nav-link"
          v-for="item in sidebarMenuTop.filter((x) => { if (project.gisDefaultCoordinatesLat && project.gisDefaultCoordinatesLng) { return true } else { return x.iconLeft !== 'pi-map' } })"
          :key="item.id"
          :title="$t(item.title)"
          :icon-right="item.iconRight"
          :icon-left="item.iconLeft"
          :route-link="item.url"
          @click="menuClick"
        >
        </ui-router-link>
        <div class="side-bar__dropdown position-relative">
          <side-bar-button
            class="side-bar__nav-button"
            :right-icon="'pi pi-chevron-down'"
            :label-text="$t('Product.Reports')"
            :left-icon="'pi pi-chart-bar'"
            :rotate-icon="showReportsDropdown"
            @click="showReportsDropdown = !showReportsDropdown"
          >
          </side-bar-button>
          <div class="w-100 overflow-hidden">
            <transition name="top-slide-fade">
              <div class="side-bar__dropdown-menu" v-show="showReportsDropdown">
                <router-link
                  class="
                    side-bar__nav-link
                    d-flex
                    flex-nowrap
                    align-items-center
                    text-decoration-none
                  "
                  :to="'/projects/' + route.params.projectId + '/concernmatrix'"
                >
                  <span class="pi pi-microsoft"></span>
                  <span class="d-inline-block text-nowrap">{{ $t('Product.ConcernMatrix') }}</span>
                </router-link>
                <router-link
                  class="
                    side-bar__nav-link
                    d-flex
                    flex-nowrap
                    align-items-center
                    text-decoration-none
                  "
                  :to="
                    '/projects/' + route.params.projectId + '/importancematrix'
                  "
                >
                  <span class="pi pi-sort-amount-up"></span>
                  <span class="d-inline-block text-nowrap">{{ $t('Product.ImportanceMatrix') }}</span
                  >
                </router-link>
                <router-link
                  class="
                    side-bar__nav-link
                    d-flex
                    flex-nowrap
                    align-items-center
                    text-decoration-none
                  "
                  :to="
                    '/projects/' +
                    route.params.projectId +
                    '/environmentmonitor'
                  "
                >
                  <span class="pi pi-globe"></span>
                  <span class="d-inline-block text-nowrap">{{ $t('Product.ViewpointTable') }}</span
                  >
                </router-link>
                <router-link
                  class="
                    side-bar__nav-link
                    d-flex
                    flex-nowrap
                    align-items-center
                    text-decoration-none
                  "
                  :to="
                    '/projects/' + route.params.projectId + '/progressmatrix'
                  "
                >
                  <span class="pi pi-sliders-v"></span>
                  <span class="d-inline-block text-nowrap">{{ $t('Product.ProgressMatrix') }}</span
                  >
                </router-link>
                <router-link
                  class="
                    side-bar__nav-link
                    d-flex
                    flex-nowrap
                    align-items-center
                    text-decoration-none
                  "
                  :to="'/projects/' + route.params.projectId + '/stakeholderissueanalysis'"
                >
                  <span class="pi pi-sitemap"></span>
                  <span class="d-inline-block text-nowrap">{{ $t('Project.StakeholderIssueAnalysis') }}</span
                  >
                </router-link>
                <router-link
                  class="
                    side-bar__nav-link
                    d-flex
                    flex-nowrap
                    align-items-center
                    text-decoration-none
                  "
                  :to="'/projects/' + route.params.projectId + '/contacts'"
                >
                  <span class="pi pi-id-card"></span>
                  <span class="d-inline-block text-nowrap">{{ $t('Product.ContactsExport') }}</span>
                </router-link>
<!--                <router-link-->
<!--                  class="-->
<!--                    side-bar__nav-link-->
<!--                    d-flex-->
<!--                    flex-nowrap-->
<!--                    align-items-center-->
<!--                    text-decoration-none-->
<!--                  "-->
<!--                  :to="'/projects/' + route.params.projectId + '/allissues'"-->
<!--                >-->
<!--                  <span class="pi pi-download"></span>-->
<!--                  <span class="d-inline-block text-nowrap"-->
<!--                    >{{ $t('Product.AllIssues') }}</span-->
<!--                  >-->
<!--                </router-link>-->
              </div>
            </transition>
          </div>
        </div>
        <ui-router-link
          class="side-bar__nav-link"
          v-for="item in sidebarMenuMiddle"
          :key="item.id"
          :title="$t(item.title)"
          :icon-right="item.iconRight"
          :icon-left="item.iconLeft"
          :route-link="item.url"
        >
        </ui-router-link>
        <div class="side-bar__dropdown position-relative"
             v-if="IsActionAllowedInRole('project.menu', currentUser, route.params.projectId)"
        >
          <side-bar-button
            class="side-bar__nav-button position-relative"
            :right-icon="'pi pi-chevron-down'"
            :label-text="$t('General.Frontend.ProjectManagement')"
            :left-icon="'pi pi-cog'"
            :rotate-icon="showProjectDropdown"
            @click="showProjectDropdown = !showProjectDropdown"
          >
          </side-bar-button>
          <div class="w-100 overflow-hidden">
            <transition name="top-slide-fade">
              <div class="side-bar__dropdown-menu" v-show="showProjectDropdown">
                <router-link
                  v-if="IsActionAllowedInRole('project.members', currentUser, route.params.projectId)"
                  class="
                    side-bar__nav-link
                    d-flex
                    flex-nowrap
                    align-items-center
                    text-decoration-none
                  "
                  :to="'/projects/' + route.params.projectId + '/members'"
                >
                  <span class="pi pi-user"></span>
                  <span class="d-inline-block text-nowrap">{{ $t('General.Frontend.ProjectMembers') }}</span>
                </router-link>
                <router-link
                  v-if="IsActionAllowedInRole('project.edit', currentUser, route.params.projectId)"
                  class="
                    side-bar__nav-link
                    d-flex
                    flex-nowrap
                    align-items-center
                    text-decoration-none
                  "
                  :to="'/projects/' + route.params.projectId + '/edit'"
                >
                  <span class="pi pi-pencil"></span>
                  <span class="d-inline-block text-nowrap">{{ $t('Project.Edit') }}</span
                  >
                </router-link>
                <router-link
                  v-if="IsActionAllowedInRole('project.archive', currentUser, route.params.projectId)"
                  class="
                    side-bar__nav-link
                    d-flex
                    flex-nowrap
                    align-items-center
                    text-decoration-none
                  "
                  :to="'/projects/' + route.params.projectId + '/archive'">
                  <span class="pi pi-inbox"></span>
                  <span class="d-inline-block text-nowrap">{{ $t('General.Frontend.ProjectArchive') }}</span>
                </router-link>
              </div>
            </transition>
          </div>
        </div>
        <ui-router-link
          v-if="!currentUser.isAdministrator && IsActionAllowedInRole('project.membersreadonly', currentUser, route.params.projectId)"
          class="side-bar__nav-link"
          :title="$t('General.Frontend.ProjectMembers')"
          icon-left="pi-user"
          :route-link="'/projects/' + route.params.projectId + '/members'">
        </ui-router-link>
        <ui-router-link
          class="side-bar__nav-link"
          v-for="item in sidebarMenuBottom"
          :key="item.id"
          :title="$t(item.title)"
          :icon-right="item.iconRight"
          :icon-left="item.iconLeft"
          :route-link="'/help/' + route.params.projectId">
        </ui-router-link>
        <ui-router-link
          v-if="IsActionAllowedInRole('project.projectusersoverview', currentUser, route.params.projectId)"
          class="side-bar__nav-link"
          :title="$t('Navigation.ProjectUsersOverview')"
          icon-left="pi-id-card"
          :route-link="'/projects/' + route.params.projectId + '/projectusersoverview'">
        </ui-router-link>
      </nav>
    </main>
  </aside>
</template>

<style lang="scss">
.side-bar {
  &__logo {
    width: calc-rem(67.36);
    height: calc-rem(38);
  }

  &__toggle {
    &.p-button {
      padding-right: calc-rem(11);
      padding-left: calc-rem(11);
      background-color: transparent;
      border-color: transparent;
      color: $textColor;
    }

    .p-button-label {
      @extend %sr-only;
    }
  }

  &__nav {
    &-button,
    &-link {
      margin-bottom: calc-rem(2);

      span:nth-child(n + 2) {
        transition: opacity 50ms ease-in-out 200ms;
      }
    }

    &-link {
      padding-top: calc-rem(13);
      padding-right: calc-rem(12);
      padding-bottom: calc-rem(13);
      padding-left: calc-rem(12);
      border-radius: $borderRadius;
      font-size: calc-rem(14);
      font-weight: 400;
      line-height: calc-rem(14);
      color: $textColor;
      transition: all $transitionDuration ease-out;

      &:hover {
        background-color: $secondaryButtonHoverBg;
      }

      &:focus {
        @extend %button-focus;
      }

      .pi {
        margin-right: calc-rem(7);
        font-size: inherit;
      }
    }

    &-button {
      flex-wrap: nowrap;
    }

    .p-panelmenu-link {
      font-size: calc-rem(14);
    }
  }

  &__dropdown {
    padding-top: calc-rem(14);
    padding-bottom: calc-rem(14);

    &::after,
    &::before {
      position: absolute;
      left: 0;
      width: 100%;
      height: calc-rem(1);
      content: '';
      background-color: $separatorLineColor;
    }

    &::after {
      bottom: calc-rem(8);
    }

    &::before {
      top: calc-rem(6);
    }

    &-menu {
      transform-origin: top;
    }
  }

  &.shrink {
    .side-bar__logo,
    .side-bar__nav-link span:nth-child(n + 2),
    .side-bar__nav-button span:nth-child(n + 2) {
      width: 0;
      opacity: 0;
      transition: all 200ms ease-out;
    }

    .side-bar__nav-link span:nth-child(1),
    .side-bar__nav-button span:nth-child(1) {
      margin-right: 0;
    }
  }
}
</style>
