import { defineStore } from 'pinia';
// import HttpClient from '@/services/httpclient';
import i18n from '../../../translations';

const t = (input) => {
  return i18n.global.t(input);
};

const useSideBarStore = defineStore({
  id: 'sidebar',
  state: () => ({
    showSideBar: false,
    toggleSideBarClass: true,
    sidebarMenuTop: [
      {
        id: 0,
        title: t('General.Frontend.Dashboard'),
        iconRight: null,
        iconLeft: 'pi-th-large',
        url: '/',
        subMenu: false,
        subMenuItems: null
      },
      {
        id: 1,
        title: t('General.Issues'),
        iconRight: null,
        iconLeft: 'pi-check-circle',
        url: '/',
        subMenu: false,
        subMenuItems: null
      },
      {
        id: 2,
        title: t('General.Stakeholders'),
        iconRight: null,
        iconLeft: 'pi-users',
        url: '/',
        subMenu: false,
        subMenuItems: null
      }
    ],
    sidebarMenuReports: [
      {
        id: 0,
        title: t('Product.Reports'),
        iconRight: null,
        iconLeft: 'pi-chart-bar',
        url: null,
        subMenu: true,
        subMenuItems: [
          {
            id: 0,
            title: t('Product.ConcernMatrix'),
            iconRight: null,
            iconLeft: 'pi-microsoft',
            url: '/'
          },
          {
            id: 1,
            title: t('Product.ImportanceMatrix'),
            iconRight: null,
            iconLeft: 'pi-sort-numeric-up-alt',
            url: '/'
          },
          {
            id: 2,
            title: t('Product.ViewpointTable'),
            iconRight: null,
            iconLeft: 'pi-user',
            url: '/'
          },
          {
            id: 3,
            title: t('Product.StakeholderIssueAnalysis'),
            iconRight: null,
            iconLeft: 'pi-chart-line',
            url: '/'
          },
          {
            id: 4,
            title: t('Product.ProgressMatrix'),
            iconRight: null,
            iconLeft: 'pi-user',
            url: '/'
          },
          {
            id: 5,
            title: t('Product.ContactsExport'),
            iconRight: null,
            iconLeft: 'pi-id-card',
            url: '/'
          }
          // {
          //   id: 6,
          //   title: 'Alle issuedossiers',
          //   iconRight: null,
          //   iconLeft: 'pi-download',
          //   url: '/'
          // }
        ]
      }
    ],
    sidebarMenuMiddle: [
      {
        id: 0,
        title: t('General.ProjectNotes'),
        iconRight: null,
        iconLeft: 'pi-comments',
        url: '/',
        subMenu: false,
        subMenuItems: null
      },
      {
        id: 1,
        title: t('General.ProjectMedias'),
        iconRight: null,
        iconLeft: 'pi-file',
        url: '/',
        subMenu: false,
        subMenuItems: null
      }
    ],
    sidebarMenuProjects: [
      {
        id: 0,
        title: t('General.Frontend.ProjectManagement'),
        iconRight: null,
        iconLeft: 'pi-cog',
        url: null,
        subMenu: true,
        subMenuItems: [
          {
            id: 0,
            title: t('General.Frontend.ProjectMembers'),
            iconRight: null,
            iconLeft: 'pi-user',
            url: '/'
          },
          {
            id: 1,
            title: t('Project.Edit'),
            iconRight: null,
            iconLeft: 'pi-pencil',
            url: '/'
          },
          {
            id: 2,
            title: t('General.Frontend.ProjectArchive'),
            iconRight: null,
            iconLeft: 'pi-inbox',
            url: '/'
          }
        ]
      }
    ],
    sidebarMenuBottom: [
      {
        id: 0,
        title: 'Help.Title',
        iconRight: null,
        iconLeft: 'pi-question-circle',
        url: '/help',
        subMenu: false,
        subMenuItems: null
      }
    ]
  }),
  getters: {
  },
  actions: {
    actionToggleSideBar () {
      this.toggleSideBarClass = !this.toggleSideBarClass;
    }
  }
});

export { useSideBarStore };
