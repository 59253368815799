import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

const routerHistory = createWebHistory();
const router = createRouter({
  history: routerHistory,
  routes: routes
});

router.beforeEach((to, from, next) => {
  // console.info('to', to.name);
  // console.info('from', from.name);
  // https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards

  if (from.name === 'project-issue-edit') {
    console.info('CONFIRM IF CHANGED ISSUE');
    // return;
  }
  if (from.name === 'project-stakeholder-edit') {
    console.info('CONFIRM IF CHANGED STAKEHOLDER');
    // return;
  }
  next();
});

export default router;
