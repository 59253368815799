import { defineStore } from 'pinia';
import apiTestUrlsJson from '@/config/apiUrls.json';
import apiProductionUrlsJson from '@/config/apiUrls.production.json';
import HttpClient from '@/services/httpclient';
import i18n from '@/translations';

const useGlobalStore = defineStore({
  id: 'global',
  state: () => ({
    showBackToTopButton: true,
    currentUserEnvironment: '',
    translationsNl: {},
    translationsEn: {},
    isDirty: false
  }),
  getters: {
    getCurrentUserEnvironment (state) {
      // Api Urls
      const apiUrlsJson = (process.env.VUE_APP_SOMSET_ENV !== 'production' ? apiTestUrlsJson : apiProductionUrlsJson);
      // console.info('store 1 process.env.VUE_APP_SOMSET_ENV', process.env.VUE_APP_SOMSET_ENV, apiUrlsJson);
      let currentEnv = state.currentUserEnvironment;
      if (window.$cookies.get('ApiEnvironment') !== null && window.$cookies.get('ApiEnvironment') !== '') {
        currentEnv = window.$cookies.get('ApiEnvironment');
      }
      return apiUrlsJson.find(function (x) {
        return x.code === currentEnv;
      });
    },
    getTranslationsNl (state) {
      return state.translationsNl;
    },
    getTranslationsEn (state) {
      return state.translationsEn;
    }
  },
  actions: {
    setCurrentUserEnvironment (code, validUserEnvironments) {
      // Api Urls
      const apiUrlsJson = (process.env.VUE_APP_SOMSET_ENV !== 'production' ? apiTestUrlsJson : apiProductionUrlsJson);
      // console.info('store 2 process.env.VUE_APP_SOMSET_ENV', process.env.VUE_APP_SOMSET_ENV, apiUrlsJson);

      let theChosenOne = validUserEnvironments.find(function (x) {
        return x.code === code;
      });
      if (theChosenOne === undefined) {
        // Fallback
        theChosenOne = apiUrlsJson.find(function (x) {
          return x.code === (process.env.VUE_APP_SOMSET_ENV !== 'production' ? 'test' : 'demo');
        });
      }
      this.currentUserEnvironment = theChosenOne.code;
      window.$cookies.set('ApiEnvironment', theChosenOne.code);
      window.$cookies.set('ApiBaseUrl', theChosenOne.url);
    },
    setCurrentUserLanguage (language) {
      i18n.global.locale.value = language;
    },
    async fetchTranslations () {
      try {
        // fetch dutch translations
        this.translationsNl = {};
        const responseNl = await HttpClient.get('api/v1/translations/nl');
        if (responseNl.data !== null) {
          this.translationsNl = responseNl.data;
          i18n.global.setLocaleMessage('nl', responseNl.data);
        }

        // fetch dutch translations
        this.translationsEn = {};
        const responseEn = await HttpClient.get('api/v1/translations/en');
        if (responseEn.data !== null) {
          i18n.global.setLocaleMessage('en', responseEn.data);
          this.translationsEn = responseEn.data;
        }
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    }
  }
});

export { useGlobalStore };
