<script setup>
// import { ref } from 'vue';

// Component imports
import PButton from 'primevue/button';
import PSplitButton from 'primevue/splitbutton';

// Define props
const props = defineProps({
  saveButton: String,
  cancelButton: String,
  items: Array
});

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel'
]);

// Variables
const submitSave = () => {
  // spinner.value = true;
  emit('emitSave');
};
const submitCancel = () => {
  emit('emitCancel');
};
// const spinner = ref(false);
</script>

<template>
  <div class="ui-save-buttons-dropdown d-flex">
    <p-button
      class="p-button-link me-2"
      :label="props.cancelButton"
      @click="submitCancel()">
    </p-button>
    <p-split-button
      :model="props.items">
      <p-button
        class="ui-save-buttons-dropdown__save"
        :label="props.saveButton"
        @click="submitSave()">
      </p-button>
<!--      <p-button v-if="spinner"-->
<!--        class="ui-save-buttons-dropdown__save"-->
<!--        icon="pi pi-spin pi-spinner"-->
<!--        :label="props.saveButton">-->
<!--      </p-button>-->
    </p-split-button>
  </div>
</template>

<style lang="scss">
.ui-save-buttons-dropdown {
  &__save {
    margin-right: calc-rem(1) !important;
    border-radius: $borderRadius 0 0 $borderRadius !important;
  }
}

.p {
  &-tieredmenu {
    margin-top: calc-rem(3);
    padding: calc-rem(5);
    width: calc-rem(240);

    &.p-tieredmenu-overlay {
      box-shadow: 0 4px 20px rgba(0, 0, 0, .2);
    }

    ul {
      width: calc-rem(230);
    }
  }

  &-menuitem {
    &:not(:last-child) {
      margin-bottom: calc-rem(2);
    }

    &-link {
      padding-right: calc-rem(15) !important;
      padding-left: calc-rem(15) !important;
      border-radius: $borderRadius  !important;
      font-size: calc-rem(14);
      line-height: calc-rem(16);
    }
  }
}
</style>
