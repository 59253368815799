import { defineStore } from 'pinia';
import HttpClient from '@/services/httpclient';
import i18n from '../../../translations';

const t = (input) => {
  return i18n.global.t(input);
};

export const useProjectsStore = defineStore({
  id: 'projects',
  state: () => ({
    allProjects: [],
    allProjectsPictures: [],
    loadingAllProjects: true,
    loadingProject: true,
    loadingProjectGeo: true,
    loadingProjectIssues: true,
    loadingProjectStakeholders: true,
    loadingProjectStakeholdersToCompare: false,
    loadingProjectConcernMatrix: true,
    loadingProjectExportViewPointTable: true,
    loadingProjectPicture: true,
    loadingProjectStakeholderIssueAnalysis: true,
    loadingProjectUpdates: true,
    loadingSupportRequest: true,
    loadingProjectMembers: false,
    loadingProjectMemberAdd: false,
    loadingIndex: false,
    supportRequestOk: false,
    error: null,
    project: {},
    projectGeo: {},
    projectIssuesGeo: null,
    projectStakeholdersGeo: null,
    projectPicture: {},
    orgProjectPicture: {},
    projectProjectStakeholderIssueAnalysis: {},
    projectProjectStakeholderIssueAnalysisFile: {},
    projectIssues: [],
    projectIssuesSelectedDate: null,
    projectIssuesGroupedByStatus: [],
    projectStakeholders: [],
    projectStakeholdersToCompare: [],
    projectStakeholdersSelectedDate: null,
    projectStakeholdersToCompareDate: null,
    projectStakeholdersGroupedByStatus: [],
    projectConcernMatrix: {},
    projectExportViewPointTable: {},
    projectMembers: [],
    projectToAdd: {},
    projectToEdit: {},
    supportRequestEdit: {},
    addOptionsMenuBar: [
      // {
      //   id: 0,
      //   title: 'Issue toevoegen',
      //   iconRight: null,
      //   iconLeft: 'pi-check-circle',
      //   url: ':issueId/create'
      // },
      // {
      //   id: 1,
      //   title: 'Stakeholder toevoegen',
      //   iconRight: null,
      //   iconLeft: 'pi-users',
      //   url: '/'
      // },
      // {
      //   id: 0,
      //   title: 'Notitie toevoegen',
      //   iconRight: null,
      //   iconLeft: 'pi-comments',
      //   url: '/'
      // },
      // {
      //   id: 1,
      //   title: 'Media toevoegen',
      //   iconRight: null,
      //   iconLeft: 'pi-file-o',
      //   url: '/'
      // }
    ]
  }),
  getters: {
    getProjectsWithPictures (state) {
      return state.allProjects.map((item, i) => Object.assign({}, item, state.allProjectsPictures[i]));
    },
    getProjectIssuesGroupedByStatus (state) {
      try {
        const projectIssuesResult = state.projectIssues;
        const projectIssuesGroupedByStatus = {
          cardTitle: projectIssuesResult.length + ' Issues',
          cardSubtitle: t('Project.Frontend.Tension'),
          itemCount: projectIssuesResult.length,
          panels: []
        };

        for (let i = 0; i < projectIssuesResult.length; i++) {
          const issue = projectIssuesResult[i];
          if (!issue.status || issue.status.nr === 0) {
            // Default to --unclear
            issue.status = {
              nr: 5,
              name: t('Status.Onduidelijk')
            };
            // continue;
          }
          let statusIndex = projectIssuesGroupedByStatus.panels.findIndex(function (x) {
            return x.id === issue.status.nr;
          });

          let className = null;
          let name = '';
          switch (issue.status.nr) {
            case 1:
              className = '--criticism';
              name = t('Status.Kritiek');
              break;
            case 2:
              className = '--warning';
              name = t('Status.Waarschuwing');
              break;
            case 3:
              className = '--focus';
              name = t('Status.Aandacht');
              break;
            case 4:
              className = '--positive';
              name = t('Status.Positief');
              break;
            case 5:
              className = '--unclear';
              name = t('Status.Onduidelijk');
              break;
          }
          if (statusIndex === -1) {
            // Add new status
            projectIssuesGroupedByStatus.panels.push({
              id: issue.status.nr,
              panelTitle: name,
              className: className,
              isCollapsed: true,
              panel: []
            });

            statusIndex = projectIssuesGroupedByStatus.panels.findIndex(function (x) {
              return x.id === issue.status.nr;
            });
          }
          projectIssuesGroupedByStatus.panels[statusIndex].panel.push({
            id: issue.id,
            title: issue.name,
            urlTo: '/projects/' + this.project.id + '/issues/' + issue.id,
            className: className
          });
        }
        projectIssuesGroupedByStatus.panels.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
        return projectIssuesGroupedByStatus;
      } catch (err) {
        console.error(err);
        return {
          cardTitle: '0 ' + t('General.Issues'),
          cardSubtitle: t('Project.Frontend.Tension'),
          itemCount: 0,
          panels: []
        };
      }
    },
    getProjectIssuesGroupedByCategory (state) {
      try {
        const projectIssuesResult = state.projectIssues;
        const projectIssuesGroupedByCategory = {
          cardTitle: projectIssuesResult.length + ' ' + t('General.Issues'),
          cardSubtitle: t('General.Categories'),
          itemCount: projectIssuesResult.length,
          panels: []
        };

        for (let i = 0; i < projectIssuesResult.length; i++) {
          const issue = projectIssuesResult[i];
          if (!issue.customCategory) {
            issue.customCategory = ' ' + t('General.NoCategory');
            // continue;
          }
          let statusIndex = projectIssuesGroupedByCategory.panels.findIndex(function (x) {
            return x.id === issue.customCategory;
          });

          if (statusIndex === -1) {
            // Add new status
            projectIssuesGroupedByCategory.panels.push({
              id: issue.customCategory,
              panelTitle: issue.customCategory,
              className: null,
              isCollapsed: true,
              editable: false,
              newValue: issue.customCategory,
              panel: []
            });

            statusIndex = projectIssuesGroupedByCategory.panels.findIndex(function (x) {
              return x.id === issue.customCategory;
            });
          }
          projectIssuesGroupedByCategory.panels[statusIndex].panel.push({
            id: issue.id,
            title: issue.name,
            urlTo: '/projects/' + this.project.id + '/issues/' + issue.id,
            className: null,
            statusNr: issue.status.nr,
            statusName: issue.status.name
          });
        }
        projectIssuesGroupedByCategory.panels.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
        return projectIssuesGroupedByCategory;
      } catch (err) {
        console.error(err);
        return {
          cardTitle: '0 ' + t('General.Issues'),
          cardSubtitle: t('General.Categories'),
          itemCount: 0,
          panels: []
        };
      }
    },
    getProjectStakeholdersGroupedByStatus (state) {
      try {
        const projectStakeholdersResult = state.projectStakeholders;
        const projectStakeholdersGroupedByStatus = {
          cardTitle: projectStakeholdersResult.length + ' ' + t('General.Stakeholders'),
          cardSubtitle: t('Project.Frontend.Tension'),
          itemCount: projectStakeholdersResult.length,
          panels: []
        };

        for (let i = 0; i < projectStakeholdersResult.length; i++) {
          const stakeholder = projectStakeholdersResult[i];
          if (!stakeholder.status) {
            // Default to --unclear
            stakeholder.status = {
              nr: 5,
              name: t('Status.Onduidelijk')
            };
            // continue;
          }
          let statusIndex = projectStakeholdersGroupedByStatus.panels.findIndex(function (x) {
            return x.id === stakeholder.status.nr;
          });

          let className = null;
          let name = '';
          switch (stakeholder.status.nr) {
            case 1:
              className = '--criticism';
              name = t('Status.Kritiek');
              break;
            case 2:
              className = '--warning';
              name = t('Status.Waarschuwing');
              break;
            case 3:
              className = '--focus';
              name = t('Status.Aandacht');
              break;
            case 4:
              className = '--positive';
              name = t('Status.Positief');
              break;
            case 5:
              className = '--unclear';
              name = t('Status.Onduidelijk');
              break;
          }
          if (statusIndex === -1) {
            // Add new status
            projectStakeholdersGroupedByStatus.panels.push({
              id: stakeholder.status.nr,
              panelTitle: name,
              className: className,
              isCollapsed: true,
              panel: []
            });

            statusIndex = projectStakeholdersGroupedByStatus.panels.findIndex(function (x) {
              return x.id === stakeholder.status.nr;
            });
          }
          projectStakeholdersGroupedByStatus.panels[statusIndex].panel.push({
            id: stakeholder.id,
            title: stakeholder.name,
            urlTo: '/projects/' + this.project.id + '/stakeholders/' + stakeholder.id,
            className: className,
            participation: stakeholder.participation,
            participationText: stakeholder.participationText,
            emotion: stakeholder.emotion,
            urgency: stakeholder.urgency,
            concernNr: stakeholder.concernNr,
            concernText: stakeholder.concernText,
            statusNr: stakeholder.statusNr,
            pointOfView: stakeholder.pointOfView
          });
        }
        projectStakeholdersGroupedByStatus.panels.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
        return projectStakeholdersGroupedByStatus;
      } catch (err) {
        console.error(err);
        return {
          cardTitle: '0 ' + t('General.Stakeholders'),
          cardSubtitle: t('Project.Frontend.Tension'),
          itemCount: 0,
          panels: []
        };
      }
    },
    getProjectStakeholdersToCompareGroupedByStatus (state) {
      try {
        const projectStakeholdersResult = state.projectStakeholdersToCompare;
        const projectStakeholdersGroupedByStatus = {
          cardTitle: projectStakeholdersResult.length + ' ' + t('General.Stakeholders'),
          cardSubtitle: t('Project.Frontend.Tension'),
          itemCount: projectStakeholdersResult.length,
          panels: []
        };

        for (let i = 0; i < projectStakeholdersResult.length; i++) {
          const stakeholder = projectStakeholdersResult[i];
          if (!stakeholder.status) {
            // Default to --unclear
            stakeholder.status = {
              nr: 5,
              name: t('Status.Onduidelijk')
            };
            // continue;
          }
          let statusIndex = projectStakeholdersGroupedByStatus.panels.findIndex(function (x) {
            return x.id === stakeholder.status.nr;
          });

          let className = null;
          switch (stakeholder.status.nr) {
            case 1:
              className = '--criticism';
              break;
            case 2:
              className = '--warning';
              break;
            case 3:
              className = '--focus';
              break;
            case 4:
              className = '--positive';
              break;
            case 5:
              className = '--unclear';
              break;
          }
          if (statusIndex === -1) {
            // Add new status
            projectStakeholdersGroupedByStatus.panels.push({
              id: stakeholder.status.nr,
              panelTitle: stakeholder.status.name,
              className: className,
              isCollapsed: true,
              panel: []
            });

            statusIndex = projectStakeholdersGroupedByStatus.panels.findIndex(function (x) {
              return x.id === stakeholder.status.nr;
            });
          }
          projectStakeholdersGroupedByStatus.panels[statusIndex].panel.push({
            id: stakeholder.id,
            title: stakeholder.name,
            urlTo: '/projects/' + this.project.id + '/stakeholders/' + stakeholder.id,
            className: className,
            participation: stakeholder.participation,
            participationText: stakeholder.participationText,
            emotion: stakeholder.emotion,
            urgency: stakeholder.urgency,
            concernNr: stakeholder.concernNr,
            concernText: stakeholder.concernText,
            statusNr: stakeholder.statusNr,
            pointOfView: stakeholder.pointOfView
          });
        }
        projectStakeholdersGroupedByStatus.panels.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
        return projectStakeholdersGroupedByStatus;
      } catch (err) {
        console.error(err);
        return {
          cardTitle: '0 ' + t('General.Stakeholders'),
          cardSubtitle: t('Project.Frontend.Tension'),
          itemCount: 0,
          panels: []
        };
      }
    },
    getProjectStakeholdersGroupedByCategory (state) {
      try {
        const projectStakeholdersResult = state.projectStakeholders;
        const projectStakeholdersGroupedByCategory = {
          cardTitle: projectStakeholdersResult.length + ' ' + t('General.Stakeholders'),
          cardSubtitle: t('General.Categories'),
          itemCount: projectStakeholdersResult.length,
          panels: []
        };

        for (let i = 0; i < projectStakeholdersResult.length; i++) {
          const stakeholder = projectStakeholdersResult[i];
          if (!stakeholder.customCategory) {
            stakeholder.customCategory = ' ' + t('General.NoCategory');
            // continue;
          }
          let statusIndex = projectStakeholdersGroupedByCategory.panels.findIndex(function (x) {
            return x.id === stakeholder.customCategory;
          });

          if (statusIndex === -1) {
            // Add new status
            projectStakeholdersGroupedByCategory.panels.push({
              id: stakeholder.customCategory,
              panelTitle: stakeholder.customCategory,
              className: null,
              isCollapsed: true,
              editable: false,
              newValue: stakeholder.customCategory,
              panel: []
            });

            statusIndex = projectStakeholdersGroupedByCategory.panels.findIndex(function (x) {
              return x.id === stakeholder.customCategory;
            });
          }
          projectStakeholdersGroupedByCategory.panels[statusIndex].panel.push({
            id: stakeholder.id,
            title: stakeholder.name,
            urlTo: '/projects/' + this.project.id + '/stakeholders/' + stakeholder.id,
            className: null,
            participation: stakeholder.participation,
            participationText: stakeholder.participationText,
            emotion: stakeholder.emotion,
            urgency: stakeholder.urgency,
            concernNr: stakeholder.concernNr,
            concernText: stakeholder.concernText,
            statusNr: stakeholder.statusNr,
            pointOfView: stakeholder.pointOfView
          });
        }
        projectStakeholdersGroupedByCategory.panels.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
        return projectStakeholdersGroupedByCategory;
      } catch (err) {
        console.error(err);
        return {
          cardTitle: '0 ' + t('General.Stakeholders'),
          cardSubtitle: t('General.Categories'),
          itemCount: 0,
          panels: []
        };
      }
    },
    getProjectIssuesByIdName (state) {
      const issues = [];
      for (let i = 0; i < state.projectIssues.length; i++) {
        let classStatus = '--unclear';

        if (this.projectIssues[i].status) {
          switch (this.projectIssues[i].status.nr) {
            case 1:
              classStatus = '--criticism';
              break;
            case 2:
              classStatus = '--warning';
              break;
            case 3:
              classStatus = '--focus';
              break;
            case 4:
              classStatus = '--positive';
              break;
            case 5:
              classStatus = '--unclear';
              break;
          }
        } else {
          classStatus = '--unclear';
        }
        issues.push({
          id: state.projectIssues[i].id,
          name: state.projectIssues[i].name,
          className: classStatus
        });
      }

      return issues;
    },
    getProjectStakeholderByIdName (state) {
      const stakeholders = [];
      for (let i = 0; i < state.projectStakeholders.length; i++) {
        let classStatus = '--unclear';

        if (this.projectStakeholders[i].status) {
          switch (this.projectStakeholders[i].status.nr) {
            case 1:
              classStatus = '--criticism';
              break;
            case 2:
              classStatus = '--warning';
              break;
            case 3:
              classStatus = '--focus';
              break;
            case 4:
              classStatus = '--positive';
              break;
            case 5:
              classStatus = '--unclear';
              break;
          }
        } else {
          classStatus = '--unclear';
        }

        stakeholders.push({
          id: state.projectStakeholders[i].id,
          name: state.projectStakeholders[i].name,
          className: classStatus
        });
      }
      return stakeholders;
    }
  },
  actions: {
    fetchImage () {
      return true;
      // This is sometimes a big performance loss.
      // return (window.$cookies.get('ApiBaseUrl').indexOf('https://localhost:7271/') > 0);
    },
    async fetchProjects () {
      this.error = null;

      try {
        await HttpClient.get('api/v1/projects/')
          .then((projects) => {
            this.allProjects = projects.data;
          });
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },
    async fetchMultipleProjectsPictures (guid) {
      // This is sometimes a big performance loss.
      if (!this.fetchImage()) {
        return;
      }
      // LoadingProjects boolean is set in projectoverview, because this function is called in a loop
      const projectIndex = this.allProjects.findIndex((x) => x.id === guid);

      try {
        const pic = await HttpClient.get('api/v1/projects/' + guid + '/picture');

        this.allProjects[projectIndex].picture = pic.data.picture;
        this.allProjects[projectIndex].pictureFileExtension = pic.data.pictureFileExtension;
        this.allProjects[projectIndex].pictureFilename = pic.data.pictureFilename;
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },
    async fetchNewProject () {
      this.loadingProject = true;
      this.projectPicture = {};
      this.orgProjectPicture = {};

      this.projectToAdd = {
        name: '',
        gisDefaultCoordinatesLat: 51.98325542289134,
        gisDefaultCoordinatesLng: 5.5652480849364405,
        gisDefaultZoomLevel: 7
      };

      this.loadingProject = false;
    },
    async fetchSingleProject (guid) {
      // Reset if a new project is loaded
      if (this.project.id !== guid) {
        this.projectIssues = [];
        this.projectIssuesGroupedByStatus = [];
        this.projectStakeholders = [];
        this.projectStakeholdersToCompare = [];
        this.projectStakeholdersGroupedByStatus = [];
        this.projectConcernMatrix = {};
        this.projectMembers = [];
      }
      this.loadingProject = true;
      this.projectPicture = {};
      this.orgProjectPicture = {};
      this.projectToEdit = {};
      try {
        const response = await HttpClient.get('api/v1/projects/' + guid);
        this.project = response.data;
        // Split custom fields if they exist
        if (this.project.customIssueFields) {
          this.project.customIssueFields = this.project.customIssueFields.split(',');
        } else {
          this.project.customIssueFields = [];
        }
        if (this.project.customStakeholderFields) {
          this.project.customStakeholderFields = this.project.customStakeholderFields.split(',');
        } else {
          this.project.customStakeholderFields = [];
        }
        // Update URLs in addOptionsMenuBar
        for (let i = 0; i < this.addOptionsMenuBar.length; i++) {
          switch (i) {
            case 0: this.addOptionsMenuBar[i].url = '/projects/' + this.project.id + '/notes/create'; break;
            case 1: this.addOptionsMenuBar[i].url = '/projects/' + this.project.id + '/media/create'; break;
          }
        }
        this.projectToEdit = this.project;
        this.loadingProject = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProject = false;
      }
    },
    async fetchSingleProjectGeo (guid) {
      this.loadingProjectGeo = true;

      this.projectGeo = {};
      this.projectIssuesGeo = null;
      this.projectStakeholdersGeo = null;
      try {
        const response = await HttpClient.get('api/v1/projects/' + guid + '/geo');
        this.projectGeo = response.data;

        if (this.projectGeo && this.projectGeo.features) {
          let result = this.projectGeo.features.filter(x => x.dataType === 'issue');
          if (result !== null) {
            this.projectIssuesGeo = {
              type: 'FeatureCollection',
              features: []
            };
            this.projectIssuesGeo.features = result;
          }

          result = this.projectGeo.features.filter(x => x.dataType === 'stakeholder');
          if (result !== null) {
            this.projectStakeholdersGeo = {
              type: 'FeatureCollection',
              features: []
            };
            this.projectStakeholdersGeo.features = result;
          }
        }

        this.loadingProjectGeo = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectGeo = false;
      }
    },
    async archiveSingleProject (guid) {
      // Reset if a new project is loaded
      try {
        const response = await HttpClient.get('api/v1/projects/' + guid + '/archive');
        this.project = response.data;
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },
    async indexProjects (guid) {
      // Index all projects
      try {
        this.loadingIndex = true;
        await HttpClient.get('api/v1/searchindex/rebuild');
        this.loadingIndex = false;
      } catch (err) {
        this.loadingIndex = false;
        console.error(err);
        this.error = err;
      }
    },
    async fetchSingleProjectIssues (guid, selectedDate) {
      this.loadingProjectIssues = true;
      if (this.projectIssuesSelectedDate !== selectedDate) {
        this.projectIssues = [];
        this.projectIssuesGroupedByStatus = [];
      }
      try {
        const addOn = selectedDate ? '/version?date=' + selectedDate : '';
        const response = await HttpClient.get(`api/v1/projects/${guid}/issues${addOn}`);
        this.projectIssues = response.data;
        this.loadingProjectIssues = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectIssues = false;
      }
    },
    async fetchSingleProjectStakeholders (guid, selectedDate) {
      this.loadingProjectStakeholders = true;
      if (this.projectStakeholdersSelectedDate !== selectedDate) {
        this.projectStakeholders = [];
        this.projectStakeholdersGroupedByStatus = [];
      }
      try {
        const addOn = selectedDate ? '/version?date=' + selectedDate : '';
        const response = await HttpClient.get('api/v1/projects/' + guid + '/stakeholders' + addOn);
        this.projectStakeholders = response.data;
        this.loadingProjectStakeholders = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectStakeholders = false;
      }
    },
    async fetchSingleProjectStakeholdersToCompare (guid, compareDate) {
      this.loadingProjectStakeholdersToCompare = true;
      this.projectStakeholdersToCompare = [];

      if (this.projectStakeholdersToCompareDate !== compareDate) {
        this.projectStakeholdersToCompare = [];
        this.projectStakeholdersToCompareGroupedByStatus = [];
      }
      try {
        const addOn = compareDate ? '/version?date=' + compareDate : '';
        const response = await HttpClient.get('api/v1/projects/' + guid + '/stakeholders' + addOn);
        this.projectStakeholdersToCompare = response.data;
        this.loadingProjectStakeholdersToCompare = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectStakeholdersToCompare = false;
      }
    },
    async fetchSingleProjectConcernMatrix (guid, selectedDate) {
      this.loadingProjectConcernMatrix = true;
      try {
        const addOn = selectedDate ? '?date=' + selectedDate : '';
        const response = await HttpClient.get('api/v1/projects/' + guid + '/concernmatrix/export' + addOn);
        this.projectConcernMatrix = response.data;
        this.loadingProjectConcernMatrix = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectConcernMatrix = false;
      }
    },
    async fetchSingleProjectExportViewPointTable (guid, selectedDate) {
      this.loadingProjectExportViewPointTable = true;

      try {
        const addOn = selectedDate ? '?date=' + selectedDate : '';
        const response = await HttpClient.get('api/v1/projects/' + guid + '/environmentmonitor/export' + addOn);
        this.projectExportViewPointTable = response.data;

        this.loadingProjectExportViewPointTable = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectExportViewPointTable = false;
      }
    },
    async fetchSingleProjectMembers (guid) {
      this.loadingProjectMembers = true;

      try {
        const response = await HttpClient.get('api/v1/projects/' + guid + '/members');
        this.projectMembers = response.data;
        this.loadingProjectMembers = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectMembers = false;
      }
    },
    async fetchSingleProjectPicture (guid) {
      if (!this.fetchImage()) {
        return;
      }
      this.loadingProjectPicture = true;
      this.projectPicture = {};
      this.orgProjectPicture = {};
      try {
        const pic = await HttpClient.get('api/v1/projects/' + guid + '/picture');

        this.projectPicture.picture = pic.data.picture;
        this.projectPicture.pictureFileExtension = pic.data.pictureFileExtension;
        this.projectPicture.pictureFilename = pic.data.pictureFilename;

        this.orgProjectPicture = this.projectPicture;
        this.loadingProjectPicture = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectPicture = false;
      }
    },
    async fetchSingleProjectStakeholderIssueAnalysis (guid) {
      this.loadingProjectStakeholderIssueAnalysis = true;

      try {
        const stakeholderIssueAnalysis = await HttpClient.get('api/v1/projects/' + guid + '/stakeholderissueanalysis');

        if (stakeholderIssueAnalysis.data) {
          this.projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysis = stakeholderIssueAnalysis.data.stakeholderIssueAnalysis;
          this.projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysisFilename = stakeholderIssueAnalysis.data.stakeholderIssueAnalysisFilename;
          this.projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysisContentType = stakeholderIssueAnalysis.data.stakeholderIssueAnalysisContentType;
        }

        this.loadingProjectStakeholderIssueAnalysis = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectStakeholderIssueAnalysis = false;
      }
    },
    async fetchSingleSupportRequestNew () {
      this.loadingSupportRequest = true;
      try {
        this.supportRequestEdit = {
          projectId: null,
          description: '',
          expectation: '',
          question: '',
          reproduce: '',
          browserInfo: navigator.userAgent,
          operationSystem: navigator.platform,
          typeOfMention: ''
        };
        this.loadingSupportRequest = false;
        this.supportRequestOk = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.supportRequestOk = false;
        this.loadingSupportRequest = false;
      }
    },
    async addNewProject (project, projectPicture) {
      if (project.customStakeholderFields !== undefined) {
        project.customStakeholderFields = project.customStakeholderFields.toString();
      }
      if (project.customIssueFields !== undefined) {
        project.customIssueFields = project.customIssueFields.toString();
      }

      let responseData = null;
      try {
        const response = await HttpClient.post('api/v1/projects', project);
        responseData = response.data;
      } catch (err) {
        console.error(err);
      }

      if (responseData) {
        const projectId = responseData.id;
        if (projectPicture && projectPicture.length > 0) {
          const modelPicture = {
            id: projectId,
            picture: projectPicture[0].base64,
            pictureFilename: projectPicture[0].name,
            pictureFileExtension: projectPicture[0].type
          };
          await HttpClient.put(`api/v1/projects/${projectId}/picture`, modelPicture).catch(err => console.error(err));
        }
      }
      await this.fetchProjects();

      // If we don't change it back to an array, the next project will have a string value instead of an array
      if (project.customStakeholderFields !== undefined) {
        project.customStakeholderFields = project.customStakeholderFields.split(',');
      }
      if (project.customIssueFields !== undefined) {
        project.customIssueFields = project.customIssueFields.split(',');
      }
      return responseData;
    },
    async updateProject (guid, project, projectPicture) {
      project.customStakeholderFields = project.customStakeholderFields.toString();
      project.customIssueFields = project.customIssueFields.toString();
      console.info('updateProject', project);
      await HttpClient.put(`api/v1/projects/${guid}`, project).catch(err => console.error(err));

      if (projectPicture && projectPicture.length > 0) {
        const modelPicture = {
          id: project.id,
          picture: projectPicture[0].base64,
          pictureFilename: projectPicture[0].name,
          pictureFileExtension: projectPicture[0].type
        };
        await HttpClient.put(`api/v1/projects/${guid}/picture`, modelPicture).catch(err => console.error(err));
      }
    },
    async updateCustomCategoryIssuesByProject (guid, oldCategory, newCategory) {
      try {
        var newCat = encodeURIComponent(newCategory);
        var oldCat = encodeURIComponent(oldCategory);

        await HttpClient.get(`api/v1/projects/${guid}/issues/replacecustomcategory?oldcategory=${oldCat}&newcategory=${newCat}`)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateCustomCategoryStakeholdersByProject (guid, oldCategory, newCategory) {
      try {
        var newCat = encodeURIComponent(newCategory);
        var oldCat = encodeURIComponent(oldCategory);

        await HttpClient.get(`api/v1/projects/${guid}/stakeholders/replacecustomcategory?oldcategory=${oldCat}&newcategory=${newCat}`)
          .then(() => {
            //
          });
      } catch (error) {
        console.error('error:' + error);
        this.error = error;
      }
    },
    async inviteMember (projectId, username, role) {
      const userModel = {
        ProjectId: projectId,
        Role: {
          Nr: role
        },
        User: {
          UserName: username
        }
      };

      let responseData = null;
      try {
        const response = await HttpClient.post('api/v1/projects/' + projectId + '/members', userModel);
        responseData = response.data;
      } catch (err) {
        console.error(err);
      }

      return responseData;
    },
    async deleteMember (projectId, username) {
      let responseData = null;
      try {
        const response = await HttpClient.delete('api/v1/projects/' + projectId + '/members/' + username);
        responseData = response.data;
        this.projectMembers.splice(this.projectMembers.indexOf(this.projectMembers.find(x => x.id === username)), 1);
      } catch (err) {
        console.error(err);
      }

      return responseData;
    },
    async updateMember (projectId, member) {
      let responseData = null;
      try {
        const response = await HttpClient.put('api/v1/projects/' + projectId + '/members/' + member.id, member);
        responseData = response.data;
      } catch (err) {
        console.error(err);
      }

      return responseData;
    },
    async updateProjectStakeholderIssueAnalysis (projectId, projectStakeholderIssueAnalysisFile) {
      this.loadingProjectStakeholderIssueAnalysis = true;

      try {
        if (projectStakeholderIssueAnalysisFile.length > 0) {
          this.projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysis = projectStakeholderIssueAnalysisFile[0].base64;
          this.projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysisFilename = projectStakeholderIssueAnalysisFile[0].name;
          this.projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysisContentType = projectStakeholderIssueAnalysisFile[0].type;
          this.projectProjectStakeholderIssueAnalysis.id = projectId;

          await HttpClient.put('api/v1/projects/' + projectId + '/stakeholderissueanalysis/', this.projectProjectStakeholderIssueAnalysis);
        }

        this.projectProjectStakeholderIssueAnalysisFile = {};
        this.loadingProjectStakeholderIssueAnalysis = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingProjectStakeholderIssueAnalysis = false;
      }
    },
    async addNewSuppporRequest (supportRequest) {
      this.loadingSupportRequest = true;

      let responseData = null;
      try {
        const response = await HttpClient.post('api/v1/projects/supportrequest', supportRequest);
        responseData = response.data;
        this.supportRequestOk = true;
      } catch (err) {
        console.error(err);
      }

      this.loadingSupportRequest = false;
      return responseData;
    },
    async updateSingleProjectStakeholders (stakeholderId, status) {
      // updates array projectStakeholders with new status
      // function is  triggered in importancematrix
      if (this.projectStakeholders === null || this.projectStakeholders.length === 0) {
        // nothing to update
        return;
      }
      const idx = this.projectStakeholders.findIndex(x => x.id === stakeholderId);
      if (idx === -1) {
        // not found
        return;
      }
      this.projectStakeholders[idx].statusNr = status;
      if (this.projectStakeholders[idx].status !== null) {
        this.projectStakeholders[idx].status.nr = status;
      }
    },
    async updateSingleProjectIssues (issueId, status) {
      // updates array projectIssues with new status
      // function is  triggered in importancematrix
      if (this.projectIssues === null || this.projectIssues.length === 0) {
        // nothing to update
        return;
      }
      const idx = this.projectIssues.findIndex(x => x.id === issueId);
      if (idx === -1) {
        // not found
        return;
      }
      this.projectIssues[idx].statusNr = status;
      if (this.projectIssues[idx].status !== null) {
        this.projectIssues[idx].status.nr = status;
      }
    }
  }
});
