import { defineStore } from 'pinia';

// role info
// 1 = beheren
// 2 = bewerken
// 3 = lezen

export const useRolesStore = defineStore({
  id: 'roles',
  state: () => ({
    userActions: [
      {
        action: 'projectsview.newproject',
        role: 1
      },
      {
        action: 'issues.download',
        role: 1
      },
      {
        action: 'issues.download',
        role: 2
      },
      {
        action: 'issues.download',
        role: 3
      },
      {
        action: 'issues.newissue',
        role: 1
      },
      {
        action: 'issues.newissue',
        role: 2
      },
      {
        action: 'issue.download',
        role: 1
      },
      {
        action: 'issue.download',
        role: 2
      },
      {
        action: 'issue.download',
        role: 3
      },
      {
        action: 'issue.edit',
        role: 1
      },
      {
        action: 'issue.edit',
        role: 2
      },
      {
        action: 'issue.delete',
        role: 1
      },
      {
        action: 'issue.checkbox.status',
        role: 1
      },
      {
        action: 'issue.checkbox.status',
        role: 2
      },
      {
        action: 'issue.map.edit',
        role: 1
      },
      {
        action: 'issue.map.edit',
        role: 2
      },
      {
        action: 'stakeholders.newstakeholder',
        role: 1
      },
      {
        action: 'stakeholders.newstakeholder',
        role: 2
      },
      {
        action: 'stakeholder.edit',
        role: 1
      },
      {
        action: 'stakeholder.edit',
        role: 2
      },
      {
        action: 'stakeholder.delete',
        role: 1
      },
      {
        action: 'stakeholder.checkbox.review',
        role: 1
      },
      {
        action: 'stakeholder.checkbox.review',
        role: 2
      },
      {
        action: 'stakeholder.checkbox.importance',
        role: 1
      },
      {
        action: 'stakeholder.checkbox.importance',
        role: 2
      },
      {
        action: 'stakeholder.dropdown.participation',
        role: 1
      },
      {
        action: 'stakeholder.dropdown.participation',
        role: 2
      },
      {
        action: 'stakeholder.map.edit',
        role: 1
      },
      {
        action: 'stakeholder.map.edit',
        role: 2
      },
      {
        action: 'stakeholder.contacts.edit',
        role: 1
      },
      {
        action: 'stakeholder.contacts.edit',
        role: 2
      },
      {
        action: 'projectconcernmatrix.edit',
        role: 1
      },
      {
        action: 'projectconcernmatrix.edit',
        role: 2
      },
      {
        action: 'projectimportancematrix.edit',
        role: 1
      },
      {
        action: 'projectimportancematrix.edit',
        role: 2
      },
      {
        action: 'projectenvironmentmonitormatrix.edit',
        role: 1
      },
      {
        action: 'projectenvironmentmonitormatrix.edit',
        role: 2
      },
      {
        action: 'projectprogressmatrixview.edit',
        role: 1
      },
      {
        action: 'projectprogressmatrixview.edit',
        role: 2
      },
      {
        action: 'projectstakeholderissueanalysisview.edit',
        role: 1
      },
      {
        action: 'projectstakeholderissueanalysisview.edit',
        role: 2
      },
      {
        action: 'notes.edit',
        role: 1
      },
      {
        action: 'notes.edit',
        role: 2
      },
      {
        action: 'note.edit',
        role: 1
      },
      {
        action: 'note.edit',
        role: 2
      },
      {
        action: 'note.delete',
        role: 1
      },
      {
        action: 'media.new',
        role: 1
      },
      {
        action: 'media.new',
        role: 2
      },
      {
        action: 'media.edit',
        role: 1
      },
      {
        action: 'media.edit',
        role: 2
      },
      {
        action: 'media.delete',
        role: 1
      },
      {
        action: 'project.menu',
        role: 1
      },
      {
        action: 'project.members',
        role: 1
      },
      {
        action: 'project.membersreadonly',
        role: 2
      },
      {
        action: 'project.membersreadonly',
        role: 3
      },
      {
        action: 'project.members.add',
        role: 1
      },
      {
        action: 'project.members.edit',
        role: 1
      },
      {
        action: 'project.edit',
        role: 1
      },
      {
        action: 'issue.updatecategory',
        role: 1
      },
      {
        action: 'stakeholder.updatecategory',
        role: 1
      }
    ]
  }),
  getters: {
  },
  actions: {
    IsActionAllowedInRole (action, currentUser, projectId) {
      // console.log('action: ' + action + ' - ' + projectId);
      if (currentUser.isAdministrator) {
        // console.log('admin granted');
        return true;
      }
      if (currentUser && currentUser.relatedProjectUser && currentUser.relatedProjectUser.length > 0) {
        const project = currentUser.relatedProjectUser.find(x => {
          return x.projectId === projectId && x.userId === currentUser.id;
        });
        // console.info('DEBUG: IsActionAllowedInRole', currentUser, project);
        if (project === undefined) {
          // project not found
          // console.log('not granted - project not found');
          return false;
        }
        const result = this.userActions.find(x => {
          return x.action === action && x.role === project.role.nr;
        });
        if (result === undefined) {
          // action not found
          // console.log('not granted - action not found');
          return false;
        }
        // console.log('granted');
        return true;
      }
      // console.log('not granted');
      return false;
    }
  }
});
