<script setup>
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';

// Config imports
import apiTestUrlsJson from '@/config/apiUrls.json';
import apiProductionUrlsJson from '@/config/apiUrls.production.json';

// Router import
import router from './router';

// import i18n from './translations'

// Service imports

// Component imports
import MenuBar from './modules/menubar/MenuBar.vue';
import SideBar from './modules/sidebar/SideBar.vue';
import PButton from 'primevue/button';
import PCard from 'primevue/card';

// Store imports
import { useSideBarStore } from './store/modules/sidebar';
import { useUserStore } from '@/store/modules/user';
import { useGraphApiStore } from '@/store/modules/graphapi';
import { useGlobalStore } from '@/store/modules/global';

// Authentication
import { useMsal } from './services/auth/useMsal';
const { accounts } = useMsal();

// Api Urls
const apiUrlsJson = (process.env.VUE_APP_SOMSET_ENV !== 'production' ? apiTestUrlsJson : apiProductionUrlsJson);
// console.info('App process.env.VUE_APP_SOMSET_ENV', process.env.VUE_APP_SOMSET_ENV, apiUrlsJson);

const getAccountUsername = computed(() => {
  if (accounts.value.length > 0) {
    const username = accounts.value[0].username;
    if (username) {
      return username;
    }
  }
  return '';
});

// Variables
const routes = ref([]);
const { setCurrentUserEnvironment, fetchTranslations, setCurrentUserLanguage } = useGlobalStore();
const { fetchCurrentUser, fetchCurrentUserPicture, fetchLatestTermsOfUse, updateLastLoginDateCurrentUser, updateCurrentUserLanguage } = useUserStore();
const { fetchUserEnvironments } = useGraphApiStore();
const { currentUser, latestTermsOfUse, isLoadingUser, isLoadingTermsOfUse } = storeToRefs(useUserStore());
const { toggleSideBarClass, showSideBar } = storeToRefs(useSideBarStore());
const { userEnvironments } = storeToRefs(useGraphApiStore());

// Functions
onMounted(async () => {
  setTimeout(async () => {
    // await i18n.global.setLocaleMessage('nl', translationsNl.value);
    // First catch the user environments
    await fetchUserEnvironments();

    // get translations
    fetchTranslations();

    // Now do the other stuff
    await writeApiUrlToCookie();
    routes.value = router
      .getRoutes()
      .sort((a, b) => a.path.localeCompare(b.path));
    await fetchCurrentUser();

    // check language and set default language
    if (currentUser.value.language === null) {
      currentUser.value.language = 'nl';
      await updateCurrentUserLanguage(currentUser.value.language);
    }
    window.$cookies.set('language', currentUser.value.language);
    setCurrentUserLanguage(currentUser.value.language);

    await fetchCurrentUserPicture();
    await fetchLatestTermsOfUse();
    await updateLastLoginDateCurrentUser();
  }, 2000);

  const winWidth = window.innerWidth;
  if (winWidth < 992) {
    useSideBarStore().$patch((state) => {
      state.toggleSideBarClass = false;
    });
  }

  window.addEventListener('resize', () => {
    const winWidth = window.innerWidth;
    if (winWidth < 992) {
      useSideBarStore().$patch((state) => {
        state.toggleSideBarClass = false;
      });
    } else {
      useSideBarStore().$patch((state) => {
        state.toggleSideBarClass = true;
      });
    }
  });
});

const validUserEnvironments = () => {
  const environments = [];
  for (let i = 0; i < apiUrlsJson.length; i++) {
    if (userEnvironments.value.findIndex(function (x) {
      return x === apiUrlsJson[i].code;
    }) >= 0) {
      environments.push(apiUrlsJson[i]);
    }
  }
  return environments;
};

async function writeApiUrlToCookie () {
  const urlCookie = window.$cookies.get('ApiBaseUrl');

  if (urlCookie === null) {
    let apiCode = '';
    for (let idx = 0; idx < userEnvironments.value.length; idx++) {
      const api = apiUrlsJson.find(
        function (data) {
          return data.code === userEnvironments.value[idx];
        }
      );

      if (api !== undefined) {
        apiCode = api.code;
        break;
      }
    }

    setCurrentUserEnvironment(apiCode, validUserEnvironments());
  }
}

const goToTermsOfUse = () => {
  router.push({ path: '/me/terms/' });
};
</script>

<!-- Using $route.fullPath as the key will trigger rerendering :-) -->
<template>
  <template v-if="getAccountUsername !== '' && router.currentRoute.value.name === 'project-screenshot-map'">
    <router-view>
    </router-view>
  </template>
  <template v-else>
    <!--  @@ currentuser: {{ currentUser }}<br/>-->
    <!--  @@ getAccountUsername: {{ getAccountUsername }}<br/>-->
    <!--  @@ loadingUser: {{ isLoadingUser }}<br/>-->
    <!--  @@ Object.keys(currentUser).length: {{ Object.keys(currentUser).length }}<br/>-->
    <!--  @@ currentUser.termsOfUseVersion: {{ currentUser.termsOfUseVersion }}<br />-->
    <!--  @@ latestTermsOfUse.id: {{ latestTermsOfUse.id }}<br />-->
    <!--  @@ route.fullPath: {{ $route.fullPath}}<br/>-->
    <!--  @@ Object.keys(currentUser).length !== 0 && (currentUser.termsOfUseVersion === latestTermsOfUse.id || $route.fullPath === '/me/terms/'): {{ Object.keys(currentUser).length !== 0 && (currentUser.termsOfUseVersion === latestTermsOfUse.id || $route.fullPath === '/me/terms/') }} @@-->
    <div class="w-100 checking-credentials" v-if="getAccountUsername === ''">
      <div class="logo my-3"></div>
      <h1 class="w-100">Inloggegevens controleren</h1>
      <p>Een moment geduld...</p>
    </div>
    <div
      class="d-flex flex-nowrap align-items-stretch w-100"
      v-if="getAccountUsername !== ''">
      <div
        class="app__sidebar overflow-hidden"
        :class="{ 'w-60': !toggleSideBarClass }"
        v-if="showSideBar">
        <side-bar
          v-if="!isLoadingUser"
          :key="$route.fullPath">
        </side-bar>
      </div>
      <div
        class="app__main position-relative w-100"
        v-if="!isLoadingUser">
        <menu-bar v-if="getAccountUsername !== '' && currentUser.termsOfUseVersion === latestTermsOfUse.id"></menu-bar>
        <router-view
          v-if="getAccountUsername !== '' && (currentUser.termsOfUseVersion === latestTermsOfUse.id || $route.fullPath === '/me/terms/')"
          :key="$route.fullPath">
        </router-view>
        <p-card class="app__card position-absolute d-inline-block mt-16"
                v-else-if="!isLoadingUser && !isLoadingTermsOfUse && getAccountUsername !== '' && currentUser.termsOfUseVersion !== latestTermsOfUse.id && $route.fullPath !== '/me/terms/'">
          <template #content>
            <div class="pt-1 pb-1 pe-1 ps-1">
              <div class="app__logo mb-3"></div>
              <p-button
                class="w-100"
                :label="$t('General.ToTermsOfUse')"
                @click="goToTermsOfUse()">
              </p-button>
            </div>
          </template>
        </p-card>
        <!--      <p-card class="app__card position-absolute d-inline-block mt-16"-->
        <!--              v-else-if="Object.keys(currentUser).length === 0 && !isLoadingUser && !isLoadingTermsOfUse">-->
        <!--        <template #content>-->
        <!--          <div class="pt-1 pb-1 pe-1 ps-1">-->
        <!--            <div class="app__logo mb-3"></div>-->
        <!--            <p-button class="w-100" label="Login" @click="loginRedirect()">-->
        <!--            </p-button>-->
        <!--          </div>-->
        <!--        </template>-->
        <!--      </p-card>-->
      </div>
    </div>
    <!--  <div class="d-flex flex-nowrap align-items-stretch w-100" v-else>-->
    <!--    <div class="position-relative w-100">-->
    <!--      <p-card class="app__card position-absolute d-inline-block mt-16">-->
    <!--        <template #content>-->
    <!--          <div class="pt-1 pb-1 pe-1 ps-1">-->
    <!--            <div class="app__logo mb-3"></div>-->
    <!--            <p-button class="w-100" label="Login" @click="loginRedirect()">-->
    <!--            </p-button>-->
    <!--          </div>-->
    <!--        </template>-->
    <!--      </p-card>-->
    <!--    </div>-->
    <!--  </div>-->
  </template>
</template>

<style lang="scss">
.app {
  &__sidebar {
    flex: 0 0 calc-rem(226);
    width: calc-rem(226);
    min-height: 100vh;
    background-color: $white;
    border-right: calc-rem(1) solid $separatorLineColor;
    transition: all 200ms ease-out;

    @media (max-width: 991.98px) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 399;
    }

    &.w-60 {
      flex: 0 0 calc-rem(60);
      width: calc-rem(60);
    }
  }

  &__main {
    @media (max-width: 992.98px) {
      padding-left: calc-rem(60);
    }
  }

  &__logo {
    aspect-ratio: 39 / 22;
    background-image: url(assets/images/logo-somset.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__card {
    top: 20%;
    left: 50%;
    width: calc-rem(220);
    transform: translate(-50%, -50%);
  }

  .checking-credentials {
    text-align: center;

    h1 {
      color: $primary;
    }

    .logo {
      width: calc-rem(400);
      aspect-ratio: 39 / 22;
      background-image: url(assets/images/logo-somset.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: auto;
    }
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
