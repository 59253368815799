import { defineStore } from 'pinia';
import { AuthService } from '@/services/auth';
import axios from 'axios';

const auth = new AuthService();

export const useGraphApiStore = defineStore({
  id: 'graphapi',
  state: () => ({
    groups: [],
    groupsIBelongTo: [],
    userEnvironments: [],
    loadingGroups: false,
    loadingGroupsIBelongTo: false,
    loadingUserEnvironments: false,
    error: null
  }),
  getters: {
  },
  actions: {
    async fetchAllGroups () {
      const authHeader = await auth.configureMicrosoftGraphAuthorizationHeader();
      this.loadingGroups = true;

      try {
        await axios.get('https://graph.microsoft.com/v1.0/groups', authHeader)
          .then((groups) => {
            this.groups = groups;
            this.loadingGroups = false;
          });
      } catch (error) {
        this.error = error;
      }
    },

    async fetchAllGroupsIBelongTo () {
      const authHeader = await auth.configureMicrosoftGraphAuthorizationHeader();
      this.loadingGroupsIBelongTo = true;

      try {
        await axios.get('https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true', authHeader)
          .then((groupsIBelongTo) => {
            this.groupsIBelongTo = groupsIBelongTo;
            this.loadingGroupsIBelongTo = false;
          });
      } catch (error) {
        this.error = error;
      }
    },

    async fetchUserEnvironments () {
      // Get the environments (API's) the user can interact with. These values are stored in a comma seperated string in the companyName field of a user
      // e.g.: xuntos,wvz,microsoft,etc.
      // This is because we can't add a StringCollection as a custom attribute and are limited by the group functionality MS provides

      const authHeader = await auth.configureMicrosoftGraphAuthorizationHeader();
      this.loadingUserEnvironments = true;

      try {
        const response = await axios.get('https://graph.microsoft.com/v1.0/me?$select=companyName', authHeader);

        if (response.data !== null && response.data.companyName !== null) {
          const receivedEnvironments = response.data.companyName.split(',');

          this.userEnvironments = receivedEnvironments;
        }

        this.loadingUserEnvironments = false;
      } catch (error) {
        this.error = error;
      }
    }
  }
});
