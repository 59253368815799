import useMoment from 'moment';

export default {
  historyTextBlock (currentTextValue, oldTextValue, date) {
    currentTextValue = (currentTextValue === undefined || currentTextValue?.length === 0 ? null : currentTextValue);
    oldTextValue = (oldTextValue === undefined || oldTextValue?.length === 0 ? null : oldTextValue);
    if (currentTextValue === null && oldTextValue === null) {
      return '';
    }
    let content = '<div class="bg--gray p-2 mb-2">';
    content += '<p><em>Op ' + useMoment(date).format('DD-MM-YYYY') + ' was de waarde:</em></p>';
    if (currentTextValue !== oldTextValue) {
      if (oldTextValue?.indexOf('<p>') < 0) {
        content += '<p>' + oldTextValue ?? '</p>';
      } else {
        content += oldTextValue ?? 'Geen waarde';
      }
    } else {
      content += '<p>De waarde was hetzelfde als nu.</p>';
    }
    content += '</div>';
    return content;
  },
  historyDropdownTextBlock (currentDropdownValue, oldDropdownValue, currentTextValue, oldTextValue, date) {
    currentDropdownValue = (currentDropdownValue === undefined || currentDropdownValue?.length === 0 ? null : currentDropdownValue);
    oldDropdownValue = (oldDropdownValue === undefined || oldDropdownValue?.length === 0 ? null : oldDropdownValue);
    currentTextValue = (currentTextValue === undefined || currentTextValue?.length === 0 ? null : currentTextValue);
    oldTextValue = (oldTextValue === undefined || oldTextValue?.length === 0 ? null : oldTextValue);
    if (currentDropdownValue === null && oldDropdownValue === null && currentTextValue === null && oldTextValue === null) {
      return '';
    }
    let content = '<div class="bg--gray p-2 mb-2">';
    content += '<p><em>Op ' + useMoment(date).format('DD-MM-YYYY') + ' was de waarde:</em></p>';
    if (currentDropdownValue !== oldDropdownValue || currentTextValue !== oldTextValue) {
      content += '<p><strong>Waarde:</strong> ' + (oldDropdownValue === null ? 'Geen waarde' : oldDropdownValue) + '</p>';
      content += '<h4>Tekst</h4>';
      if (oldTextValue?.indexOf('<p>') < 0) {
        content += '<p>' + oldTextValue ?? '' + '</p>';
      } else {
        content += oldTextValue ?? 'Geen waarde';
      }
    } else {
      content += '<p>De waarde was hetzelfde als nu.</p>';
    }
    content += '</div>';
    return content;
  }
};
