<template>
  <div ref="container" :class="containerClass" @click="onClick($event)">
    <div class="p-hidden-accessible">
      <input
        ref="focusInput"
        type="text"
        :id="inputId"
        readonly
        :disabled="disabled"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeyDown"
        :tabindex="tabindex"
        aria-haspopup="true"
        :aria-expanded="overlayVisible"
        :aria-labelledby="ariaLabelledBy"
      />
    </div>
    <input
      v-if="editable"
      type="text"
      class="p-dropdown-label p-inputtext"
      :disabled="disabled"
      @focus="onFocus"
      @blur="onBlur"
      :placeholder="placeholder"
      :value="editableInputValue"
      @input="onEditableInput"
      aria-haspopup="listbox"
      :aria-expanded="overlayVisible"
    />
    <span v-if="!editable" :class="labelClass">
      <slot name="value" :value="modelValue" :placeholder="placeholder">{{
        label || 'empty'
      }}</slot>
    </span>
    <i
      v-if="showClear && modelValue != null"
      class="p-dropdown-clear-icon pi pi-times"
      @click="onClearClick($event)"
    ></i>
    <div
      class="p-dropdown-trigger"
      role="button"
      aria-haspopup="listbox"
      :aria-expanded="overlayVisible"
    >
      <slot name="indicator">
        <span :class="dropdownIconClass"></span>
      </slot>
    </div>
    <Portal :appendTo="appendTo">
      <transition
        name="p-connected-overlay"
        @enter="onOverlayEnter"
        @after-enter="onOverlayAfterEnter"
        @leave="onOverlayLeave"
        @after-leave="onOverlayAfterLeave"
      >
        <div
          :ref="overlayRef"
          :class="panelStyleClass"
          v-if="overlayVisible"
          @click="onOverlayClick"
        >
          <slot
            name="header"
            :value="modelValue"
            :options="visibleOptions"
          ></slot>
          <div class="p-dropdown-header" v-if="filter">
            <div class="p-dropdown-filter-container">
              <input
                type="text"
                ref="filterInput"
                :value="filterValue"
                @vnode-updated="onFilterUpdated"
                autoComplete="off"
                class="p-dropdown-filter p-inputtext p-component"
                :placeholder="filterPlaceholder"
                @keydown="onFilterKeyDown"
                @input="onFilterChange"
              />
              <span class="p-dropdown-filter-icon pi pi-search"></span>
            </div>
          </div>
          <div
            :ref="itemsWrapperRef"
            class="p-dropdown-items-wrapper"
            :style="{
              'max-height': virtualScrollerDisabled ? scrollHeight : '',
            }"
          >
            <VirtualScroller
              :ref="virtualScrollerRef"
              v-bind="virtualScrollerOptions"
              :items="visibleOptions"
              :style="{ height: scrollHeight }"
              :disabled="virtualScrollerDisabled"
            >
              <template
                v-slot:content="{
                  styleClass,
                  contentRef,
                  items,
                  getItemOptions,
                  contentStyle,
                }"
              >
                <ul
                  :ref="contentRef"
                  :class="['p-dropdown-items', styleClass]"
                  :style="contentStyle"
                  role="listbox"
                >
                  <template v-if="!optionGroupLabel">
                    <li
                      v-for="(option, i) of items"
                      :class="[
                        'p-dropdown-item',
                        {
                          'p-highlight': isSelected(option),
                          'p-disabled': isOptionDisabled(option),
                        },
                      ]"
                      v-ripple
                      :key="getOptionRenderKey(option, i)"
                      @click="onOptionSelect($event, option)"
                      role="option"
                      :aria-label="getOptionLabel(option)"
                      :aria-selected="isSelected(option)"
                    >
                      <slot
                        name="option"
                        :option="option"
                        :index="getOptionIndex(i, getItemOptions)"
                        >{{ getOptionLabel(option) }}</slot
                      >
                    </li>
                  </template>
                  <template v-else>
                    <template
                      v-for="(optionGroup, i) of items"
                      :key="getOptionGroupRenderKey(optionGroup)"
                    >
                      <li class="p-dropdown-item-group">
                        <slot
                          name="optiongroup"
                          :option="optionGroup"
                          :index="getOptionIndex(i, getItemOptions)"
                          >{{ getOptionGroupLabel(optionGroup) }}</slot
                        >
                      </li>
                      <li
                        v-for="(option, i) of getOptionGroupChildren(
                          optionGroup
                        )"
                        :class="[
                          'p-dropdown-item',
                          {
                            'p-highlight': isSelected(option),
                            'p-disabled': isOptionDisabled(option),
                          },
                        ]"
                        v-ripple
                        :key="getOptionRenderKey(option, i)"
                        @click="onOptionSelect($event, option)"
                        role="option"
                        :aria-label="getOptionLabel(option)"
                        :aria-selected="isSelected(option)"
                      >
                        <slot
                          name="option"
                          :option="option"
                          :index="getOptionIndex(i, getItemOptions)"
                          >{{ getOptionLabel(option) }}</slot
                        >
                      </li>
                    </template>
                  </template>
                  <li
                    v-if="
                      filterValue && (!items || (items && items.length === 0))
                    "
                    class="p-dropdown-empty-message"
                  >
                    <slot name="emptyfilter">{{ emptyFilterMessageText }}</slot>
                  </li>
                  <li
                    v-else-if="!options || (options && options.length === 0)"
                    class="p-dropdown-empty-message"
                  >
                    <slot name="empty">{{ emptyMessageText }}</slot>
                  </li>
                </ul>
              </template>
              <template v-slot:loader="{ options }" v-if="$slots.loader">
                <slot name="loader" :options="options"></slot>
              </template>
            </VirtualScroller>
          </div>
          <slot
            name="footer"
            :value="modelValue"
            :options="visibleOptions"
          ></slot>
        </div>
      </transition>
    </Portal>
  </div>
</template>

<script>
import {
  ConnectedOverlayScrollHandler,
  ObjectUtils,
  DomHandler,
  ZIndexUtils
} from 'primevue/utils';
import OverlayEventBus from 'primevue/overlayeventbus';
import { FilterService } from 'primevue/api';
import Ripple from 'primevue/ripple';
import VirtualScroller from 'primevue/virtualscroller';
import Portal from 'primevue/portal';

export default {
  name: 'DropdownCustom',
  emits: [
    'update:modelValue',
    'before-show',
    'before-hide',
    'show',
    'hide',
    'change',
    'filter',
    'focus',
    'blur'
  ],
  props: {
    modelValue: null,
    options: Array,
    optionLabel: null,
    optionValue: null,
    optionDisabled: null,
    optionGroupLabel: null,
    optionGroupChildren: null,
    scrollHeight: {
      type: String,
      default: '200px'
    },
    filter: Boolean,
    filterPlaceholder: String,
    filterLocale: String,
    filterMatchMode: {
      type: String,
      default: 'contains'
    },
    filterFields: {
      type: Array,
      default: null
    },
    editable: Boolean,
    placeholder: String,
    disabled: Boolean,
    dataKey: null,
    showClear: Boolean,
    inputId: String,
    tabindex: String,
    ariaLabelledBy: null,
    appendTo: {
      type: String,
      default: 'body'
    },
    emptyFilterMessage: {
      type: String,
      default: null
    },
    emptyMessage: {
      type: String,
      default: null
    },
    panelClass: null,
    loading: {
      type: Boolean,
      default: false
    },
    loadingIcon: {
      type: String,
      default: 'pi pi-spinner pi-spin'
    },
    virtualScrollerOptions: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      focused: false,
      filterValue: null,
      overlayVisible: false
    };
  },
  watch: {
    modelValue () {
      this.isModelValueChanged = true;
    }
  },
  outsideClickListener: null,
  scrollHandler: null,
  resizeListener: null,
  searchTimeout: null,
  currentSearchChar: null,
  previousSearchChar: null,
  searchValue: null,
  overlay: null,
  itemsWrapper: null,
  virtualScroller: null,
  isModelValueChanged: false,
  updated () {
    if (this.overlayVisible && this.isModelValueChanged) {
      this.scrollValueInView();
    }

    this.isModelValueChanged = false;
  },
  beforeUnmount () {
    this.unbindOutsideClickListener();
    this.unbindResizeListener();

    if (this.scrollHandler) {
      this.scrollHandler.destroy();
      this.scrollHandler = null;
    }

    this.itemsWrapper = null;

    if (this.overlay) {
      ZIndexUtils.clear(this.overlay);
      this.overlay = null;
    }
  },
  methods: {
    getOptionIndex (index, fn) {
      return this.virtualScrollerDisabled ? index : fn && fn(index).index;
    },
    getOptionLabel (option) {
      return this.optionLabel
        ? ObjectUtils.resolveFieldData(option, this.optionLabel)
        : option;
    },
    getOptionValue (option) {
      return this.optionValue
        ? ObjectUtils.resolveFieldData(option, this.optionValue)
        : option;
    },
    getOptionRenderKey (option, index) {
      return this.dataKey
        ? ObjectUtils.resolveFieldData(option, this.dataKey)
        : this.getOptionLabel(option) + '_' + index.toString();
    },
    isOptionDisabled (option) {
      return this.optionDisabled
        ? ObjectUtils.resolveFieldData(option, this.optionDisabled)
        : false;
    },
    getOptionGroupRenderKey (optionGroup) {
      return ObjectUtils.resolveFieldData(optionGroup, this.optionGroupLabel);
    },
    getOptionGroupLabel (optionGroup) {
      return ObjectUtils.resolveFieldData(optionGroup, this.optionGroupLabel);
    },
    getOptionGroupChildren (optionGroup) {
      return ObjectUtils.resolveFieldData(
        optionGroup,
        this.optionGroupChildren
      );
    },
    getSelectedOption () {
      const index = this.getSelectedOptionIndex();
      return index !== -1
        ? this.optionGroupLabel
          ? this.getOptionGroupChildren(this.visibleOptions[index.group])[index.option]
          : this.visibleOptions[index]
        : null;
    },
    getSelectedOptionIndex () {
      if (this.modelValue !== null && this.visibleOptions) {
        if (this.optionGroupLabel) {
          for (let i = 0; i < this.visibleOptions.length; i++) {
            const selectedOptionIndex = this.findOptionIndexInList(
              this.modelValue,
              this.getOptionGroupChildren(this.visibleOptions[i])
            );
            if (selectedOptionIndex !== -1) {
              return { group: i, option: selectedOptionIndex };
            }
          }
        } else {
          return this.findOptionIndexInList(
            this.modelValue,
            this.visibleOptions
          );
        }
      }

      return -1;
    },
    findOptionIndexInList (value, list) {
      for (let i = 0; i < list.length; i++) {
        if (
          ObjectUtils.equals(
            value,
            this.getOptionValue(list[i]),
            this.equalityKey
          )
        ) {
          return i;
        }
      }

      return -1;
    },
    isSelected (option) {
      return ObjectUtils.equals(
        this.modelValue,
        this.getOptionValue(option),
        this.equalityKey
      );
    },
    show (isFocus) {
      this.$emit('before-show');
      this.overlayVisible = true;

      isFocus && this.$refs.focusInput.focus();
    },
    hide () {
      this.$emit('before-hide');
      this.overlayVisible = false;
    },
    onFocus (event) {
      this.overlayVisible = true;
      this.focused = true;
      this.$emit('focus', event);
    },
    onBlur (event) {
      this.focused = false;
      this.$emit('blur', event);
    },
    onKeyDown (event) {
      // console.log('DropdownCustom.onKeyDown', event);
      this.overlayVisible = true;
      switch (event.which) {
        // down
        case 40:
          this.onDownKey(event);
          break;

        // up
        case 38:
          this.onUpKey(event);
          break;

        // space
        case 32:
          if (!this.overlayVisible) {
            this.show();
            event.preventDefault();
          }
          break;

        // enter and escape
        case 13:
        case 27:
          if (this.overlayVisible) {
            this.hide();
            event.preventDefault();
          }
          break;

        // tab
        case 9:
          this.hide();
          break;

        default:
          this.search(event);
          break;
      }
    },
    onFilterKeyDown (event) {
      // console.log('DropdownCustom.onFilterKeyDown', event);
      this.overlayVisible = true;
      switch (event.which) {
        // down
        case 40:
          this.onDownKey(event);
          break;

        // up
        case 38:
          this.onUpKey(event);
          break;

        // enter and escape
        case 13:
        case 27:
          this.overlayVisible = false;
          event.preventDefault();
          break;

        default:
          break;
      }
    },
    onDownKey (event) {
      if (this.visibleOptions) {
        if (!this.overlayVisible && event.altKey) {
          this.show();
        } else {
          const nextOption =
            this.visibleOptions && this.visibleOptions.length > 0
              ? this.findNextOption(this.getSelectedOptionIndex())
              : null;
          if (nextOption) {
            this.updateModel(event, this.getOptionValue(nextOption));
          }
        }
      }

      event.preventDefault();
    },
    onUpKey (event) {
      if (this.visibleOptions) {
        const prevOption = this.findPrevOption(this.getSelectedOptionIndex());
        if (prevOption) {
          this.updateModel(event, this.getOptionValue(prevOption));
        }
      }

      event.preventDefault();
    },
    findNextOption (index) {
      if (this.optionGroupLabel) {
        const groupIndex = index === -1 ? 0 : index.group;
        const optionIndex = index === -1 ? -1 : index.option;
        const option = this.findNextOptionInList(
          this.getOptionGroupChildren(this.visibleOptions[groupIndex]),
          optionIndex
        );

        if (option) {
          return option;
        } else if (groupIndex + 1 !== this.visibleOptions.length) {
          return this.findNextOption({ group: groupIndex + 1, option: -1 });
        } else {
          return null;
        }
      } else {
        return this.findNextOptionInList(this.visibleOptions, index);
      }
    },
    findNextOptionInList (list, index) {
      const i = index + 1;
      if (i === list.length) {
        return null;
      }

      const option = list[i];
      if (this.isOptionDisabled(option)) return this.findNextOptionInList(i);
      else return option;
    },
    findPrevOption (index) {
      if (index === -1) {
        return null;
      }

      if (this.optionGroupLabel) {
        const groupIndex = index.group;
        const optionIndex = index.option;
        const option = this.findPrevOptionInList(
          this.getOptionGroupChildren(this.visibleOptions[groupIndex]),
          optionIndex
        );

        if (option) return option;
        else if (groupIndex > 0) {
          return this.findPrevOption({
            group: groupIndex - 1,
            option: this.getOptionGroupChildren(
              this.visibleOptions[groupIndex - 1]
            ).length
          });
        } else return null;
      } else {
        return this.findPrevOptionInList(this.visibleOptions, index);
      }
    },
    findPrevOptionInList (list, index) {
      const i = index - 1;
      if (i < 0) {
        return null;
      }

      const option = list[i];
      if (this.isOptionDisabled(option)) return this.findPrevOption(i);
      else return option;
    },
    onClearClick (event) {
      this.updateModel(event, null);
    },
    onClick (event) {
      if (this.disabled || this.loading) {
        return;
      }

      if (
        DomHandler.hasClass(event.target, 'p-dropdown-clear-icon') ||
        event.target.tagName === 'INPUT'
      ) {
        // Do nothing
        // return;
      } else if (!this.overlay || !this.overlay.contains(event.target)) {
        if (this.overlayVisible) {
          this.hide();
        } else {
          this.show();
        }
        this.$refs.focusInput.focus();
      }
    },
    onOptionSelect (event, option) {
      const value = this.getOptionValue(option);
      this.updateModel(event, value);
      this.$refs.focusInput.focus();

      setTimeout(() => {
        this.hide();
      }, 200);
    },
    onEditableInput (event) {
      this.$emit('update:modelValue', event.target.value);
    },
    onOverlayEnter (el) {
      ZIndexUtils.set('overlay', el, this.$primevue.config.zIndex.overlay);
      this.alignOverlay();
      this.scrollValueInView();

      if (!this.virtualScrollerDisabled) {
        const selectedIndex = this.getSelectedOptionIndex();
        if (selectedIndex !== -1) {
          setTimeout(() => {
            this.virtualScroller &&
              this.virtualScroller.scrollToIndex(selectedIndex);
          }, 0);
        }
      }
    },
    onOverlayAfterEnter () {
      if (this.filter) {
        this.$refs.filterInput.focus();
      }

      this.bindOutsideClickListener();
      this.bindScrollListener();
      this.bindResizeListener();

      this.$emit('show');
    },
    onOverlayLeave () {
      this.unbindOutsideClickListener();
      this.unbindScrollListener();
      this.unbindResizeListener();
      this.$emit('hide');
      this.itemsWrapper = null;
      this.overlay = null;
    },
    onOverlayAfterLeave (el) {
      ZIndexUtils.clear(el);
    },
    alignOverlay () {
      if (this.appendTo === 'self') {
        DomHandler.relativePosition(this.overlay, this.$el);
      } else {
        this.overlay.style.minWidth = DomHandler.getOuterWidth(this.$el) + 'px';
        DomHandler.absolutePosition(this.overlay, this.$el);
      }
    },
    updateModel (event, value) {
      this.$emit('update:modelValue', value);
      this.$emit('change', { originalEvent: event, value: value });
    },
    bindOutsideClickListener () {
      if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
          if (
            this.overlayVisible &&
            this.overlay &&
            !this.$el.contains(event.target) &&
            !this.overlay.contains(event.target)
          ) {
            this.hide();
          }
        };
        document.addEventListener('click', this.outsideClickListener);
      }
    },
    unbindOutsideClickListener () {
      if (this.outsideClickListener) {
        document.removeEventListener('click', this.outsideClickListener);
        this.outsideClickListener = null;
      }
    },
    bindScrollListener () {
      if (!this.scrollHandler) {
        this.scrollHandler = new ConnectedOverlayScrollHandler(
          this.$refs.container,
          () => {
            if (this.overlayVisible) {
              this.hide();
            }
          }
        );
      }

      this.scrollHandler.bindScrollListener();
    },
    unbindScrollListener () {
      if (this.scrollHandler) {
        this.scrollHandler.unbindScrollListener();
      }
    },
    bindResizeListener () {
      if (!this.resizeListener) {
        this.resizeListener = () => {
          if (this.overlayVisible && !DomHandler.isTouchDevice()) {
            this.hide();
          }
        };
        window.addEventListener('resize', this.resizeListener);
      }
    },
    unbindResizeListener () {
      if (this.resizeListener) {
        window.removeEventListener('resize', this.resizeListener);
        this.resizeListener = null;
      }
    },
    search (event) {
      if (!this.visibleOptions) {
        return;
      }

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      const char = event.key;
      this.previousSearchChar = this.currentSearchChar;
      this.currentSearchChar = char;

      if (this.previousSearchChar === this.currentSearchChar) {
        this.searchValue = this.currentSearchChar;
      } else {
        this.searchValue = this.searchValue ? this.searchValue + char : char;
      }

      if (this.searchValue) {
        let searchIndex = this.getSelectedOptionIndex();
        const newOption = this.optionGroupLabel
          ? this.searchOptionInGroup(searchIndex)
          : this.searchOption(++searchIndex);
        if (newOption) {
          this.updateModel(event, this.getOptionValue(newOption));
        }
      }

      this.searchTimeout = setTimeout(() => {
        this.searchValue = null;
      }, 250);
    },
    searchOption (index) {
      let option;

      if (this.searchValue) {
        option = this.searchOptionInRange(index, this.visibleOptions.length);

        if (!option) {
          option = this.searchOptionInRange(0, index);
        }
      }

      return option;
    },
    searchOptionInRange (start, end) {
      for (let i = start; i < end; i++) {
        const opt = this.visibleOptions[i];
        if (this.matchesSearchValue(opt)) {
          return opt;
        }
      }

      return null;
    },
    searchOptionInGroup (index) {
      const searchIndex = index === -1 ? { group: 0, option: -1 } : index;

      for (let i = searchIndex.group; i < this.visibleOptions.length; i++) {
        const groupOptions = this.getOptionGroupChildren(this.visibleOptions[i]);
        for (
          let j = searchIndex.group === i ? searchIndex.option + 1 : 0;
          j < groupOptions.length;
          j++
        ) {
          if (this.matchesSearchValue(groupOptions[j])) {
            return groupOptions[j];
          }
        }
      }

      for (let i = 0; i <= searchIndex.group; i++) {
        const groupOptions = this.getOptionGroupChildren(this.visibleOptions[i]);
        for (
          let j = 0;
          j <
          (searchIndex.group === i ? searchIndex.option : groupOptions.length);
          j++
        ) {
          if (this.matchesSearchValue(groupOptions[j])) {
            return groupOptions[j];
          }
        }
      }

      return null;
    },
    matchesSearchValue (option) {
      const label = this.getOptionLabel(option).toLocaleLowerCase(
        this.filterLocale
      );
      return label.startsWith(
        this.searchValue.toLocaleLowerCase(this.filterLocale)
      );
    },
    onFilterChange (event) {
      this.filterValue = event.target.value;
      this.$emit('filter', { originalEvent: event, value: event.target.value });
    },
    onFilterUpdated () {
      if (this.overlayVisible) {
        this.alignOverlay();
      }
    },
    overlayRef (el) {
      this.overlay = el;
    },
    itemsWrapperRef (el) {
      this.itemsWrapper = el;
    },
    virtualScrollerRef (el) {
      this.virtualScroller = el;
    },
    scrollValueInView () {
      if (this.overlay) {
        const selectedItem = DomHandler.findSingle(
          this.overlay,
          'li.p-highlight'
        );
        if (selectedItem) {
          selectedItem.scrollIntoView({ block: 'nearest', inline: 'start' });
        }
      }
    },
    onOverlayClick (event) {
      OverlayEventBus.emit('overlay-click', {
        originalEvent: event,
        target: this.$el
      });
    }
  },
  computed: {
    visibleOptions () {
      if (this.filterValue) {
        if (this.optionGroupLabel) {
          const filteredGroups = [];
          for (const optgroup of this.options) {
            const filteredSubOptions = FilterService.filter(
              this.getOptionGroupChildren(optgroup),
              this.searchFields,
              this.filterValue,
              this.filterMatchMode,
              this.filterLocale
            );
            if (filteredSubOptions && filteredSubOptions.length) {
              const filteredGroup = { ...optgroup };
              filteredGroup[this.optionGroupChildren] = filteredSubOptions;
              filteredGroups.push(filteredGroup);
            }
          }
          return filteredGroups;
        } else {
          return FilterService.filter(
            this.options,
            this.searchFields,
            this.filterValue,
            this.filterMatchMode,
            this.filterLocale
          );
        }
      } else {
        return this.options;
      }
    },
    containerClass () {
      return [
        'p-dropdown p-component p-inputwrapper',
        {
          'p-disabled': this.disabled,
          'p-dropdown-clearable': this.showClear && !this.disabled,
          'p-focus': this.focused,
          'p-inputwrapper-filled': this.modelValue,
          'p-inputwrapper-focus': this.focused || this.overlayVisible
        }
      ];
    },
    labelClass () {
      return [
        'p-dropdown-label p-inputtext',
        {
          'p-placeholder': this.label === this.placeholder,
          'p-dropdown-label-empty':
            !this.$slots.value &&
            (this.label === 'p-emptylabel' || this.label.length === 0)
        }
      ];
    },
    panelStyleClass () {
      return [
        'p-dropdown-panel p-component',
        this.panelClass,
        {
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false
        }
      ];
    },
    label () {
      const selectedOption = this.getSelectedOption();
      if (selectedOption !== null) return this.getOptionLabel(selectedOption);
      else return this.placeholder || 'p-emptylabel';
    },
    editableInputValue () {
      const selectedOption = this.getSelectedOption();
      if (selectedOption) return this.getOptionLabel(selectedOption);
      else return this.modelValue;
    },
    equalityKey () {
      return this.optionValue ? null : this.dataKey;
    },
    searchFields () {
      return this.filterFields || [this.optionLabel];
    },
    emptyFilterMessageText () {
      return (
        this.emptyFilterMessage ||
        this.$primevue.config.locale.emptyFilterMessage
      );
    },
    emptyMessageText () {
      return this.emptyMessage || this.$primevue.config.locale.emptyMessage;
    },
    virtualScrollerDisabled () {
      return !this.virtualScrollerOptions;
    },
    dropdownIconClass () {
      return [
        'p-dropdown-trigger-icon',
        this.loading ? this.loadingIcon : 'pi pi-chevron-down'
      ];
    }
  },
  directives: {
    ripple: Ripple
  },
  components: {
    VirtualScroller: VirtualScroller,
    Portal: Portal
  }
};
</script>

<style>
.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.p-dropdown-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.p-dropdown-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
}

.p-dropdown-label-empty {
  overflow: hidden;
  visibility: hidden;
}

input.p-dropdown-label {
  cursor: default;
}

.p-dropdown .p-dropdown-panel {
  min-width: 100%;
}

.p-dropdown-panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.p-dropdown-items-wrapper {
  overflow: auto;
}

.p-dropdown-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-dropdown-item-group {
  cursor: auto;
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-dropdown-filter {
  width: 100%;
}

.p-dropdown-filter-container {
  position: relative;
}

.p-dropdown-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-fluid .p-dropdown {
  display: flex;
}

.p-fluid .p-dropdown .p-dropdown-label {
  width: 1%;
}
</style>
