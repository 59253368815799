<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { onClickOutside } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import useMoment from 'moment';
import router from '@/router';
import apiTestUrlsJson from '@/config/apiUrls.json';
import apiProductionUrlsJson from '@/config/apiUrls.production.json';

// Service imports
import { AuthService } from '@/services/auth';

// Component imports
import PMenubar from 'primevue/menubar';
import PInputtext from 'primevue/inputtext';
import PDialog from 'primevue/dialog';
// import PInputswitch from 'primevue/inputswitch';
import PButton from 'primevue/button';
import UiLogoLink from '@/core/ui/UiLogoLink';
import MenuBarDoubleIconButton from './components/MenuBarDoubleIconButton';
import MenuBarAvatarButton from './components/MenuBarAvatarButton';
import UiDropdownMenu from '@/core/ui/UiDropdownMenu';
import UiAvatar from '@/core/ui/UiAvatar';
import UiRouterLink from '@/core/ui/UiRouterLink';
import IssueCreateDialogForm from '../issueedit/components/IssueCreateDialogForm';
import StakeholdeerCreateDialogForm from '../stakeholderedit/components/StakeholderCreateDialogForm';

// Store imports
import { useUserStore } from '@/store/modules/user';
import { useMenuBarStore } from '@/store/modules/menubar';
import { useRoute } from 'vue-router';
import { useProjectsStore } from '@/store/modules/projects';
import { useIssuesStore } from '@/store/modules/issues';
import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useRolesStore } from '@/store/modules/roles';
import { useGraphApiStore } from '@/store/modules/graphapi';
import { useGlobalStore } from '@/store/modules/global';

// Api Urls
const apiUrlsJson = (process.env.VUE_APP_SOMSET_ENV !== 'production' ? apiTestUrlsJson : apiProductionUrlsJson);

// Variables
const auth = new AuthService();
const { setCurrentUserEnvironment, setCurrentUserLanguage } = useGlobalStore();
// const { getCurrentUserEnvironment } = storeToRefs(useGlobalStore());
const { showLogo, showLeftButtons, showAddButton } = storeToRefs(useMenuBarStore());
const { userEnvironments } = storeToRefs(useGraphApiStore());
const { IsActionAllowedInRole } = useRolesStore();
// const { updateAddDropdown } = useMenuBarStore();
const { currentUser, currentUserPicture } = storeToRefs(useUserStore());
const { allProjects, loadingAllProjects, addOptionsMenuBar, loadingProject, getProjectIssuesGroupedByCategory, getProjectStakeholdersGroupedByCategory } = storeToRefs(useProjectsStore());
const { fetchProjects, fetchMultipleProjectsPictures, fetchSingleProjectIssues, fetchSingleProjectStakeholders } = useProjectsStore();
const { adddNewIssueFast } = useIssuesStore();
const { createNewStakeholderFast } = useStakeholdersStore();
const { updateCurrentUserLanguage } = useUserStore();
// const { fetchProjects, fetchMultipleProjectsPictures } = useProjectsStore();
// const { fetchProjects } = useProjectsStore();
// const store = useProjectsStore();
const route = useRoute();
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});

// const checked = ref(false);
const showUserMenu = ref(false);
const showProjectsMenu = ref(false);
const showAddMenu = ref(false);
const closeAddMenu = () => {
  showAddMenu.value = false;
};
const closeProjectsMenu = () => {
  showProjectsMenu.value = false;
};
const closeUserMenu = () => {
  showUserMenu.value = false;
};
const searchTerm = ref('');
const targetAddMenu = ref(null);
const targetProjectsMenu = ref(null);
const targetUserMenu = ref(null);
const handleSearchSubmit = () => {
  if (route.query.project === undefined && route.params.projectId === undefined) {
    router.push({ path: '/searchresults', query: { searchfor: searchTerm.value } });
  } else {
    const projectId = route.params.projectId !== undefined ? route.params.projectId : (route.query.project !== undefined ? route.query.project : '');
    router.push({ path: '/searchresults', query: { searchfor: searchTerm.value, project: projectId } });
  }
  searchTerm.value = '';
};
const newIssue = ref();
const showAddIssueDialog = ref(false);
const configDialogNewIssue = {
  title: t('Project.AddIssue')
};
const addNewIssue = () => {
  newIssue.value = {
    name: '',
    description: '',
    customCategory: '',
    status: 0
  };
  showAddIssueDialog.value = true;
};
const saveNewIssue = async (status) => {
  if (!route.params.projectId) {
    return;
  }
  const projectId = route.params.projectId;
  const response = await adddNewIssueFast(projectId, newIssue.value);
  await fetchSingleProjectIssues(projectId);
  showAddIssueDialog.value = false;

  // save only
  if (status === 0) {
    return;
  }

  // save and edit
  if (status === 1) {
    await router.push({ path: '/projects/' + projectId + '/issues/' + response.id + '/edit' });
    return;
  }

  // save and new
  addNewIssue();
};
const cancelNewIssue = () => {
  showAddIssueDialog.value = false;
};
const getCategoriesIssues = () => {
  const availableCategories = getProjectIssuesGroupedByCategory.value;
  const optionsDropdown = [];
  if (availableCategories && availableCategories.panels.length) {
    for (let i = 0; i < availableCategories.panels.length; i++) {
      if (availableCategories.panels[i].panelTitle.trim().toLowerCase() !== t('General.NoCategory').toLowerCase()) {
        optionsDropdown.push({ name: availableCategories.panels[i].panelTitle });
      }
    }
  }
  return optionsDropdown;
};
const newStakeholder = ref();
const showAddStakeholderDialog = ref(false);
const configDialogNewStakeholder = {
  title: t('Project.AddStakeholder')
};
const addNewStakeholder = async () => {
  newStakeholder.value = {
    name: '',
    description: '',
    customCategory: '',
    status: 0
  };
  showAddStakeholderDialog.value = true;
};
const saveNewStakeholder = async (status) => {
  if (!route.params.projectId) {
    return;
  }
  const projectId = route.params.projectId;
  const response = await createNewStakeholderFast(projectId, newStakeholder.value);
  await fetchSingleProjectStakeholders(projectId);
  showAddStakeholderDialog.value = false;

  // save only
  if (status === 0) {
    return;
  }

  // save and edit
  if (status === 1) {
    await router.push({ path: '/projects/' + projectId + '/stakeholders/' + response.id + '/edit' });
    return;
  }

  await addNewStakeholder();
};
const cancelNewStakeholder = () => {
  showAddStakeholderDialog.value = false;
};
const getCategoriesStakeholders = () => {
  const availableCategories = getProjectStakeholdersGroupedByCategory.value;
  const optionsDropdown = [];
  if (availableCategories && availableCategories.panels.length) {
    for (let i = 0; i < availableCategories.panels.length; i++) {
      if (availableCategories.panels[i].panelTitle.trim().toLowerCase() !== t('General.NoCategory').toLowerCase()) {
        optionsDropdown.push({ name: availableCategories.panels[i].panelTitle });
      }
    }
  }
  return optionsDropdown;
};
const fullname = () => {
  return ((currentUser.firstname ? currentUser.firstname : '') + ' ' + (currentUser.lastname ? currentUser.lastname : '')).trim();
};
const validUserEnvironments = () => {
  const environments = [];
  for (let i = 0; i < apiUrlsJson.length; i++) {
    if (userEnvironments.value.findIndex(function (x) {
      return x === apiUrlsJson[i].code;
    }) >= 0) {
      environments.push(apiUrlsJson[i]);
    }
  }
  return environments;
};
const getCurrentEnvironmentCode = () => {
  return window.$cookies.get('ApiEnvironment');
};
const targetLang = ref(null);
const langCollapse = ref(false);
const collapseLang = () => {
  langCollapse.value = !langCollapse.value;
};

// Functions
onMounted(async () => {
  if (allProjects.value.length === 0) {
    loadingAllProjects.value = true;
    await fetchProjects().then(async () => {
      await allProjects.value.forEach(project => {
        fetchMultipleProjectsPictures(project.id);
      });
    });
    loadingAllProjects.value = false;
  }
});

// todo: this is not necessary here, retrieve projects from store. If not present, fetch the projects (pictures also not necessary for menu bar)
// store.$patch((state) => { state.loadingAllProjects = true; });
// fetchProjects().then(async () => {
//   await allProjects.value.forEach(project => {
//     fetchMultipleProjectsPictures(project.id);
//   });
//   store.$patch((state) => { state.loadingAllProjects = false; });

//   if (route.params !== undefined) {
//     await updateAddDropdown(route.params.projectId);
//   }
// });

async function setApiUrlToCookie (environment) {
  // console.info('setApiUrlToCookie', environment);
  // window.$cookies.set('ApiEnvironment', environment.code);
  // window.$cookies.set('ApiBaseUrl', environment.url);
  setCurrentUserEnvironment(environment.code, validUserEnvironments());
  document.location.href = '/?environment=' + environment.code;
}

const setLanguage = async (language) => {
  setCurrentUserLanguage(language);
  currentUser.value.language = language;
  window.$cookies.set('language', currentUser.value.language);
  await updateCurrentUserLanguage(language);
  document.location.href = '/';
};

onClickOutside(targetAddMenu, () => {
  closeAddMenu();
});

onClickOutside(targetProjectsMenu, () => {
  closeProjectsMenu();
});

onClickOutside(targetUserMenu, () => {
  closeUserMenu();
});

onClickOutside(targetLang, () => {
  langCollapse.value = false;
});
</script>

<template>
  <div class="menu-bar container-fluid">
    <div class="row h-100">
      <div class="col-12 h-100">
        <p-menubar class="h-100">
          <template #start>
            <div class="d-flex align-items-center">
              <ui-logo-link
                class="menu-bar__logo me-6"
                v-if="showLogo && !route.params.projectId">
              </ui-logo-link>
              <router-link
                class="menu-bar__home p-button p-button-secondary me-2 text-decoration-none"
                to="/"
                v-if="showLeftButtons && route.params.projectId">
                <span class="pi pi-home pe-1"></span>
                <span>{{ $t('General.Projects') }}</span>
              </router-link>
              <div
                class="position-relative"
                v-show="showLeftButtons"
                v-if="route.params.projectId && allProjects.length > 0">
                <menu-bar-double-icon-button
                  class="menu-bar__projects-button"
                  :title="allProjects ? (allProjects.find(function (x) { return x.id === route.params.projectId; }) ? allProjects.find(function (x) { return x.id === route.params.projectId; }).name : 'onbekend') : 'onbekend'"
                  @click="showProjectsMenu = !showProjectsMenu">
                </menu-bar-double-icon-button>
                <Transition name="left-slide-fade">
                  <ui-dropdown-menu
                    ref="targetProjectsMenu"
                    class="menu-bar__projects-menu"
                    v-show="showProjectsMenu"
                    v-if="!loadingAllProjects">
                    <ui-router-link
                      v-for="item in allProjects.sort((a, b) => a.name.localeCompare(b.name))"
                      :key="item.id"
                      :title="item.name ?? $t('General.Unknown')"
                      :icon-right="item.iconRight"
                      :icon-left="item.iconLeft"
                      :route-link="'/projects/' + item.id"
                      @btn-click="closeProjectsMenu()">
                    </ui-router-link>
                  </ui-dropdown-menu>
                </Transition>
              </div>
            </div>
          </template>
          <template #end>
            <div class="d-flex">
              <span class="p-input-icon-right me-2">
                <i class="pi pi-search" />
                <p-inputtext
                  type="text"
                  v-model="searchTerm"
                  :placeholder="$t('General.Search')"
                  @keyup.enter="handleSearchSubmit">
                </p-inputtext>
              </span>
              <div
                class="position-relative"
                v-if="!loadingProject && showAddButton && route.params.projectId && IsActionAllowedInRole('projectsview.newproject', currentUser, route.params.projectId)">
                <p-button
                  class="menu-bar__add me-2"
                  :label="$t('General.AddMenu')"
                  icon="pi pi-plus"
                  iconPos="center"
                  @click="showAddMenu = !showAddMenu">
                </p-button>
                <transition name="right-slide-fade">
                  <ui-dropdown-menu
                    ref="targetAddMenu"
                    class="menu-bar__add-menu pt-1 pb-1 pe-1 ps-1"
                    v-show="showAddMenu">
                    <p-button :label="$t('Project.AddIssue')" class="p-button-text menu-bar__dropdown-button" icon="pi pi-check-circle" @click="addNewIssue()"></p-button>
                    <p-button :label="$t('Project.AddStakeholder')" class="p-button-text menu-bar__dropdown-button" icon="pi pi-users" @click="addNewStakeholder()"></p-button>
                    <ui-router-link
                      v-for="item in addOptionsMenuBar"
                      :key="item.id"
                      :title="item.title"
                      :icon-right="item.iconRight"
                      :icon-left="item.iconLeft"
                      :route-link="item.url"
                      @btn-click="closeAddMenu()">
                    </ui-router-link>
                  </ui-dropdown-menu>
                </transition>
              </div>
              <div
                ref="targetUserMenu"
                class="position-relative">
                <menu-bar-avatar-button
                  v-if="currentUserPicture.picture !== null && currentUserPicture.picture !== undefined && currentUserPicture.pictureFileExtension !== ''"
                  class="menu-bar__user-button"
                  @click="showUserMenu = !showUserMenu"
                  :title="fullname()"
                  :img-url="'data:image/jpeg;base64,' + currentUserPicture.picture">
                </menu-bar-avatar-button>
                <menu-bar-avatar-button
                  v-else class="menu-bar__user-button"
                  @click="showUserMenu = !showUserMenu">
                </menu-bar-avatar-button>
                <transition name="left-slide-fade">
                  <ui-dropdown-menu
                    class="menu-bar__user-menu"
                    v-show="showUserMenu">
                    <div class="menu-bar__user-menu-header d-flex pt-3 pb-3 pe-3 ps-3">
                      <ui-avatar
                        v-if="currentUserPicture.picture !== null && currentUserPicture.picture !== undefined && currentUserPicture.pictureFileExtension !== ''"
                        class="menu-bar__user-avatar"
                        :title="fullname()"
                        :img-url="'data:image/jpeg;base64,' + currentUserPicture.picture">
                      </ui-avatar>
                      <ui-avatar
                        v-else
                        class="menu-bar__user-avatar"
                        :title="fullname()"
                        :img-url="''">
                      </ui-avatar>
                      <div class="menu-bar__user-body ps-3">
                        <span class="menu-bar__user-name mb-2 d-block">
                          {{ fullname().length > 0 ? fullname() : currentUser.userName }}
                        </span>
                        <span class="menu-bar__user-email d-block">
                          {{ currentUser.userName }}
                        </span>
                        <span class="menu-bar__user-log d-block">
                          {{  $t('General.LastLogin') }}:
                          {{ useMoment(currentUser.lastLoginDate).format('DD-MM-YYYY HH:mm') }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="menu-bar__theme-switch pe-1 ps-3 d-flex justify-content-between align-items-center"
                      v-if="false">
                      <span class="d-inline-block"> {{ $t('General.Frontend.DiplayInDark') }} </span>
                      <!-- p-inputswitch v-model="checked"></p-inputswitch -->
                    </div>
                    <div class="menu-bar__user-menu-links menu-bar__user-menu-header">
                      <a
                        v-for="env in validUserEnvironments()"
                        :key="('env' + env.code)"
                        href="#"
                        class="ui-router-link d-flex flex-nowrap justify-content-between align-items-center w-100 text-decoration-none"
                        @click="setApiUrlToCookie(env)">
                        <span class="d-flex flex-nowrap">
                          <span class="pi" :class="(getCurrentEnvironmentCode() === env.code ? 'pi-circle-fill' : 'pi-circle')"></span>
                          <span class="d-inline-block text-nowrap">{{env.label}}</span>
                        </span>
                        <span class="pi"></span>
                      </a>
                    </div>
                    <div class="menu-bar__user-menu-links">
                      <div
                        ref="targetLang"
                        class="menu-bar__user-menu-lang">
                        <button
                          type="button"
                          class="menu-bar__user-menu-lang-btn d-flex justify-content-between aligns-items-center"
                          @click="collapseLang()">
                          <span class="d-flex align-items-center flex-nowrap">
                            <span class="pi"></span>
                            <span class="d-inline-block text-nowrap">
                              {{ $t('Language.Choose') }}
                            </span>
                          </span>
                          <span class="pi pi-chevron-down"></span>
                        </button>
                        <div
                          :class="{ 'is-collapsed': langCollapse }"
                          class="menu-bar__user-menu-lang-collapse">
                          <button
                            type="button"
                            class="menu-bar__user-menu-lang-btn d-flex justify-content-between aligns-items-center"
                            @click="setLanguage('nl')">
                            <span class="d-flex align-items-center flex-nowrap">
                              <span class="pi flag --nl"></span>
                              <span>
                                {{ $t('Language.Dutch') }}
                              </span>
                            </span>
                            <span
                              v-show="currentUser.language === 'nl'"
                              class="pi pi-check">
                            </span>
                          </button>
                          <button
                            type="button"
                            class="menu-bar__user-menu-lang-btn d-flex justify-content-between aligns-items-center"
                            @click="setLanguage('en')">
                            <span class="d-flex align-items-center flex-nowrap">
                              <span class="pi flag --en"></span>
                              <span>
                                {{ $t('Language.English') }}
                              </span>
                            </span>
                            <span
                              v-show="currentUser.language === 'en'"
                              class="pi pi-check">
                            </span>
                          </button>
                        </div>
                      </div>
                      <ui-router-link
                        :title="$t('Profile.Edit')"
                        :icon-class="null"
                        :route-link="'/me'"
                        @btn-click="closeUserMenu()">
                      </ui-router-link>
                      <ui-router-link
                        :title="$t('General.Frontend.TermsOfUse')"
                        :icon-class="null"
                        :route-link="'/me/terms'"
                        @btn-click="closeUserMenu()">
                      </ui-router-link>
                      <ui-router-link
                        :title="$t('Logout')"
                        :icon-class="null"
                        :route-link="'#'"
                        @btn-click="auth.logout()">
                      </ui-router-link>
                    </div>
                  </ui-dropdown-menu>
                </transition>
              </div>
            </div>
          </template>
        </p-menubar>
      </div>
    </div>
  </div>
  <p-dialog
    class="issue-detail__dialog"
    position="top"
    v-model:visible="showAddIssueDialog"
    :modal="true">
    <template #header>
      <h3>
        {{ configDialogNewIssue.title}}
      </h3>
    </template>
    <issue-create-dialog-form
      :categories="getCategoriesIssues()"
      v-model="newIssue"
      @save="saveNewIssue(0)"
      @save-and-edit="saveNewIssue(1)"
      @save-and-new="saveNewIssue(2)"
      @close-dialog="cancelNewIssue">
    </issue-create-dialog-form>
    <template #footer></template>
  </p-dialog>
  <p-dialog
    class="stakeholder-detail__dialog"
    position="top"
    v-model:visible="showAddStakeholderDialog"
    :modal="true">
    <template #header>
      <h3>
        {{ configDialogNewStakeholder.title}}
      </h3>
    </template>
    <stakeholdeer-create-dialog-form
      :categories="getCategoriesStakeholders()"
      v-model="newStakeholder"
      @save="saveNewStakeholder(0)"
      @save-and-edit="saveNewStakeholder(1)"
      @save-and-new="saveNewStakeholder(2)"
      @close-dialog="cancelNewStakeholder">
    </stakeholdeer-create-dialog-form >
    <template #footer></template>
  </p-dialog>
</template>

<style lang="scss">
.menu-bar {
  height: calc-rem(60);

  &__logo {
    width: calc-rem(71);
    height: calc-rem(40);
  }

  &__add {
    &-menu {
      .pi {
        color: $secondaryTextColor;
      }
    }

    .p-button-label {
      @extend %sr-only;
    }
  }

  &__user {
    &-button {
      .ui-avatar {
        background-color: $white;
      }
    }

    &-menu {
      &-links {
        padding-top: calc-rem(8);

        .pi {
          color: #005FE3;
        }
      }

      &-lang {
        &-btn {
          position: relative;
          margin-bottom: calc-rem(2);
          padding-top: calc-rem(13);
          padding-right: calc-rem(12);
          padding-bottom: calc-rem(13);
          padding-left: calc-rem(12);
          width: 100%;
          cursor: pointer;
          font-size: calc-rem(14);
          text-align: left;
          border-radius: $borderRadius;
          border: none;
          background-color: transparent;
          color: $textColor;
          transition: all $transitionDuration ease-out;

          &:hover {
            background-color: $secondaryHover;
          }

          &:focus {
            box-shadow: $focusShadow;
          }

          .pi-chevron-down {
            color: inherit;
          }

          span .pi {
            margin-right: calc-rem(7);
          }

          span .flag {
            width: calc-rem(14);
            height: calc-rem(10.5);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            &.--nl {
              margin-left: calc-rem(7);
              background-image: url(../../assets/images/flags/nl.svg);
            }

            &.--en {
              margin-left: calc-rem(7);
              background-image: url(../../assets/images/flags/en.svg);
            }

            &.--es {
              margin-left: calc-rem(7);
              background-image: url(../../assets/images/flags/es.svg);
            }

            &.--fr {
              margin-left: calc-rem(7);
              background-image: url(../../assets/images/flags/fr.svg);
            }

            &.--de {
              margin-left: calc-rem(7);
              background-image: url(../../assets/images/flags/de.svg);
            }
          }
        }

        &-collapse {
          height: auto;
          max-height: 0;
          overflow: hidden;
          transition: all 400ms ease-in-out;

          &.is-collapsed {
            max-height: 1000px;
          }
        }
      }
    }
  }

  &__theme-switch {
    padding-top: calc-rem(16);
    padding-bottom: calc-rem(16);
    font-size: calc-rem(14);
    line-height: calc-rem(16);
    border-bottom: calc-rem(1) solid $separatorLineColor;

    span {
      font-size: calc-rem(14);
      line-height: calc-rem(16);
    }
  }

  .p {
    &-inputtext {
      height: calc-rem(40);
    }

    &-menubar {
      border-radius: 0;
      border-bottom: calc-rem(1) solid $separatorLineColor;

      &-button,
      &-root-list {
        display: none !important;
      }

      &-start {
        height: calc-rem(24);

        @include media-breakpoint-up(md) {
          height: calc-rem(24.29);
        }

        >div {
          height: inherit;
        }
      }
    }

    &-button-icon {
      line-height: calc-rem(16);
    }
  }

  &__dropdown-button {
    white-space: nowrap;
    text-align: left;
    padding-top: calc-rem(13) !important;
    padding-left: calc-rem(12) !important;
    padding-bottom: calc-rem(13) !important;
    padding-right: calc-rem(12) !important;

    &.p-button.p-button-text {
      color: #2B323B;
    }
  }
}
</style>

<style lang="scss" scoped>
.issue-detail__dialog {
  width: calc-rem(500) !important;
}
.stakeholder-detail__dialog {
  width: calc-rem(500) !important;
}

.menu-bar {
  &__home {
    padding-right: calc-rem(11);
    padding-left: calc-rem(11);
    height: calc-rem(40);
  }

  &__projects,
  &__add,
  &__user {
    &-menu {
      width: 1000%;
    }
  }

  &__projects {
    &-menu {
      /* max-width: calc-rem(236); */
      max-width: calc-rem(472);
    }
  }

  &__add {
    padding-right: calc-rem(11);
    padding-left: calc-rem(11);

    &-menu {
      right: calc-rem(10);
      max-width: calc-rem(216);
    }
  }

  &__user {
    &-button {
      .ui-avatar {
        background-color: $white;
      }
    }

    &-menu {
      right: 0;
      max-width: calc-rem(310);

      &-header {
        padding-top: calc-rem(8);
        padding-bottom: calc-rem(8);
        border-bottom: calc-rem(1) solid $separatorLineColor;
      }
    }

    &-avatar {
      width: calc-rem(40);
      height: calc-rem(40);
    }

    &-body {
      width: calc(100% - 40px);
    }

    &-name {
      font-size: calc-rem(12);
      font-weight: 600;
      line-height: calc-rem(12);
      color: $primary;
    }

    &-email,
    &-log {
      font-size: calc-rem(11);
      line-height: calc-rem(10);
    }

    &-email {
      margin-bottom: calc-rem(7);
    }

    &-log {
      color: $secondaryTextColor;
    }
  }
}
</style>
