<template>
  <div class="ui-dropdown-menu position-absolute mt-1 pt-1 pb-1 pe-1 ps-1">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.ui-dropdown-menu {
  z-index: $zindex-dropdown;
  width: 100%;
  border-radius: calc-rem(5);
  background-color: $white;
  box-shadow: 0 4px 20px rgba(0,0,0,.2);
}
</style>
