import { defineStore } from 'pinia';
import HttpClient from '@/services/httpclient';

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    currentUser: {},
    isDirty: false,
    currentUserPicture: {},
    profile: {},
    profilePicture: {},
    latestTermsOfUse: {},
    isLoadingUser: true,
    isLoadingUserPicture: true,
    isLoadingUserUpdate: true,
    isLoadingprofile: true,
    isLoadingprofilePicture: true,
    isLoadingTermsOfUse: true,
    error: null
  }),
  getters: {},
  actions: {
    // The force will help get 'me' any time for userrole purposes for example. It's with you :-).
    async fetchCurrentUser (force = false) {
      // console.info('FETCH ME', Object.keys(this.currentUser), this.currentUser, force);
      this.currentUserPicture = {};
      if (Object.keys(this.currentUser).length === 0 || force) {
        try {
          this.isLoadingUser = true;
          const response = await HttpClient.get('api/v1/me');
          this.currentUser = response.data;
          this.isLoadingUser = false;
        } catch (error) {
          console.error(error);
          this.error = error;
          this.isLoadingUser = false;
        }
      }
    },

    async fetchCurrentUserPicture () {
      this.isLoadingUserPicture = true;
      this.currentUserPicture = {};
      try {
        const response = await HttpClient.get('api/v1/me/picture');
        this.currentUserPicture = response.data;
        this.currentUserPicture.upload = [];
        this.isLoadingUserPicture = false;
      } catch (error) {
        this.isLoadingUserPicture = false;
        console.error(error);
        this.error = error;
      }
    },

    async updateCurrentUser () {
      this.isLoadingUserUpdate = true;

      try {
        await HttpClient.put('api/v1/me', this.currentUser)
          .then(() => {
            this.isLoadingUserUpdate = false;
          });
      } catch (error) {
        this.isLoadingUserUpdate = false;
        this.isLoadingUserUpdate = false;
        console.error(error);
        this.error = error;
      }
    },

    async updateCurrentUserLanguage (language) {
      try {
        await HttpClient.put('api/v1/me/language?languageCode=' + language);
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },

    async updateCurrentUserPicture () {
      this.isLoadingUserUpdate = true;

      if (!this.currentUserPicture.picture && (this.currentUserPicture.upload === undefined || this.currentUserPicture.upload.length === 0)) {
        await HttpClient.delete('api/v1/me/picture/');
      } else {
        try {
          const pictureToUpload = {
            userName: this.currentUser.userName,
            Picture: this.currentUserPicture.upload[0].base64,
            PictureFilename: this.currentUserPicture.upload[0].name
          };
          await HttpClient.put('api/v1/me/picture', pictureToUpload)
            .then(() => {
              this.isLoadingUserUpdate = false;
            });
        } catch (error) {
          this.isLoadingUserUpdate = false;
          console.error(error);
          this.error = error;
        }
      }
    },

    async updateLastLoginDateCurrentUser () {
      this.isLoadingUserUpdate = true;

      try {
        await HttpClient.get('api/v1/me/lastlogin')
          .then(() => {
            this.isLoadingUserUpdate = false;
          });
      } catch (error) {
        this.isLoadingUserUpdate = false;
        console.error(error);
        this.error = error;
      }
    },

    async acceptTermsOfUse () {
      this.isLoadingTermsOfUse = true;

      try {
        const response = await HttpClient.get('api/v1/me/terms/accepted');
        this.currentUser = response.data;
        this.isLoadingTermsOfUse = false;
      } catch (error) {
        this.isLoadingTermsOfUse = false;
        console.error(error);
        this.error = error;
      }
      await this.fetchCurrentUser(true); // Force a reload of the user, otherwise unexpected things happen with userroles.
    },

    async fetchProfile (profileId) {
      this.isLoadingProfile = true;

      try {
        const response = await HttpClient.get('api/v1/profile/' + profileId);
        this.profile = response.data;
        this.isLoadingProfile = false;
      } catch (error) {
        this.isLoadingProfile = false;
        console.error(error);
        this.error = error;
      }
    },

    async fetchProfilePicture (profileId) {
      this.isLoadingUserPicture = true;

      try {
        const response = await HttpClient.get('api/v1/profile/' + profileId + '/picture');
        this.profilePicture = response.data;
        this.isLoadingProfilePicture = false;
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },

    async fetchLatestTermsOfUse (profileId) {
      this.isLoadingTermsOfUse = true;

      try {
        const response = await HttpClient.get('api/v1/me/terms/');
        this.latestTermsOfUse = response.data;
        this.isLoadingTermsOfUse = false;
      } catch (error) {
        this.isLoadingTermsOfUse = false;
        console.error(error);
        this.error = error;
      }
    }
  }
});
