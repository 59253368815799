import { loginRequest, msalInstance } from './config';

export class AuthService {
  constructor () {
    this.instance = msalInstance;
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    this.accounts = accounts;
    this.loginRequest = loginRequest;
    this.showDebug = false;
    this.isLoading = false;
  }

  login () {
    this.instance.loginRedirect(this.loginRequest);
  }

  async logout () {
    localStorage.clear();
    document.cookie.split(';').forEach(function (c) {
      console.info('c', c);
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    // this.instance.logoutPopup();
    const getAccountByHomeId = this.instance.getAccountByUsername(this.accounts[0].username);
    await this.instance.logoutRedirect({
      account: getAccountByHomeId
    });
  }

  getAccountUsername () {
    this.isLoading = true;
    if (this.accounts.length > 0) {
      const username = this.accounts[0].username;
      if (username) {
        this.isLoading = false;
        return username;
      }
    }
    this.isLoading = false;
    return '';
  }

  userIsLoggedIn () {
    return this.accounts.length > 0;
  }

  async getAccessToken () {
    const response = await this.instance.acquireTokenSilent({
      ...loginRequest
    }).catch(async (e) => {
      if (e) {
        await this.instance.acquireTokenRedirect(loginRequest);
      }
      throw e;
    });
    if (this.showDebug) {
      console.info('AuthService getAccessToken', response);
    }
    return response.accessToken;
  }

  async getIdToken () {
    const response = await this.instance.acquireTokenSilent({
      ...loginRequest
    }).catch(async (e) => {
      if (e) {
        await this.instance.acquireTokenRedirect(loginRequest);
      }
      throw e;
    });
    if (this.showDebug) {
      console.info('AuthService getIdToken', response);
    }
    return response.idToken;
  }

  // Microsoft Graph API requires the use of an access token, while the SOMSET backend requires the use of an ID token
  async configureDefaultAuthorizationHeader () {
    let language = window.$cookies.get('language');
    if (language === undefined || language === null || language === '') {
      language = 'nl';
    }

    return {
      headers: {
        Authorization: 'Bearer ' + await this.getIdToken(),
        Language: language
      }
    };
  }

  async configureMicrosoftGraphAuthorizationHeader () {
    let language = window.$cookies.get('language');
    if (language === undefined || language === null || language === '') {
      language = 'nl';
    }

    return {
      headers: {
        Authorization: 'Bearer ' + await this.getAccessToken(),
        Language: language
      }
    };
  }
}
